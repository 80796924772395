import React, { PropsWithChildren, useState } from "react";

type HealthMessageContextType = {
  responseData: Message[];
  setResponseData: (message: Message[]) => void;
  loading: boolean;
  setLoading: (status: boolean) => void;

  resendClicked: boolean;
  setResendClicked: (status: boolean) => void;
  setSkipPageReset: (status: boolean) => void;
  skipPageReset: boolean;
};

export type Message = {
  id: number;
  text: { en: string; fr: string; ar: string; rw: string };
};
export const HealthMessageContext =
  React.createContext<HealthMessageContextType>({
    responseData: [],
    setResponseData: () => {
      //
    },
    loading: false,
    setLoading: () => {
      //
    },

    resendClicked: false,
    setResendClicked: () => {
      //
    },
    setSkipPageReset: () => {
      //
    },
    skipPageReset: false,
  });

const HealthMessageContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [responseData, setResponseData] = useState<Array<Message>>([]);
  const [loading, setLoading] = useState(false);
  const [resendClicked, setResendClicked] = useState(false);
  const [skipPageReset, setSkipPageReset] = useState(false);

  return (
    <HealthMessageContext.Provider
      value={{
        responseData,
        setResponseData,
        loading,
        setLoading,
        resendClicked,
        setResendClicked,
        setSkipPageReset,
        skipPageReset,
      }}
    >
      {children}
    </HealthMessageContext.Provider>
  );
};

export default HealthMessageContextProvider;
