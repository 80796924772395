import { useCallback, useContext } from "react";
import { AuthContext } from "../store/AuthContext";
import { useHistory } from "react-router-dom";

const useAPI = () => {
  const { authData, logout } = useContext(AuthContext);
  const history = useHistory();

  const sendRequest = useCallback(
    async (
      requestData: {
        url: string;
        method?: string;
        contentType?: boolean;
        body?: string | FormData;
        noAuthorization?: boolean;
      },
      processResponse: (response: Response) => void
    ) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}${requestData.url}`, {
          method: requestData.method ?? "GET",
          headers: requestData.contentType
            ? {
                Authorization: `Bearer ${authData.access}`,
                "Accept-Language": JSON.parse(localStorage.getItem("auth_data")!).lang,
              }
            : {
                "Content-Type": "application/json",
                Authorization: requestData.noAuthorization ? "" : `Bearer ${authData.access}`,
                "Accept-Language": JSON.parse(localStorage.getItem("auth_data")!).lang,
              },
          body: requestData.body ?? null,
        });
        if (response.status === 403) {
          logout();
          history.push("/login")
          return;
        }
        processResponse(response);
      } catch (error) {
        console.log(error);
      }
    },
    []
  );

  return {
    sendRequest,
  };
};

export default useAPI;
