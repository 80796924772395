import React from "react";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import ActivityLogType from "../../../../types/ActivityLog";
import i18next from "i18next";

const OWNER_ROLE_STAFF = "Staff";
const OWNER_ROLE_ADMIN = "Admin";

const ActivityLog: React.FC<{
    activityLog: ActivityLogType;
    className?: string;
}> = ({ activityLog, className }) => {
    const { t } = useTranslation();
    let bgColor, color, imageBgColor;
    switch (activityLog.ownerRole) {
        case null:
            bgColor = "bg-gray-612d";
            break;
        case OWNER_ROLE_ADMIN:
            bgColor = "bg-blue-7133";
            color = "text-blue-primary";
            imageBgColor = "bg-blue-primary";
            break;
        case OWNER_ROLE_STAFF:
            bgColor = "bg-green-primary33";
            color = "text-green-primary";
            imageBgColor = "bg-green-primary";
            break;
    }

    return (
        <article className={`p-4 rounded text-sm text-black41 ${bgColor} ${className}`}>
            <p className="first-letter:uppercase">
                {!activityLog.ownerRole && <FontAwesomeIcon className={`${i18next.language === "ar" ? "ml-2" : "mr-2"}`} icon={faEnvelope} />}
                {activityLog.activityText}
            </p>
            <div className="mt-6">
                {activityLog.ownerRole && (
                    <div className={`capitalize ${color}`}>
                        <div className="flex">
                            {activityLog.ownerPhoto ? (
                                <img
                                    className="rounded-full h-[42px] w-[42px] object-fill"
                                    src={activityLog.ownerPhoto}
                                    alt={t("profile_image")}
                                />
                            ) : (
                                <div className={`${imageBgColor} text-white px-[13.41px] py-[11px] rounded-full text-[24px]`}>
                                    {activityLog.ownerRole[0]}
                                </div>
                            )}
                            <div className="mx-[10px]">
                                <div className="font-semibold">{activityLog.ownerRole}</div>
                                <div>{activityLog.ownerName}</div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="flex justify-end mt-1">
                    <p className="mx-4">
                        <Moment parse={"DD/MM/YYYY HH:mm:ss"} format={"DD/MM/YYYY"} locale={i18next.language === "ar" ? "ar" : "en"}>
                            {activityLog.date.replace("T", " ")}
                        </Moment>
                    </p>
                    <p>
                        <Moment parse={"DD/MM/YYYY HH:mm:ss"} format={"HH:mm"} locale={i18next.language === "ar" ? "ar" : "en"}>
                            {activityLog.date.replace("T", " ")}
                        </Moment>
                    </p>
                </div>
            </div>
        </article>
    );
};

export default ActivityLog;
