import React from "react";
import i18next from "i18next";

const LanguageBox: React.FC<{
  onChange: (lng: string) => void;
  selectedLanguage: string;
}> = ({ onChange, selectedLanguage }) => {

  return (
    <div
      className="flex top-0 right-0 rounded text-sm h-[39px]"
    >
      <div
        onClick={() => {
          onChange("en");
        }}
        className={`font-bold py-[7.5px] px-[19px] hover:cursor-pointer ${
          selectedLanguage === "en"
            ? "bg-[#007BB0] text-white"
            : "bg-[#F4F4F4] text-[#007BB0]"
        } ${
          i18next.language === "ar" ? "rounded-r" : "rounded-l"
        } duration-300`}
      >
        EN
      </div>

      {process.env.REACT_APP_Country === "Rwanda" ? (
        <div
          onClick={() => {
            onChange("rw");
          }}
          className={`font-bold py-[7.5px] px-[19px] hover:cursor-pointer ${
            selectedLanguage === "rw"
              ? "bg-[#007BB0] text-white"
              : "bg-[#F4F4F4] text-[#007BB0]"
          } rounded-r duration-300`}
        >
          RW
        </div>
      ) : (
        <div
          onClick={() => {
            onChange("fr");
          }}
          className={`font-bold py-[7.5px] px-[19px] hover:cursor-pointer ${
            selectedLanguage === "fr"
              ? "bg-[#007BB0] text-white"
              : "bg-[#F4F4F4] text-[#007BB0]"
          } ${
            process.env.REACT_APP_Country !== "Lebanon" && "rounded-r"
          } duration-300`}
        >
          FR
        </div>
      )}

      {process.env.REACT_APP_Country === "Lebanon" && (
        <div
          onClick={() => {
            onChange("ar");
          }}
          className={`font-bold py-[7.5px] px-[19px] hover:cursor-pointer ${
            selectedLanguage === "ar"
              ? "bg-[#007BB0] text-white"
              : "bg-[#F4F4F4] text-[#007BB0]"
          } ${
            i18next.language === "ar" ? "rounded-l" : "rounded-r"
          } duration-300`}
        >
          AR
        </div>
      )}
    </div>
  );
};

export default LanguageBox;
