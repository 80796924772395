import useAPI from "../../hooks/useAPI";
import Area, { LngLat } from "../../types/Area";
import { AddOrEditModal } from "../../utils/Constants";

const AreaAPI = () => {
  const { sendRequest } = useAPI();

  const getAreas = (getAreasProcessResponse: (response: Response) => void, pageNumber?: number, pageSize?: number, searchInput?: string) => {
    sendRequest(
      {
        url: `areas/?page=${pageNumber}&page_size=${pageSize}${searchInput ? `&search=${searchInput}` : ""}`,
      },
      getAreasProcessResponse
    );
  };

  const getArea = (
    id: number,
    getAreaProcessResponse: (response: Response, area: Area) => void
  ) => {
    sendRequest(
      {
        url: `areas/${id}/`,
      },
      async (response) => {
        const result = await response.json();
        getAreaProcessResponse(response, {
          id: result.id,
          name: result.name,
          description: result.description,
          coordinates: result.coordinates,
        });
      }
    );
  };

  const addOrEditArea = (
    addOrEditProcessResponse: (response: Response) => void,
    _type: AddOrEditModal,
    _name: string,
    _description: string,
    _coordinates: LngLat[],
    _editAreaId?: number
  ) => {
    sendRequest(
      {
        url: _type === AddOrEditModal.ADD ? "areas/" : `areas/${_editAreaId}/`,
        method: _type === AddOrEditModal.ADD ? "POST" : "PUT",
        body: JSON.stringify({
          name: _name,
          description: _description,
          center_longitude: 0,
          center_latitude: 0,
          coordinates: JSON.stringify(_coordinates),
        }),
      },
      addOrEditProcessResponse
    );
  };

  const deleteArea = (processDeleteAreaResponse: (response: Response) => void, _id: number) => {
    const id = _id.toString();
    sendRequest(
      {
        url: `areas/${id}/`,
        method: "DELETE",
      },
      (response: Response) => {
        processDeleteAreaResponse(response);
      }
    );
  };

  const getCoordinatesList = (getCoordinatesProcessResponse: (response: Response) => void) => {
    sendRequest(
      {
        url: "areas/coordinates/",
      },
      getCoordinatesProcessResponse
    );
  };

  return {
    getAreas,
    getArea,
    addOrEditArea,
    deleteArea,
    getCoordinatesList
  };
};

export default AreaAPI;
