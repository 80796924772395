import React, { useContext, useEffect, useState } from "react";
import { useTable, usePagination } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-light-svg-icons";
import { faInfoCircle, faCheck } from "@fortawesome/pro-regular-svg-icons";
import Pagination from "../../../UI/Pagination";
import VaccineUtilizationRow from "./VaccineUtilizationRow";
import VaccineUtilization from "../../../../types/VaccineUtilization";
import { useTranslation } from "react-i18next";
import { SortConfig } from "./VaccineUtilization";
import i18next from "i18next";
import { SelectedVaccineUtilizationContext } from "../../../../store/SelectedVaccineUtilizationContext";

interface Column {
  header: string;
  accessor: any;
}

const VaccineUtilizationTable: React.FC<{
  columns: Column[];
  data: VaccineUtilization[];
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  pageCount: number;
  totalDataCount: number;
  currentSortConfig: SortConfig | undefined;
  sortChanged: (sortConfig: SortConfig) => void;
}> = ({
  columns,
  data,
  pageNumber,
  setPageNumber,
  pageSize,
  setPageSize,
  pageCount,
  totalDataCount,
  currentSortConfig,
  sortChanged,
}) => {
  const { t } = useTranslation();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    gotoPage,
    nextPage,
    previousPage,
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
    },
    usePagination
  );
  const { selectedVaccineUtilization, selectAll, deselectAll } = useContext(
    SelectedVaccineUtilizationContext
  );

  const [tooltipInfo, setTooltipInfo] = useState({
    show: false,
    text: "",
    x: 0,
    y: 0,
  });

  useEffect(() => {
    page.length === 0 && previousPage();
  }, [page]);

  const showTooltip = (
    text: string,
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    const icon = event.currentTarget;
    const rect = icon.getBoundingClientRect();
    setTooltipInfo({
      show: true,
      text: text,
      x: rect.left,
      y: rect.top,
    });
  };

  const sortTable = (column: string, isAscending: boolean) => {
    sortChanged({
      column: column,
      sortType: isAscending ? "Ascending" : "Descending",
    });
  };

  const visibleVaccineUtilizations = page.map((row) => row.original.id);
  let allChecked = true;
  for (let visibleVaccineUtilization of visibleVaccineUtilizations) {
    if (selectedVaccineUtilization.indexOf(visibleVaccineUtilization) === -1) {
      allChecked = false;
      break;
    }
  }
  if (selectedVaccineUtilization.length === 0) {
    allChecked = false;
  }

  return (
    <>
      <div className="relative">
        <div className="w-full mx-auto overflow-auto bg-white scroll-firefox overflow-x-auto overflow-y-hidden">
          <table {...getTableProps()} className="w-full border-collapse">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className="border-b border-gray-200"
                >
                  {headerGroup.headers.map((column, index) => (
                    <th
                      scope="col"
                      className={`td-firefox border-gray-df table__cell sticky top-0 z-10 text-sm text-[#414141] font-extrabold tracking-wider ${
                        index === 0 &&
                        `z-20 ${
                          i18next.language === "ar" ? "right-0" : "left-0"
                        }`
                      }`}
                      {...column.getHeaderProps()}
                    >
                      <div className="flex items-center text-sm">
                        {index === 0 && (
                          <div
                            className={`table__checkbox grid place-items-center ${
                              allChecked &&
                              "border-blue-primary bg-blue-primary"
                            } ${i18next.language === "ar" ? "ml-4" : "mr-4"}`}
                            onClick={(e) => {
                              e.stopPropagation();

                              if (allChecked) {
                                deselectAll(page.map((row) => row.original.id));
                              } else {
                                selectAll(page.map((row) => row.original.id));
                              }
                            }}
                          >
                            {allChecked && (
                              <FontAwesomeIcon
                                icon={faCheck}
                                className="text-sm text-white"
                              />
                            )}
                          </div>
                        )}

                        <h3 className="font-bold text-black41">
                          {column.render("header")}
                        </h3>
                        <div className="flex flex-col mx-4">
                          <FontAwesomeIcon
                            className={`cursor-pointer ${
                              currentSortConfig?.column === column.id &&
                              currentSortConfig?.sortType === "Ascending"
                                ? "text-black41"
                                : "text-gray-df"
                            }`}
                            icon={faAngleUp}
                            onClick={() => sortTable(column.id, true)}
                          />
                          <FontAwesomeIcon
                            className={`cursor-pointer ${
                              currentSortConfig?.column === column.id &&
                              currentSortConfig?.sortType === "Descending"
                                ? "text-black41"
                                : "text-gray-df"
                            }`}
                            icon={faAngleDown}
                            onClick={() => sortTable(column.id, false)}
                          />
                        </div>
                        {index === 1 && (
                          <FontAwesomeIcon
                            className="text-[#8D8D8D]"
                            icon={faInfoCircle}
                            onMouseOver={(e) =>
                              showTooltip(
                                t("no_of_children_received_vaccine"),
                                e
                              )
                            }
                            onMouseOut={() =>
                              setTooltipInfo({
                                show: false,
                                text: "",
                                x: 0,
                                y: 0,
                              })
                            }
                          />
                        )}
                        {index === 2 && (
                          <FontAwesomeIcon
                            className="text-[#8D8D8D]"
                            icon={faInfoCircle}
                            onMouseOver={(e) =>
                              showTooltip(
                                t("no_of_children_scheduled_for_vaccine"),
                                e
                              )
                            }
                            onMouseOut={() =>
                              setTooltipInfo({
                                show: false,
                                text: "",
                                x: 0,
                                y: 0,
                              })
                            }
                          />
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {data.map((vu) => {
                return (
                  <VaccineUtilizationRow key={vu.id} vaccineUtilization={vu} />
                );
              })}
            </tbody>
          </table>
        </div>
        {tooltipInfo.show && (
          <div
            className="fixed top-0 left-0 z-50"
            style={{ pointerEvents: "none" }}
          >
            <div
              className="bg-white rounded shadow p-2 text-xs w-[160px] -mt-6 -ml-16"
              style={{
                position: "absolute",
                left: `${tooltipInfo.x}px`,
                top: `${tooltipInfo.y - 30}px`,
              }}
            >
              {tooltipInfo.text}
            </div>
          </div>
        )}
      </div>

      <Pagination
        dropdownKeys={["5", "10", "20"]}
        previousPage={previousPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
        setPageNumber={setPageNumber}
        gotoPage={gotoPage}
        pageCount={pageCount}
        pageIndex={pageNumber}
        pageSize={pageSize}
        pageLength={page.length}
        totalLength={totalDataCount}
      />
    </>
  );
};

export default VaccineUtilizationTable;
