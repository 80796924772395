import { useContext, useEffect, useState } from "react";
import MainContainer from "../../../UI/MainContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchSection from "../../../UI/SearchSection";
import AreaTable from "./AreaTable";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import NoResultMessage from "../../../UI/NoResultMessage";
import MainFooter from "../../../UI/MainFooter";
import { AreaContext } from "../../../../store/AreaContext";
import i18next from "i18next";
import { CircularProgress, Snackbar } from "@mui/material";
import BreadCrumb from "../../../UI/BreadCrumb";
import Button from "../../../UI/Button";
import { ButtonTypes, SnackBarType } from "../../../../utils/Constants";
import AreaAPI from "../../../../store/api/areaAPI";
import { parseCoordinates } from "../../../../utils/parseCoordinates";
import { SnackbarType } from "../../reports/vaccineUtilization/VaccineUtilization";

const Areas = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { responseData, setResponseData, setSkipPageReset } =
    useContext(AreaContext);
  const { getAreas, deleteArea } = AreaAPI();
  const [showLoading, setShowLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(localStorage.getItem("areas_page_size") && (localStorage.getItem("areas_page_size") !== undefined || localStorage.getItem("areas_page_size") !== null || localStorage.getItem("areas_page_size") !== (0).toString()) ? Number(localStorage.getItem("areas_page_size")) : 4);
  const [pageCount, setPageCount] = useState<number>(0);
  const [areasCount, setAreasCount] = useState<number>(0);
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [searchInputIsEmpty, setSearchInputIsEmpty] = useState(true);
  const location = useLocation<SnackbarType>();
  const [snackbar, setSnackbar] = useState<SnackbarType>({
    message: "",
    type: 0,
    show: false,
  });

  const columns = [
    {
      header: t("text"),
      accessor: "text",
    },
  ];

  useEffect(() => {
    if (location.state?.message) {
      setSnackbar({
        message: location.state.message,
        type: location.state.type,
        show: true,
      });
      const timer = setTimeout(() => {
        history.replace("/areas");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [location.state, history]);

  useEffect(() => {
    if (searchInput && !searchPerformed) {
      setPageNumber(0);
      setSearchPerformed(true);
    }
  }, [searchInput]);

  useEffect(() => {
    localStorage.setItem("areas_page_size", pageSize.toString());
  }, [pageSize]);

  useEffect(() => {
    getDataFromServer(searchInput);
  }, [pageNumber, pageSize, searchInput]);

  useEffect(() => {
    setShowLoading(true);
    setTimeout(() => {
      setShowLoading(false);
    }, 2000);
  }, [responseData]);

  useEffect(() => {
    const handleRouteChange = (location: string) => {
      if (!location.startsWith("/areas") && !location.startsWith("/area")) {
        localStorage.removeItem("areas_page_size");
      }
    };
    history.listen((location) => {
      handleRouteChange(location.pathname);
    });
    handleRouteChange(location.pathname);
  }, [history, location.pathname]);
  

  const getDataFromServer = (searchInput?: string) => {
    getAreas(
      async (response: Response) => {
        if (response.ok) {
          const result = await response.json();
          const areasWithParsedCoordinates = result.areas.map((area: any) =>
            parseCoordinates(area)
          );
          setResponseData(areasWithParsedCoordinates);
          setPageCount(result.page_count);
          setAreasCount(result.areas_count);
        } else {
          console.log("Failed to fetch areas");
        }
      },
      pageNumber + 1,
      pageSize,
      searchInput
    );
  };

  const deleteHandler = (_id: number) => {
    setSkipPageReset(false);
    deleteArea((response) => deleteAreaProcessResponse(response, _id), _id);
  };

  const deleteAreaProcessResponse = async (response: Response, _id: number) => {
    if (response.ok) {
      setSnackbar({
        message: t("area_delete_message"),
        type: SnackBarType.DELETE,
        show: true,
      });
      getDataFromServer();
    } else {
      console.log("message: area not found");
    }
  };

  return (
    <MainContainer>
      <Snackbar
        dir={i18next.language === "ar" ? "rtl" : "ltr"}
        open={snackbar.show}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: `${
              snackbar.type === SnackBarType.ADDEDITFAILED
                ? "#D11C1C"
                : "#03A678"
            }`,
          },
          "& .MuiSnackbarContent-message": {
            fontSize: "16px",
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: "100",
          },
        }}
        onClose={() => {
          setSnackbar({ message: "", type: 0, show: false });
        }}
        message={snackbar.message}
      />

      <div className="alignment mb-8">
        <header className="flex flex-col mt-4 md:flex-row md:justify-between md:items-center md:mt-0">
          <div className="flex items-center">
            <h3 className="text-lg font-semibold text-gray-27">{t("areas")}</h3>
            <BreadCrumb
              className="mt-[2px] mx-2"
              availableLinks={[{ title: t("settings") }, { title: t("areas") }]}
            />
          </div>
          <Button
            className="self-start mt-2 md:mt-0"
            type={ButtonTypes.PRIMARY}
            onClick={() => history.push(`/area`)}
          >
            <div className="flex items-center">
              <FontAwesomeIcon
                icon={faPlus}
                className={`${
                  i18next.language === "ar" ? "ml-[10px]" : "mr-[10px]"
                } mt-[2px]`}
              />
              <p className="normal-case">{t("add_area")}</p>
            </div>
          </Button>
        </header>

        <div className="bg-white mt-5 rounded">
          <SearchSection
            onSearch={(_searchInput) => {
              setSearchInput(_searchInput);
              setSearchInputIsEmpty(_searchInput.length === 0);
            }}
            noFilter={true}
            onSliderClicked={() => {}}
          />

          <div dir="ltr" className="bg-gray-f5 pt-[6px]">
            {showLoading ? (
              <div className="flex w-full h-[500px] items-center justify-center">
                <CircularProgress size={64} style={{ color: "#11589a" }} />
              </div>
            ) : responseData.length > 0 ? (
              <AreaTable
                columns={columns}
                data={responseData}
                editHandler={(id) => history.push(`/area/${id}`)}
                deleteHandler={(id) => deleteHandler(id!)}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                pageSize={pageSize}
                setPageSize={setPageSize}
                pageCount={pageCount}
                totalDataCount={areasCount}
                paginationDropdownKeys={["4", "8", "12"]}
              />
            ) : (
              <div className="text-center py-2.5 rounded mt-[5px] bg-white">
                <NoResultMessage
                  noResultOrNoData={!searchInputIsEmpty}
                  button={{
                    buttonTitle: t("add_area"),
                    onClick: () => history.push(`/area`),
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <MainFooter />
      </div>
    </MainContainer>
  );
};

export default Areas;
