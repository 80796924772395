import React, { useContext, useEffect, useState } from "react";
import TitleBreadCrumb, { BreadCrumbLinks, BreadCrumbButton } from "../../../UI/TitleBreadCrumb";
import MainContainer from "../../../UI/MainContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchSection from "../../../UI/SearchSection";
import VaccineTable from "./VaccineTable";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Vaccine } from "./VaccineRow";
import AddOrEditVaccination from "./AddOrEditVaccination";
import { AddOrEditModal } from "../../../../utils/Constants";
import { DropResult } from "react-beautiful-dnd";
import MenuItem from "../../../UI/MenuItem";
import { faAngleDown, faCloudUpload, faDownload } from "@fortawesome/pro-light-svg-icons";
import NoResultMessage from "../../../UI/NoResultMessage";
import MainFooter from "../../../UI/MainFooter";
import { VaccineContext } from "../../../../store/VaccineContext";
import i18next from "i18next";
import { CircularProgress } from "@mui/material";

const VaccineDetail: React.FC<{
  getDataFromApi: () => void;

  importHandler: (inputFile: FormData, _inputVal: string) => void;
  deleteHandler: (_id: number) => void;
  downloadVaccineHandler: () => void;
  discontinueHandler: (
    vaccine_name: { en: string; fr: string; ar: string; rw: string },
    dose_detail: { en: string; fr: string; ar: string; rw: string },
    optional: boolean,
    preBirth: boolean,
    _id: number
  ) => void;
  searchVaccineHandler: (_searchValue: string) => void;
  update_order: (id: number, _oldOrder: number, _newOrder: number) => void;
  addOrEditVaccinationHandler: (
    _type: AddOrEditModal,
    _name: { en: string; fr: string; ar: string; rw: string },
    _dose_detail: { en: string; fr: string; ar: string; rw: string },
    _optional: boolean,
    _preBirth: boolean,
    searchInput: string,
    editVaccineId?: number
  ) => void;
}> = ({
  getDataFromApi,
  importHandler,
  deleteHandler,
  downloadVaccineHandler,
  discontinueHandler,
  searchVaccineHandler,
  update_order,
  addOrEditVaccinationHandler,
}) => {
  const { t } = useTranslation();
  const { responseData, setResponseData } = useContext(VaccineContext);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    setShowLoading(true); // TODO It's temproray loading for cunsistency

    setTimeout(() => {
      setShowLoading(false);
    }, 2000);
  }, [responseData]);

  const { slug } = useParams<{ slug: string }>();
  const [showAddVaccination, setShowAddVaccination] = useState(false);
  const [isDraggable, setIsDraggable] = useState(false);

  useEffect(() => {
    getDataFromApi();
  }, [slug]);

  const columns = [
    {
      header: t("vaccination_time"),
      accessor: "vaccination_time",
      disableSortBy: true,
    },
    {
      header: t("dose_detail"),
      accessor: "dose_detail",
      disableSortBy: true,
    },
    {
      header: t("status"),
      accessor: "status",
      disableSortBy: true,
    },
  ];
  const [showEditVaccine, setShowEditVaccine] = useState(false);
  const [vaccinationTimeEn, setVaccinationTimeEn] = useState("");
  const [vaccinationTimeFr, setVaccinationTimeFr] = useState("");
  const [vaccinationTimeAr, setVaccinationTimeAr] = useState("");
  const [vaccinationTimeRw, setVaccinationTimeRw] = useState("");
  const [vaccineDoseEn, setVaccineDoseEn] = useState<string>("");
  const [vaccineDoseFr, setVaccineDoseFr] = useState<string>("");
  const [vaccineDoseAr, setVaccineDoseAr] = useState<string>("");
  const [vaccineDoseRw, setVaccineDoseRw] = useState<string>("");
  const [vaccineOptional, setVaccineOptional] = useState(false);
  const [vaccinePreBirth, setVaccinePreBirth] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [editedVaccineId, setEditedVaccineId] = useState(0);
  const [searchInputIsEmpty, setSearchInputIsEmpty] = useState(true);
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const [showMenu, setShowMenu] = useState(false);

  const editHandler = (id: number, __data: Array<Vaccine>) => {
    setShowEditVaccine(true);
    setEditedVaccineId(id);
    const vaccineRow: Vaccine = __data.find((vaccine: Vaccine) => vaccine.id === id)!;
    setVaccinationTimeEn(vaccineRow.name.en);
    setVaccinationTimeFr(vaccineRow.name.fr);
    setVaccinationTimeAr(vaccineRow.name.ar);
    setVaccinationTimeRw(vaccineRow.name.rw);
    setVaccineDoseEn(vaccineRow.dose_detail.en);
    setVaccineDoseFr(vaccineRow.dose_detail.fr);
    setVaccineDoseAr(vaccineRow.dose_detail.ar);
    setVaccineDoseRw(vaccineRow.dose_detail.rw);
    setVaccineOptional(vaccineRow.is_optional);
    setVaccinePreBirth(vaccineRow.is_pre_birth);
  };

  const handleClick = () => {
    if (hiddenFileInput.current !== null) {
      hiddenFileInput.current.click();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileUpload = Object(event.target.files)[0];
    const formData = new FormData();
    formData.append("csv", fileUpload);
    importHandler(formData, searchInput);
  };

  const updateData = (result: DropResult, pageIndex: number, pageSize: number) => {
    if (!result.destination) return;

    const updatedData = Array.from(responseData);
    const [reOrderedFriend] = updatedData.splice(pageIndex * pageSize + result.source.index, 1);
    updatedData.splice(pageIndex * pageSize + result.destination.index, 0, reOrderedFriend);

    setShowLoading(true);
    setResponseData(updatedData);
  };

  const links: BreadCrumbLinks = {
    firstAddress: "settings",
    secondAddress: "vaccination_details",
    title: "vaccination_details",
  };

  const secondaryButton: BreadCrumbButton = {
    buttonTitle: "actions",
    onClick: () => setShowMenu(!showMenu),
    onBlur: () => setShowMenu(false),
  };
  const primaryButton: BreadCrumbButton = {
    buttonTitle: "add_vaccination",
    onClick: () => setShowAddVaccination(true),
  };

  return (
    <div>
      <MainContainer>
        <div className="alignment mb-8">
          <div>
            <TitleBreadCrumb
              links={links}
              primaryButton={primaryButton}
              className="pl-3 pr-8"
              secondaryButton={secondaryButton}
              hasSecondButton={responseData.length > 0}
            >
              <FontAwesomeIcon
                className={`text-sm absolute top-3.5 text-[18px] ${
                  i18next.language === "ar" ? "right-[15px]" : "left-[80px]"
                }`}
                icon={faAngleDown}
              />
              <div className="absolute">
                <div
                  className={`absolute z-10 top-[38px] ${
                    i18next.language === "ar" ? "md:left-[-60px] left-[-238px]" : "md:left-[-175px] left-[-11px]"
                  } ${showMenu ? "block" : "hidden"}`}
                >
                  <ul className="rounded shadow bg-white py-1 w-[270px]">
                    <MenuItem
                      titleKey={t("download_vaccine")}
                      onClick={() => {
                        downloadVaccineHandler();
                      }}
                    >
                      <FontAwesomeIcon icon={faDownload} className="mx-2" />
                    </MenuItem>
                    <MenuItem titleKey={t("import_vaccine_details")} onClick={handleClick}>
                      <FontAwesomeIcon icon={faCloudUpload} className="mx-1.5" />
                      <input
                        onChange={(e) => handleChange(e)}
                        ref={hiddenFileInput}
                        type="file"
                        className="hidden"
                        value={""}
                      />
                    </MenuItem>
                  </ul>
                </div>
              </div>
            </TitleBreadCrumb>
          </div>
          <div className="rounded shadow bg-white overflow-hidden mt-[34px]">
            <SearchSection
              onSliderClicked={() => {
                //
              }}
              onSearch={(_searchInput) => {
                setSearchInputIsEmpty(_searchInput.length === 0);
                _searchInput.length !== 0 ? setIsDraggable(true) : setIsDraggable(false);
                searchVaccineHandler(_searchInput);
                setSearchInput(_searchInput);
              }}
              noFilter={true}
            />

            {showLoading ? (
              <div className="flex w-full h-[500px] items-center justify-center">
                <CircularProgress size={64} style={{ color: "#11589a" }} />
              </div>
            ) : responseData.length > 0 ? (
              <div>
                <div className="line"></div>
                <div className="px-4 mb-[100px] relative z-10 bg-white">
                  <VaccineTable
                    isDragDisabled={isDraggable}
                    deleteHandler={(id) => deleteHandler(id)}
                    discontinueHandler={discontinueHandler}
                    columns={columns}
                    data={responseData}
                    editHandler={(id) => editHandler(id, responseData)}
                    updateData={updateData}
                    update_order={update_order}
                  />
                </div>
              </div>
            ) : (
              <div className="text-center py-2.5 rounded mt-[5px] bg-white">
                <NoResultMessage noResultOrNoData={!searchInputIsEmpty} button={primaryButton} />
              </div>
            )}
          </div>
          <MainFooter />
        </div>
      </MainContainer>
      <AddOrEditVaccination
        typeOfModal={AddOrEditModal.ADD}
        onCancel={() => setShowAddVaccination(false)}
        className={`${showAddVaccination ? "scale-100" : "scale-0"}`}
        addOrEditVaccinationHandler={(_type, _name, _dose_detail, _optional, _preBirth) =>
          addOrEditVaccinationHandler(_type, _name, _dose_detail, _optional, _preBirth, searchInput)
        }
      />
      <AddOrEditVaccination
        className={`${showEditVaccine ? "scale-100" : "scale-0"}`}
        onCancel={() => {
          setShowEditVaccine(false);
        }}
        typeOfModal={AddOrEditModal.EDIT}
        editVaccineTimeEn={vaccinationTimeEn}
        editVaccineTimeFr={vaccinationTimeFr}
        editVaccineTimeAr={vaccinationTimeAr}
        editVaccineTimeRw={vaccinationTimeRw}
        editDoseDetailEn={vaccineDoseEn}
        editDoseDetailFr={vaccineDoseFr}
        editDoseDetailAr={vaccineDoseAr}
        editDoseDetailRw={vaccineDoseRw}
        editOptional={vaccineOptional}
        editPreBirth={vaccinePreBirth}
        editVaccineId={editedVaccineId}
        addOrEditVaccinationHandler={(_type, _name, _dose_detail, _optional, _preBirth, editVaccineId) =>
          addOrEditVaccinationHandler(_type, _name, _dose_detail, _optional, _preBirth, searchInput, editVaccineId)
        }
      />
    </div>
  );
};

export default VaccineDetail;
