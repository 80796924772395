import React, { useState, useEffect, useContext } from "react";
import TitleBreadCrumb, { BreadCrumbLinks, BreadCrumbButton } from "../../../UI/TitleBreadCrumb";
import MainContainer from "../../../UI/MainContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchSection from "../../../UI/SearchSection";
import { AddOrEditModal } from "../../../../utils/Constants";
import { faAngleDown, faExclamationCircle, faCloudUpload, faDownload } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import MenuItem from "../../../UI/MenuItem";
import AddOrEditHealthMessage from "./AddOrEditHealthMessage";
import { useParams } from "react-router-dom";
import MainFooter from "../../../UI/MainFooter";
import HealthTable from "./HealthTable";
import NoResultMessage from "../../../UI/NoResultMessage";
import { HealthMessageContext } from "../../../../store/HealthMessageContext";
import { Message } from "./HealthCard";
import i18next from "i18next";
import { CircularProgress } from "@mui/material";

const HealthMessage: React.FC<{
  getDataFromServer: (searchInput: string) => void;
  allMessageSent: boolean;
  deleteMessage: (_id: number) => void;
  downloadMessageFile: () => void;
  resendMessageHandler: () => void;
  importMessageHandler: (inputFile: FormData, searchValue: string) => void;
  onSearch: () => void;
  addHealthMessageHandler: (
    enMessage: string,
    frMessage: string,
    arMessage: string,
    rwMessage: string,
    searchValue: string
  ) => boolean;
  editHealthMessageHandler: (
    id: number,
    enMessage: string,
    frMessage: string,
    arMessage: string,
    rwMessage: string,
    searchValue: string
  ) => void;
  searchHandler: (_value: string) => void;
}> = ({
  getDataFromServer,
  allMessageSent,
  deleteMessage,
  downloadMessageFile,
  resendMessageHandler,
  importMessageHandler,
  addHealthMessageHandler,
  editHealthMessageHandler,
  searchHandler,
}) => {
  const { t } = useTranslation();
  const [showMenu, setShowMenu] = useState(false);
  const [showAddHealthMessage, setShowAddHealthMessage] = useState(false);
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const [searchInputIsEmpty, setSearchInputIsEmpty] = useState(true);
  const [showEditHealthMessage, setShowEditHealthMessage] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [editID, setEditID] = useState(0);
  const [editMessage, setEditMessage] = useState({
    enMessage: "",
    frMessage: "",
    arMessage: "",
    rwMessage: "",
  });
  const { responseData, loading, resendClicked } = useContext(HealthMessageContext);
  const [showLoading, setShowLoading] = useState(true);
  const columns = [
    {
      header: t("text"),
      accessor: "text",
    },
  ];

  const { slug } = useParams<{ slug: string }>();

  useEffect(() => {
    getDataFromServer("");
  }, [slug, resendClicked]);

  useEffect(() => {
    setShowLoading(true);
  }, [loading]);

  useEffect(() => {
    // TODO It's temproray loading for cunsistency
    setShowLoading(true);

    setTimeout(() => {
      setShowLoading(false);
    }, 1000);
  }, [responseData]);

  const handleClick = () => {
    if (hiddenFileInput.current !== null) {
      hiddenFileInput.current.click();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileUpload = Object(event.target.files)[0];
    const formData = new FormData();
    formData.append("csv", fileUpload);

    importMessageHandler(formData, searchValue);
  };

  const deleteHandler = (_id: number) => {
    deleteMessage(_id);
  };

  const links: BreadCrumbLinks = {
    firstAddress: "settings",
    secondAddress: "health_messages",
    title: "health_messages",
  };

  const secondaryButton: BreadCrumbButton = {
    buttonTitle: "actions",
    onClick: () => setShowMenu(!showMenu),
    onBlur: () => setShowMenu(false),
  };

  const primaryButton: BreadCrumbButton = {
    buttonTitle: "add_health_message",
    onClick: () => setShowAddHealthMessage(true),
    onBlur: () => setShowMenu(false),
  };

  const editHandler = (_id: number) => {
    setShowEditHealthMessage(true);
    setEditID(_id);
    const messageRow: Message = responseData.find((message: Message) => message.id === _id)!;
    setEditMessage({
      enMessage: messageRow.text.en,
      frMessage: messageRow.text.fr,
      arMessage: messageRow.text.ar,
      rwMessage: messageRow.text.rw,
    });
  };

  return (
    <>
      <MainContainer>
        <div className="alignment mb-8">
          <div>
            <TitleBreadCrumb
              className="pl-3 pr-8"
              links={links}
              secondaryButton={secondaryButton}
              primaryButton={primaryButton}
              hasSecondButton={responseData.length > 0}
            >
              <FontAwesomeIcon
                className={`text-sm absolute top-3.5 text-[18px] ${
                  i18next.language === "ar" ? "right-[18px]" : "left-[80px]"
                }`}
                icon={faAngleDown}
              />
              <div className="absolute">
                <div
                  className={`absolute z-10 top-[38px] ${
                    i18next.language === "ar" ? "md:left-[-60px] left-[-260px]" : "md:left-[-200px] left-[-11px]"
                  } ${showMenu ? "block" : "hidden"}`}
                >
                  <ul className="rounded shadow bg-white py-1 w-[293px]">
                    <MenuItem titleKey={t("download__message")} onClick={() => downloadMessageFile()}>
                      <FontAwesomeIcon icon={faDownload} className="mx-2" />
                    </MenuItem>
                    <MenuItem titleKey={t("import_messages")} onClick={handleClick}>
                      <FontAwesomeIcon icon={faCloudUpload} className="mx-1.5" />
                      <input
                        onChange={(e) => handleChange(e)}
                        ref={hiddenFileInput}
                        type="file"
                        className="hidden"
                        value={""}
                      />
                    </MenuItem>
                  </ul>
                </div>
              </div>
            </TitleBreadCrumb>
            {allMessageSent && (
              <div className="rounded mt-[15px] w-full py-2 bg-[#FEE8C9] text-[#F78E00] text-sm px-3">
                <FontAwesomeIcon className="text-sm text-[18px] pt-[2px]" icon={faExclamationCircle} />
                <span className="mx-[8px] inline-block">{t("healt_message_top_bar__message")}</span>
                <span>
                  <button onClick={resendMessageHandler} className="text-[#272727] underline">
                    {t("resend")}
                  </button>{" "}
                </span>
              </div>
            )}

            <div className="bg-white mt-5 rounded">
              <SearchSection
                onSearch={(_searchVal) => {
                  searchHandler(_searchVal);
                  setSearchInputIsEmpty(_searchVal.length === 0);
                  setSearchValue(_searchVal);
                }}
                noFilter={true}
                onSliderClicked={() => {
                  //
                }}
              />
            </div>

            <div dir="ltr">
              {showLoading ? (
                <div className="flex w-full h-[500px] items-center justify-center">
                  <CircularProgress size={64} style={{ color: "#11589a" }} />
                </div>
              ) : responseData.length > 0 ? (
                <HealthTable
                  columns={columns}
                  data={responseData}
                  deleteHandler={(id) => deleteHandler(id!)}
                  editHealthMessageHandler={(id) => {
                    editHandler(id);
                  }}
                  loading={loading}
                />
              ) : (
                <div className="text-center py-2.5 rounded mt-[5px] bg-white">
                  <NoResultMessage noResultOrNoData={!searchInputIsEmpty} button={primaryButton} />
                </div>
              )}
            </div>
          </div>

          <MainFooter />
        </div>
      </MainContainer>
      <AddOrEditHealthMessage
        typeOfModal={AddOrEditModal.ADD}
        onCancel={() => setShowAddHealthMessage(false)}
        className={`${showAddHealthMessage ? "scale-100" : "scale-0"}`}
        addHealthMessageHandler={(enMessage, frMessage, arMessage, rwMessage) =>
          addHealthMessageHandler(enMessage, frMessage!, arMessage!, rwMessage!, searchValue)
        }
      />
      <AddOrEditHealthMessage
        typeOfModal={AddOrEditModal.EDIT}
        editMessageEn={editMessage.enMessage}
        editMessageFr={editMessage.frMessage}
        editMessageAr={editMessage.arMessage}
        editMessageRw={editMessage.rwMessage}
        onCancel={() => setShowEditHealthMessage(false)}
        className={`${showEditHealthMessage ? "scale-100" : "scale-0"}`}
        id={editID}
        editHealthMessageHandler={(id, enMessage, frMessage, arMessage, rwMessage) =>
          editHealthMessageHandler(id, enMessage, frMessage, arMessage, rwMessage, searchValue)
        }
      />
    </>
  );
};

export default HealthMessage;
