export const parseCoordinates = (area?: any) => {
  let formattedCoordinates = area.coordinates ? area.coordinates : area;

  try {
    const parsedCoordinates = JSON.parse(formattedCoordinates);
    return {
      ...area,
      coordinates: parsedCoordinates,
    };
  } catch (error) {
    console.error("Failed to parse coordinates:", error);
    return {
      ...area,
      coordinates: [],
    };
  }
};
