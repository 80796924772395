import { useParams } from "react-router-dom";
import moment from "moment";
import Staff from "../../../types/Staff";
import { DEFAULT_DATE } from "../../../utils/DateUtils";
import { useState, useContext } from "react";
import { saveAs } from "file-saver";
import { AddOrEditModal, ButtonTypes, SnackBarType } from "../../../utils/Constants";
import { Snackbar, SnackbarContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { StaffContext } from "../../../store/StaffContext";
import StaffAPI from "../../../store/api/staffAPI";
import LoadingSpinner from "../../UI/LoadingSpinner";
import i18next from "i18next";
import Button from "../../UI/Button";

export const staffLoader = (Component: any) => {
	return (props: any) => {
		const { slug } = useParams<{ slug: string }>();
		const [clinicName, setClinicName] = useState("");
		const [notFoundMessage, setNotFoundMessage] = useState("");
		const [showSnackbar, setShowSnackbar] = useState(false);
		const [typeAddOrEdit, setTypeAddOrEdit] = useState<AddOrEditModal>();
		const [staffExistsErrorMassage, setStaffExistsErrorMassage] = useState("");
		const { getStaff, exportStaff, addStaff, editStaff, updateStatus } = StaffAPI();
		const [exporting, setExporting] = useState(false);
		const [snackBarType, setSnackBarType] = useState<SnackBarType>();

		const {
			setResponseData,
			responseData,
			setLoading,
			setSkipPageReset,
			setResponseStatus,
			responseStatus,
			setinputIsChanged,
			inputIsChanged,
			filterInput,
			searchInput,
		} = useContext(StaffContext);
		const { t } = useTranslation();
		
		const getStaffs = () => {
			let url: string = "";
			if (searchInput === "" && filterInput === "") {
				url = `clinics/${slug}/staffs/`;
			} else if (searchInput !== "" && filterInput === "") {
				url = `clinics/${slug}/staffs/?${searchInput}`;
			} else if (searchInput === "" && filterInput !== "") {
				url = `clinics/${slug}/staffs/?${filterInput}`;
			} else {
				url = `clinics/${slug}/staffs/?${searchInput}&${filterInput}`;
			}

			getStaff(processGetStaffsResponse, url);
		};
		const processGetStaffsResponse = async (response: Response) => {
			const result = await response.json();
			if (response.ok) {
				setResponseData(
					result.staffs.map((staff: Staff) => {
						return {
							first_name: staff.first_name,
							last_name: staff.last_name,
							last_sync: staff.last_sync ? moment(staff.last_sync, "DD/MM/YYYY HH:mm:ss").toDate() : DEFAULT_DATE,
							phone_number: staff.phone_number,
							email: staff.email,
							preferred_language: staff.preferred_language,
							is_clinic_admin: staff.is_clinic_admin,
							slug: staff.slug,
							status: staff.status,
							status_key: staff.status_key,
						};
					})
				);
				setClinicName(result.clinic_name);
			} else {
				setNotFoundMessage(result.clinic);
			}
		};

		function capitalizeFirstLetter(string: string) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		}

		const exportHandler = () => {
			let queryParams: string = "";
			if (searchInput === "" && filterInput === "") {
				queryParams = ``;
			} else if (searchInput !== "" && filterInput === "") {
				queryParams = `?${searchInput}`;
			} else if (searchInput === "" && filterInput !== "") {
				queryParams = `?${filterInput}`;
			} else {
				queryParams = `?${searchInput}&${filterInput}`;
			}
			exportStaff(exportProcessResponse, slug, queryParams);
		};

		const exportProcessResponse = async (response: Response) => {
			if (response.ok) {
				setExporting(true);
				setTimeout(async () => {
					try {
						const blob = await response.blob();
						saveAs(blob, ` "cima_staff".csv`);
						setSnackBarType(SnackBarType.DOWNLOAD);
						setExporting(false);
						setShowSnackbar(true);
					} catch (e) {
						console.log("error:file not found");
					} finally {
						setExporting(false);
					}
				}, 1000);
			}
		};

		const saveAddOrEditHandler = (_type: AddOrEditModal, _name: string, _lastName: string, _phone: string, _email: string, _language: string, _isClinicAdmin: boolean, _editStaffSlug: string) => {
			const name = capitalizeFirstLetter(_name || "");
			const lastName = capitalizeFirstLetter(_lastName || "");
			const phone = capitalizeFirstLetter(_phone || "");
			const email = _email;
			const language = _language;
			const isClinicAdmin = _isClinicAdmin;
			switch (_type) {
				case 0:
					setLoading(true);
					setTypeAddOrEdit(_type);
					addStaff((response) => processAddOrEditResponse(response, _type), name, lastName, phone, email, language, isClinicAdmin, slug);
					break;
				case 1:
					setLoading(true);
					setinputIsChanged(!inputIsChanged);
					setTypeAddOrEdit(_type);
					editStaff((response) => processAddOrEditResponse(response, _type), name, lastName, phone, email, language, isClinicAdmin, slug, _editStaffSlug);
					break;
			}
		};

		const processAddOrEditResponse = async (response: Response, _type: AddOrEditModal) => {
			if (response.ok) {
				setSkipPageReset(true);
				getStaffs();
				setShowSnackbar(true);
				setResponseStatus({
					responseStatusChanged: !responseStatus.responseStatusChanged,
					responseIsOk: response.ok,
				});
				setLoading(false);
			} else {
				const result = await response.json();
				if (result.error)
					setStaffExistsErrorMassage(result.error)
				setResponseStatus({
					responseStatusChanged: !responseStatus.responseStatusChanged,
					responseIsOk: response.ok,
				});
				setLoading(false);
			}
		};

		const updateStatusHandler = (_status: string, _slugStaff: string) => {
			updateStatus(async (response) => updateProcessResponse(response, _slugStaff), slug, _status, _slugStaff);
		};

		const updateProcessResponse = async (response: Response, _slugStaff: string) => {
			setLoading(true);
			if (response.ok) {
				const result = await response.json();
				responseUpdateStatusMethod(_slugStaff, result.status);
				setLoading(false);
			}
		};
		const responseUpdateStatusMethod = (_slugStaff: string, _result_status: string) => {
			statusAndDeleteHandler(_slugStaff, _result_status, responseData);
			(_result_status === "IC" || _result_status === "PD") && setTypeAddOrEdit(AddOrEditModal.EDIT);
			_result_status === "DL" && setTypeAddOrEdit(AddOrEditModal.DELETE);

			setShowSnackbar(true);
		};

		const statusAndDeleteHandler = (_slugStaff: string, _status: string, _data: Array<Staff>) => {
			const index = _data.findIndex((staffs) => staffs.slug === _slugStaff);
			const editData: Staff[] = [..._data];
			const selectedStaff: Staff = editData[index];
			switch (_status) {
				case "DL":
					setSkipPageReset(false);
					editData.splice(index, 1);
					setResponseData(editData);
					break;
				case "PD":
				case "IC":
					setSkipPageReset(false);
					const newStaff: Staff = {
						status: _status,
						first_name: selectedStaff.first_name,
						last_name: selectedStaff.last_name,
						last_sync: selectedStaff.last_sync,
						phone_number: selectedStaff.phone_number,
						email: selectedStaff.email,
						preferred_language: selectedStaff.preferred_language,
						is_clinic_admin: selectedStaff.is_clinic_admin,
						slug: selectedStaff.slug,
						status_key: _status,
					};
					editData[index] = newStaff;
					setResponseData(editData);
					break;
			}
		};

		return (
			<>
				<Snackbar
					dir={i18next.language === "ar" ? "rtl" : "ltr"}
					open={showSnackbar}
					autoHideDuration={3000}
					anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
					sx={{
						"& .MuiSnackbarContent-root": {
							backgroundColor: `${typeAddOrEdit === 2 ? "#D11C1C" : "#03A678"} `,
						},
						"& .MuiSnackbarContent-message": {
							fontSize: "16px",
							fontFamily: "'Open Sans', sans-serif",
							fontWeight: "100",
						},
					}}
					onClose={() => { setShowSnackbar(false) }}>
					<SnackbarContent
						message={
							<p>
								{typeAddOrEdit === 0 ? t("staff_added") : typeAddOrEdit === 1 ? t("staff_updated__message") : typeAddOrEdit === 2 ? staffExistsErrorMassage : typeAddOrEdit === 3 ? t("staff_deleted__message") : snackBarType === SnackBarType.DOWNLOAD && <>{t("data_exported_successfully")}</>}
							</p>
						}
					/>
				</Snackbar>
				{exporting && (
					<LoadingSpinner
						onBackdrop={() => setExporting(false)}
						titleKey={t("exporting_to_csv")}
					/>
				)}

				{staffExistsErrorMassage && (
					<div
						className="backdrop z-50"
					>
						<section dir={i18next.language === "ar" ? "rtl" : "ltr"}
							className="bg-white shadow-md p-6 cursor-auto w-[330px] rounded-md"
							onClick={(e) => e.stopPropagation()}
						>
							<p className="text-black41 mt-2">
								{staffExistsErrorMassage}
							</p>
							<div className="flex mt-8 justify-end">
								<Button
									type={ButtonTypes.PRIMARY}
									onClick={() => setStaffExistsErrorMassage("")}
								>
									{t("ok")}
								</Button>
							</div>
						</section>
					</div>
				)}

				<Component
					{...props}
					getStaffs={getStaffs}
					clinicName={clinicName}
					notFoundMessage={notFoundMessage}
					exportHandler={exportHandler}
					saveAddOrEditHandler={saveAddOrEditHandler}
					updateStatusHandler={updateStatusHandler}
					statusAndDeleteHandler={statusAndDeleteHandler}
				/>
			</>
		);
	};
};
