import React, { useContext, useEffect } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { HealthMessageContext } from "../../../../store/HealthMessageContext";
import Pagination from "../../../UI/Pagination";
import HealthCard from "./HealthCard";

interface Column {
  header: string;
  accessor: any;
}
type Message = {
  id: number;
  text: { en: string; fr: string; ar: string; rw: string };
};

//////////////////////////////////////////////
const HealthTable: React.FC<{
  loading: boolean;
  columns: Column[];
  data: Message[];
  deleteHandler: (id?: number) => void;
  editHealthMessageHandler: (id: number) => void;
}> = ({ columns, data, deleteHandler, editHealthMessageHandler }) => {
  // Use the state and functions returned from useTable to build your UI
  const { skipPageReset } = useContext(HealthMessageContext);

  const {
    getTableProps,
    getTableBodyProps,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 4,
      },
      autoResetPage: skipPageReset,
    },

    useSortBy,
    usePagination
  );

  useEffect(() => {
    page.length === 0 && previousPage();
  }, [page]);

  // Render the UI for your table
  return (
    <>
      <div {...getTableProps()} className="grid grid-cols-1 gap-5 mt-1.5 480:grid-cols-2">
        {page.map((row, i) => (
          <div {...getTableBodyProps()} key={i} className="col-span-1 flex flex-col bg-white rounded ">
            <HealthCard
              deleteHandler={(id) => deleteHandler(id)}
              englishMessage={row.original.text.en}
              frenchMessage={row.original.text.fr}
              arabicMessage={row.original.text.ar}
              kinyarwandaMessage={row.original.text.rw}
              index={row.original.id}
              editHealthMessageHandler={editHealthMessageHandler}
            />
          </div>
        ))}
      </div>

      <Pagination
        dropdownKeys={["4", "8", "12"]}
        previousPage={previousPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
        gotoPage={gotoPage}
        pageCount={pageCount}
        pageIndex={state.pageIndex}
        pageSize={state.pageSize}
        pageLength={page.length}
        totalLength={data.length}
      />
    </>
  );
};

export default HealthTable;
