import React, { PropsWithChildren, useEffect, useState } from "react";
import VaccineUtilization from "../types/VaccineUtilization";
import { VaccineUtilizationFilterBarType } from "../components/main/reports/vaccineUtilization/VaccineUtilizationFilterBar";
import IdValueType from "../types/IdValueType";
import i18next from "i18next";
import VaccineUtilizationApi from "./api/vaccineUtilizationApi";
import ClinicsApi from "../store/api/clinicsAPI";
import { ClinicTitle } from "./SearchFilterDefaultersContext";

type VaccineUtilizationContextType = {
  responseData: VaccineUtilization[];
  setResponseData: (VaccineUtilization: VaccineUtilization[]) => void;
  loading: boolean;
  setLoading: (status: boolean) => void;
  searchInput: string;
  setSearchInput: (input: string) => void;
  filterState: VaccineUtilizationFilterBarType;
  setFilterState: (input: VaccineUtilizationFilterBarType) => void;
  vaccines: IdValueType[];
  clinics: ClinicTitle[];
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
};

export const VaccineUtilizationContext =
  React.createContext<VaccineUtilizationContextType>({
    responseData: [],
    setResponseData: () => {
      //
    },
    loading: false,
    setLoading: () => {
      //
    },
    searchInput: "",
    setSearchInput: () => {
      //
    },
    filterState: { vaccine: [], dateFrom: null, dateTo: null, clinic: "" },
    setFilterState: () => {
      //
    },
    vaccines: [],
    clinics: [],
    setLanguage: () => {
      //
    },
  });

const VaccineUtilizationContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [responseData, setResponseData] = useState<Array<VaccineUtilization>>(
    []
  );
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filterState, setFilterState] =
    useState<VaccineUtilizationFilterBarType>({
      vaccine: [{ id: "", name: "", type: [] }],
      dateFrom: null,
      dateTo: null,
      clinic: "",
    });
  const [vaccines, setVaccines] = useState<IdValueType[]>([]);
  const [clinics, setClinics] = useState<ClinicTitle[]>([]);
  const { getVaccineNames } = VaccineUtilizationApi();
  const { getClinicTitles } = ClinicsApi();
  const [language, setLanguage] = useState<string>(i18next.language);

  useEffect(() => {
    if (language) {
      getVaccineNames(async (response: Response) => {
        const result = await response.json();
        if (response.ok) {
          setVaccines(result);
        }
      });
      getClinicTitles((clinicTitles) => setClinics(clinicTitles));
    }
  }, [language]);

  return (
    <VaccineUtilizationContext.Provider
      value={{
        responseData,
        setResponseData,
        loading,
        setLoading,
        setSearchInput,
        searchInput,
        setFilterState,
        filterState,
        vaccines,
        clinics,
        setLanguage,
      }}
    >
      {children}
    </VaccineUtilizationContext.Provider>
  );
};

export default VaccineUtilizationContextProvider;
