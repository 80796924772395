import { useState, useEffect } from "react";

import { validationState } from "../utils/Constants";

const passwordRegex =
  /(?=.*[0-9])(?=.*[!"#$%&'()*+,-./\\:;<=>?@[\]^_`{|}~])[a-zA-Z0-9!"#$%&'()*+,-./\\:;<=>?@[\]^_`{|}~]{8,}/;

export const passwordValidator = (password: string) => {
  return passwordRegex.test(password);
};

const usePasswordValidation = (
  equalityPasswordValidator?: (password: string) => boolean
) => {
  const [password, setPassword] = useState<string>();
  const [passwordHasError, setPasswordHasError] = useState(
    validationState.WAITING
  );

  useEffect(() => {
    if (password !== undefined) {
      const timeout = setTimeout(() => {
        if (equalityPasswordValidator !== undefined) {
          if (equalityPasswordValidator(password)) {
            setPasswordHasError(validationState.VALID);
          } else {
            setPasswordHasError(validationState.INVALID);
          }
        } else {
          if (passwordValidator(password)) {
            setPasswordHasError(validationState.VALID);
          } else {
            setPasswordHasError(validationState.INVALID);
          }
        }
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [password]);

  return { password, setPassword, passwordHasError, setPasswordHasError };
};

export default usePasswordValidation;
