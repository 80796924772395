import useAPI from "../../hooks/useAPI";

const VaccineUtilizationApi = () => {
  const { sendRequest } = useAPI();

  const getVaccineUtilization = (getVaccineUtilizationProcessResponse: (response: Response) => void, queryParams: string) => {
    sendRequest(
      {
        url: `vaccine-utilization/${queryParams}`,
      },
      getVaccineUtilizationProcessResponse
    );
  };

  const getVaccineNames = (getNamesProcessResponse: (response: Response) => void) => {
    sendRequest(
      {
        url: "vaccines/",
      },
      getNamesProcessResponse
    );
  };

  const exportVaccineUtilization = (queryParams: string,  exportPreccessResponse: (response: Response) => void) => {
    sendRequest(
      {
        url: `vaccine-utilization/export/${queryParams}`,
        method: "GET",
      },
      exportPreccessResponse
    );
  };

  return { getVaccineUtilization, getVaccineNames, exportVaccineUtilization };
};

export default VaccineUtilizationApi;
