import React, { useMemo } from "react";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
import { useTable, useSortBy, Column, TableOptions } from "react-table";

import Vaccine from "../../../../types/Vaccine";
import TableHeaderCell from "../../../UI/TableHeaderCell";
import VaccineStatusBox from "../../../UI/VaccineStatusBox";
import i18next from "i18next";

const DefaulterMissedVaccinesTable: React.FC<{
    missedVaccines: Vaccine[];
}> = ({ missedVaccines }) => {
    const { t } = useTranslation();

    const columns: Column<Vaccine>[] = useMemo(
        () => [
            {
                header: t("missed_vaccines"),
                accessor: "name",
            },
            {
                header: t("scheduled_date"),
                accessor: "scheduledDate",
                sortType: "datetime",
            },
        ],
        [t]
    );
    const data = useMemo(() => missedVaccines, [missedVaccines]);

    const options: TableOptions<Vaccine> = {
        columns,
        data,
    };

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(options, useSortBy);

    return (
        <div className="relative mt-4">
            <div className="overflow-x-auto overflow-y-hidden scroll-firefox">
                <table {...getTableProps()} className="w-full border-collapse">
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((header, index) => (
                                    <th
                                        {...header.getHeaderProps(header.getSortByToggleProps())}
                                        className={`table__cell border-b border-gray-df td-firefox ${index === 0 && "sticky left-0 z-10"}`}
                                    >
                                        <div className="flex items-center">
                                            <TableHeaderCell header={header} />
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                            prepareRow(row);
                            const { name, scheduledDate } = row.original;

                            return (
                                <tr {...row.getRowProps()} className="border-t border-gray-df">
                                    <td
                                        {...row.cells[0].getCellProps()}
                                        className={`table__cell text-sm text-black41 sticky left-0 ${i18next.language === "ar" && "text-right"} z-10 border-t border-gray-df td-firefox`}
                                    >
                                        <p>{name}</p>
                                    </td>
                                    <td {...row.cells[1].getCellProps()} className="table__cell text-sm text-black41 flex justify-between">
                                        <p className="mt-[8px]">
                                            <Moment date={scheduledDate} format="DD/MM/YYYY" locale={i18next.language === "ar" ? "ar" : "en"} />
                                        </p>
                                        <VaccineStatusBox
                                            statusName={t("optional")}
                                            status={missedVaccines[0].is_optional}
                                            className="bg-[#e7eef5] text-[#11589a] mt-[-1px]"
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default DefaulterMissedVaccinesTable;
