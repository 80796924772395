export const defaultGoogleMapCenter = {
  lat:
    process.env.REACT_APP_Country === "Lebanon"
      ? 33.8547
      : process.env.REACT_APP_Country === "Rwanda"
        ? -1.9403
        : 7.3697,
  lng:
    process.env.REACT_APP_Country === "Lebanon"
      ? 35.5236
      : process.env.REACT_APP_Country === "Rwanda"
        ? 29.8795
        : 12.3547,
};
