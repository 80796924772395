import { AdminRole } from "./Constants";

export const getAdminRole = () => {
    if (localStorage.getItem("auth_data") && JSON.parse(localStorage.getItem("auth_data")!).role !== "")
        return JSON.parse(localStorage.getItem("auth_data")!).role as AdminRole
    return AdminRole.Au
};

export const getHeaderTabs = () => {
    const headerArray =
        [
            {
                route: "/dashboard",
                title: "dashboard",
            },
            {
                route: "/children",
                title: "children",
            },
            {
                route: "/defaulters",
                title: "defaulters",
            },
        ];

    switch (getAdminRole()) {
        case AdminRole.Au:
            headerArray.splice(1,0,{
                route: "/clinics",
                title: "clinics_and_staff",
            },)
            break;

        case AdminRole.Cla:
            headerArray.splice(1,0,{
                route: "/clinic-details",
                title: "clinics_and_staff",
            },)
            break;

        default:
            headerArray.splice(1,0,{
                route: "/clinics",
                title: "clinics_and_staff",
            },)
            break;
    }

    return headerArray;
}
