import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";

const ErrorBanner: React.FC<{ message: string; className?: string }> = (
  props
) => {
  const { message, className } = props;

  return (
    <div
      className={`py-3 px-4 rounded bg-red-primary40 text-red-primary flex items-center ${className}`}
    >
      <FontAwesomeIcon icon={faExclamationTriangle} />
      <p className="ml-3 text-sm first-letter:uppercase">{message}</p>
    </div>
  );
};

export default ErrorBanner;
