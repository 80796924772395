import { LngLat } from "../types/Area";

export const calculatePolygonCenter = (coordinates: LngLat[]): LngLat => {
    const sum = coordinates.reduce(
        (acc, curr) => ({ lng: acc.lng + curr.lng, lat: acc.lat + curr.lat }),
        { lng: 0, lat: 0 }
    );
    const center = {
        lng: sum.lng / coordinates.length,
        lat: sum.lat / coordinates.length,
    };
    return center;
};
