import React from "react";
import { useTranslation } from "react-i18next";

import { ButtonTypes } from "../../utils/Constants";
import Button from "./Button";

const FilterActions: React.FC<{
  isAnyFilterApplied: () => boolean;
  reset: () => void;
  onApply: () => void;
}> = ({ isAnyFilterApplied, reset, onApply }) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center mt-6 2xl:justify-start justify-end">
      {isAnyFilterApplied() && (
        <p
          className="link mx-6"
          onClick={() => {
            reset();
          }}
        >
          {t("reset")}
        </p>
      )}
      <Button type={ButtonTypes.PRIMARY} onClick={() => onApply()}>
        {t("apply")}
      </Button>
    </div>
  );
};

export default FilterActions;
