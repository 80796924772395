import React, { useContext } from "react";
import VaccineUtilization from "../../../../types/VaccineUtilization";
import i18next from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { SelectedVaccineUtilizationContext } from "../../../../store/SelectedVaccineUtilizationContext";

const VaccineUtilizationRow: React.FC<{
  vaccineUtilization: VaccineUtilization;
}> = ({ vaccineUtilization }) => {
  const {
    selectedVaccineUtilization,
    selectVaccineUtilization,
    deselectVaccineUtilization,
  } = useContext(SelectedVaccineUtilizationContext);

  const checked =
    selectedVaccineUtilization.indexOf(vaccineUtilization.id) !== -1;

  return (
    <>
      <tr className="border-t border-gray-df">
        <td
          className={`border-t td-firefox border-gray-df table__cell text-sm text-[#414141] sticky ${
            i18next.language === "ar" ? "right-0 text-right" : "left-0"
          }`}
        >
          <div className="flex items-center">
            <div
              className={`table__checkbox grid place-items-center ${
                i18next.language === "ar" ? "ml-4" : "mr-4"
              } ${checked && "border-blue-primary bg-blue-primary"}`}
              onClick={() => {
                if (checked) {
                  deselectVaccineUtilization(vaccineUtilization.id);
                } else {
                  selectVaccineUtilization(vaccineUtilization.id);
                }
              }}
            >
              {checked && (
                <FontAwesomeIcon
                  icon={faCheck}
                  className="text-sm text-white"
                />
              )}
            </div>
            {vaccineUtilization.name}
          </div>
        </td>
        <td className="border-t td-firefox border-gray-df table__cell text-sm text-[#414141]">
          {i18next.language === "ar"
            ? Intl.NumberFormat("ar-EG").format(
                vaccineUtilization.no_of_administered
              )
            : vaccineUtilization.no_of_administered}
        </td>
        <td className="border-t td-firefox border-gray-df table__cell text-sm text-[#414141]">
          {i18next.language === "ar"
            ? Intl.NumberFormat("ar-EG").format(
                vaccineUtilization.no_of_utilized
              )
            : vaccineUtilization.no_of_utilized}
        </td>
        <td className="border-t td-firefox border-gray-df table__cell text-sm text-[#414141]">
          {i18next.language === "ar"
            ? Intl.NumberFormat("ar-EG").format(
                vaccineUtilization.no_of_defaulters
              )
            : vaccineUtilization.no_of_defaulters}
        </td>
        <td className="border-t td-firefox border-gray-df table__cell text-sm text-[#414141]">
          {i18next.language === "ar"
            ? "%" +
              Intl.NumberFormat("ar-EG").format(
                parseFloat(vaccineUtilization.administration_rate.toFixed(2))
              )
            : parseFloat(vaccineUtilization.administration_rate.toFixed(2)) +
              "%"}
        </td>
        <td className="border-t td-firefox border-gray-df table__cell text-sm text-[#414141]">
          {i18next.language === "ar"
            ? "%" +
              Intl.NumberFormat("ar-EG").format(
                parseFloat(vaccineUtilization.defaulter_rate.toFixed(2))
              )
            : parseFloat(vaccineUtilization.defaulter_rate.toFixed(2)) +
              "%"}
        </td>
      </tr>
    </>
  );
};

export default VaccineUtilizationRow;
