import React from "react";

const PasswordProgressBar: React.FC<{
  label: string;
  value: number;
  color: string;
  className?: string;
}> = (props) => {
  const { label, value, color, className } = props;

  return (
    <div
      data-testid="password-progressbar"
      className={`flex items-center ${className}`}
    >
      <div className="flex-1 h-2 bg-gray-f5">
        <div
          className="h-full duration-500"
          style={{ width: `${value}%`, background: color }}
        />
      </div>
      <p className="text-xs capitalize ml-5" style={{ color }}>
        {label}
      </p>
    </div>
  );
};

export default PasswordProgressBar;
