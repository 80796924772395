import React, { useState, useEffect } from "react";
import { Snackbar, SnackbarContent, CircularProgress } from "@mui/material";

import AuthAPI from "../../store/api/authAPI";
import captchaValidation from "../../utils/captchaValidation";
import useEmailValidation from "../../hooks/UseEmailValidation";
import { validationState, ButtonTypes, AlertModalTypes } from "../../utils/Constants";
import { Error, errorType, ERROR_DEFAULT_VALUE } from "./Login";
import AuthContainer from "../UI/AuthContainer";
import BlockedUserError from "../UI/BlockedUserError";
import ErrorBanner from "../UI/ErrorBanner";
import TextInput from "../UI/TextInput";
import Button from "../UI/Button";
import AlertModal from "../UI/AlertModal";

const CAPTCHA_ACTION = "forgotPassword__sendEmail__submit";

const ForgotPassword = () => {
	const [initailState, setInitailState] = useState(true);
	const [error, setError] = useState<Error>(ERROR_DEFAULT_VALUE);
	const [remainingAttempts, setRemaintingAttempts] = useState<number>();
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [loading, setLoading] = useState(false);
	const [somethingWentWrong, setSomethingWentWrong] = useState(false);

	const { email, emailHasError, setEmail, setEmailHasError } = useEmailValidation();

	const { forgotPasswordRequest } = AuthAPI();

	useEffect(() => {
		if (emailHasError === validationState.INVALID && email !== "") {
			setError({
				type: errorType.EMAIL_FORMAT,
				message: "the email address is incorrect.",
			});
		}
	}, [emailHasError, email]);

	const onFormSubmitted = (event: React.FormEvent) => {
		event.preventDefault();
		setLoading(true);
		captchaRequest();
	};

	const captchaRequest = () => {
		captchaValidation(
			CAPTCHA_ACTION,
			(token) => {
				if (email) forgotPasswordRequest(email, token, processForgotPasswordSendEmailResponse);
			},
			() => {
				setLoading(false);
				setSomethingWentWrong(true);
			}
		);
	};

	const processForgotPasswordSendEmailResponse = async (response: Response) => {
		const result = await response.json();
		setLoading(false);

		if (response.ok) {
			setRemaintingAttempts(result["remained_try"]);
			setError(ERROR_DEFAULT_VALUE);

			if (initailState) {
				setInitailState(false);
			} else {
				setShowSnackbar(true);
			}
		} else {
			switch (response.status) {
				case 400:
				case 404:
					setError({
						type: errorType.EMAIL,
						message: result.email,
					});
					break;
				case 403:
					if ("lock" in result) {
						setError({
							type: errorType.BLOCKED,
							message: result.lock,
						});
					} else if ("resend" in result) {
						setError({
							type: errorType.BLOCKED,
							message: result.resend,
						});
					}
					break;
				case 406:
					setError({
						type: errorType.CAPTCHA,
						message: result.captcha,
					});
					break;
			}
		}
	};

	let emailErrorMessage = "";
	if (email === "") {
		emailErrorMessage = "email is required.";
	} else {
		if (error.type === errorType.EMAIL_FORMAT) {
			emailErrorMessage = error.message || "";
		}
	}

	return (
		<AuthContainer>
			<Snackbar
				open={showSnackbar}
				autoHideDuration={5000}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				sx={{
					"& .MuiSnackbarContent-root": { backgroundColor: "#03A678" },
					"& .MuiSnackbarContent-message": {
						fontSize: "16px",
						fontFamily: "'Open Sans', sans-serif",
						fontWeight: "100",
					},
				}}
				onClose={() => setShowSnackbar(false)}>
				<SnackbarContent
					message={
						<p>
							Another email has been sent to you.
							<br />
							Please also check the Spam/Junk folder.
						</p>
					}
				/>
			</Snackbar>
			{initailState ? (
				<form className="flex flex-col mt-[70px]" onSubmit={onFormSubmitted}>
					<h3 className="text-center capitalize text-base text-gray-41 font-semibold">forgot password?</h3>
					{error.type === errorType.BLOCKED && <BlockedUserError message={error.message || ""} className="mt-8" />}
					{error.type === errorType.EMAIL ||
						(error.type === errorType.CAPTCHA && <ErrorBanner message={error.message || ""} className="mt-8" />)}
					<p className="mt-[18px] text-center text-sm text-gray-41 mb-10 first-letter:uppercase">
						please enter your email to reset your password.
					</p>
					<TextInput
						value={email || ""}
						placeholder="email"
						errorMessage={emailErrorMessage}
						hasError={email === "" || error.type === errorType.EMAIL || error.type === errorType.EMAIL_FORMAT}
						onTextInputChanged={(value) => {
							setEmail(value);
							setEmailHasError(validationState.WAITING);
							setError(ERROR_DEFAULT_VALUE);
						}}
					/>
					<Button
						type={ButtonTypes.PRIMARY}
						className="mt-5"
						disabled={emailHasError !== validationState.VALID}
						dataAction={CAPTCHA_ACTION}>
						{loading && <CircularProgress size={18} style={{ color: "white" }} />}
						{!loading && "send email"}
					</Button>
				</form>
			) : (
				<article className="mt-[55px] text-center">
					<h3 className="text-base text-gray-41 first-letter:uppercase font-semibold">check your mail</h3>
					{error.type === errorType.BLOCKED && <BlockedUserError message={error.message || ""} className="mt-10" />}
					{error.type !== errorType.BLOCKED && (
						<>
							<p className="mt-10 text-gray-41 text-sm">
								An Email has been sent.
								<br /> Please check your email and follow the instructions.
							</p>
							<p className="mt-9 text-sm text-gray-8d">
								{`${remainingAttempts} ${remainingAttempts === 1 ? "attempt" : "attempts"}  remaining`}
							</p>
						</>
					)}
					<p className="text-blue-primary text-sm mt-[52px] cursor-pointer first-letter:uppercase" onClick={captchaRequest}>
						{loading && <CircularProgress size={18} style={{ color: "white" }} />}
						{!loading && "didn't receive any emails?"}
					</p>
				</article>
			)}
			{somethingWentWrong && (
				<AlertModal
					type={AlertModalTypes.SOMETHING_WENT_WRONG}
					onAction={() => window.location.reload()}
					onBackdrop={() => setSomethingWentWrong(false)}
				/>
			)}
		</AuthContainer>
	);
};

export default ForgotPassword;
