import React from "react";

import background from "../../images/background.png";
import logo from "../../images/www.svg";

const IncorrectUrl: React.FC<{
  message: string;
}> = (props) => {
  return (
    <main className="page">
      <img
        className="w-auto h-full absolute right-0 bottom-0"
        src={background}
        alt=""
      />
      <div className="w-screen h-screen flex flex-col justify-evenly items-center z-10 960:flex-row">
        <img src={logo} alt="www" />
        <p className="text-blue-primary text-4xl text-center w-[540px] first-letter:uppercase">
          {props.message}
        </p>
      </div>
    </main>
  );
};

export default IncorrectUrl;
