import React, { PropsWithChildren, useState } from "react";

type ProfileContextType = {
  profileOrAccountSetting: boolean;
  profileOrAccountSettingHandler: (isProfilePage: boolean) => void;
};

export const ProfileContext = React.createContext<ProfileContextType>({
  profileOrAccountSetting: false,
  profileOrAccountSettingHandler: () => {
    //
  },
});

const ProfileOrAccountSettingContext: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [profileOrAccountSetting, setProfileOrAccountSetting] = useState(true);
  const profileOrAccountSettingHandler = (isProfilePage: boolean) => {
    setProfileOrAccountSetting(isProfilePage);
  };

  return (
    <ProfileContext.Provider
      value={{ profileOrAccountSetting, profileOrAccountSettingHandler }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export default ProfileOrAccountSettingContext;
