import React, { useEffect, useState } from "react";
import AccountSetting from "./AccountSetting";
import ChangePassword from "./ChangePassword";

const AccountSettingPage: React.FC<{
  cancelStatusChanged: boolean;
  saveChangePassword: (currentPassword: string, secondPassword: string) => void;
}> = ({ saveChangePassword, cancelStatusChanged }) => {
  const [resetBtnClicked, setResetBtnClicked] = useState(false);

  useEffect(() => {
    setResetBtnClicked(false);
  }, [cancelStatusChanged]);
  return (
    <>
      {!resetBtnClicked ? (
        <AccountSetting resetBtnHandler={() => setResetBtnClicked(true)} />
      ) : (
        <ChangePassword
          cancel={() => setResetBtnClicked(false)}
          saveChangePassword={saveChangePassword}
        />
      )}
    </>
  );
};

export default AccountSettingPage;
