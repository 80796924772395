import React, { useEffect, useContext } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/pro-light-svg-icons";
import Pagination from "../../UI/Pagination";
import StaffRow from "./StaffRow";
import { StaffContext } from "../../../store/StaffContext";
import i18next from "i18next";
import Staff from "../../../types/Staff";
interface Column {
  header: string;
  accessor: any;
}

//////////////////////////////////////////////
const StaffTable: React.FC<{
  columns: Column[];
  data: Staff[];
  editHandler: (status: string) => void;
  updateStatusHandler: (_status: string, _slugStaff: string) => void;
}> = ({ columns, data, editHandler, updateStatusHandler }) => {
  // Use the state and functions returned from useTable to build your UI
  const { skipPageReset } = useContext(StaffContext);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
  } = useTable(
    {
      columns,
      data,
      autoResetPage: skipPageReset,
    },

    useSortBy,
    usePagination
  );

  useEffect(() => {
    page.length === 0 && previousPage();
  }, [page]);

  const selectPage = (_pageNum: number) => {
    gotoPage(_pageNum - 1);
  };

  // Render the UI for your table
  return (
    <>
      {/* table */}
      <div className="relative">
        <div
          id="staff-table__container"
          className="w-full mx-auto  overflow-auto bg-white scroll-firefox overflow-x-auto overflow-y-hidden"
        >
          <table {...getTableProps()} className="border-collapse w-full  ">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} className="border-b border-gray-200 ">
                  {headerGroup.headers.map((column, index) => (
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props

                    <th
                      scope="col"
                      className={`border-b td-firefox border-gray-df table__cell sticky top-0 z-10 text-sm text-[#414141] font-extrabold tracking-wider ${
                        index === 0 && `z-20 ${i18next.language === "ar" ? "right-0" : "left-0"}`
                      }`}
                      {...column.getHeaderProps(column.getSortByToggleProps())} //i replace this method
                    >
                      {column.render("header") !== " " && (
                        <div onClick={() => selectPage(1)} className="flex items-center ">
                          <h3 className="text-sm font-bold text-black41">{column.render("header")}</h3>

                          <div className="flex flex-col-reverse mx-4">
                            <FontAwesomeIcon
                              className={`text-sm ${
                                column.isSorted && column.isSortedDesc ? "text-black41" : "text-gray-df"
                              }`}
                              icon={faAngleDown}
                            />
                            <FontAwesomeIcon
                              className={`text-sm rotate-180 ${
                                column.isSorted && !column.isSortedDesc ? "text-black41" : "text-gray-df"
                              }`}
                              icon={faAngleDown}
                            />
                          </div>
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);

                return (
                  <StaffRow
                    row={row}
                    key={row.id}
                    editHandler={(slug_Staff) => editHandler(slug_Staff)}
                    updateStatusHandler={updateStatusHandler}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        dropdownKeys={["5", "10", "20"]}
        previousPage={previousPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
        gotoPage={gotoPage}
        pageCount={pageCount}
        pageIndex={state.pageIndex}
        pageSize={state.pageSize}
        pageLength={page.length}
        totalLength={data.length}
      />
    </>
  );
};

export default StaffTable;
