import moment from "moment";

import useAPI from "../../hooks/useAPI";
import Defaulter from "../../types/Defaulter";
import DefaulterDetailsType from "../../types/DefaulterDetails";
import { getFormattedDate, DEFAULT_DATE } from "../../utils/DateUtils";

const getMissedVaccines = (missed_vaccines: { name: string; scheduled_date: string; is_discontinued: boolean; is_optional: boolean }[]) => {
	return missed_vaccines.map((missed_vaccine) => ({
		name: missed_vaccine.name,
		scheduledDate: moment(missed_vaccine.scheduled_date, "DD/MM/YYYY").toDate(),
		is_discontinued: missed_vaccine.is_discontinued,
		is_optional: missed_vaccine.is_optional,
	}));
};

const DefaultersAPI = () => {
	const { sendRequest } = useAPI();

	const getSearchedAndFilteredDefaulters = (
		searchTerm: string,
		clinicTitleSlug: string,
		lastReminderSentFrom: Date | null,
		lastReminderSentTo: Date | null,
		missedVaccinesScheduledDateFrom: Date | null,
		missedVaccinesScheduledDateTo: Date | null,
		isMandatory: boolean,
		processGetSearchedAndFilteredDefaultersResponse: (defaulters: Defaulter[]) => void
	) => {
		sendRequest(
			{
				url: `defaulters/?${searchTerm && "search=" + searchTerm}${clinicTitleSlug && "&clinic_slug=" + clinicTitleSlug}${
					lastReminderSentFrom ? "&reminder_from=" + getFormattedDate(lastReminderSentFrom, false) : ""
				}${lastReminderSentTo ? "&reminder_to=" + getFormattedDate(lastReminderSentTo, false) : ""}${
					missedVaccinesScheduledDateFrom ? "&scheduled_from=" + getFormattedDate(missedVaccinesScheduledDateFrom, false) : ""
				}${missedVaccinesScheduledDateTo ? "&scheduled_to=" + getFormattedDate(missedVaccinesScheduledDateTo, false) : ""}${isMandatory ? "&is_mandatory=" + isMandatory : ""}`,
			},
			async (response) => {
				if (response.ok) {
					const result = await response.json();

					processGetSearchedAndFilteredDefaultersResponse(
						result.map(
							(defaulter: {
								slug: string;
								name: string;
								note: string;
								phone_number: string;
								national_id: string;
								area: string;
								clinic_title: string;
								missed_vaccines: {
									name: string;
									scheduled_date: string;
									is_discontinued: boolean;
									is_optional: boolean;
								}[];
								last_reminder_sent: string | null;
							}) => {
								const missedVaccines = getMissedVaccines(defaulter.missed_vaccines);

								return {
									slug: defaulter.slug,
									name: defaulter.name,
									note: defaulter.note,
									phoneNumber: defaulter.phone_number,
									nationalID: defaulter.national_id,
									area: defaulter.area,
									clinicTitle: defaulter.clinic_title,
									missedVaccines,
									lastReminderSent: defaulter.last_reminder_sent
										? moment(defaulter.last_reminder_sent, "DD/MM/YYYY").toDate()
										: DEFAULT_DATE,
								};
							}
						)
					);
				}
			}
		);
	};

	const resendReminder = (selectedDefaultersSlug: { slug: string }[], processResendReminderResponse: (response: Response) => void) => {
		sendRequest(
			{
				url: "send-reminders/",
				method: "POST",
				body: JSON.stringify(selectedDefaultersSlug),
			},
			processResendReminderResponse
		);
	};

	const getDefaulterDetails = (
		slug: string,
		processGetDefaulterDetailsResponse: (response: Response, defaulter: DefaulterDetailsType) => void
	) => {
		sendRequest(
			{
				url: `defaulters/${slug}/`,
			},
			async (response) => {
				const result = await response.json();

				const missedVaccines = getMissedVaccines(result.missed_vaccines);

				const activityLogs = result.activity_log.map(
					(activity_log: {
						id: number;
						owner_name: string;
						owner_role: string;
						date: string;
						activity_text: string;
						owner_photo: string;
					}) => {
						return {
							id: activity_log.id,
							ownerName: activity_log.owner_name,
							ownerRole: activity_log.owner_role,
							date: activity_log.date,
							activityText: activity_log.activity_text,
							ownerPhoto: activity_log.owner_photo,
						};
					}
				);

				processGetDefaulterDetailsResponse(response, {
					firstName: result.first_name,
					lastName: result.last_name,
					nationalID: result.national_id,
					birthDate: result.birth_date,
					gender: result.gender,
					clinicTitle: result.clinic_title,
					phoneNumber: result.phone_number,
					area: result.area,
					email: result.email,
					street: result.street,
					caregiverFirstName: result.caregiver_first_name,
					caregiverLastName: result.caregiver_last_name,
					caregiverType: result.caregiver_type,
					caregiverTypeKey: result.caregiver_type_key,
					caregiverNationalID: result.caregiver_national_id,
					relation: result.relation,
					missedVaccines,
					activityLogs,
				});
			}
		);
	};

	const addActivityLog = (slug: string, note: string, processAddActivityLogResponse: (response: Response) => void) => {
		sendRequest(
			{
				url: `defaulters/${slug}/activity-logs/`,
				method: "POST",
				body: JSON.stringify({ activity_text: note }),
			},
			processAddActivityLogResponse
		);
	};

	const exportDefaulter = (
		searchTerm: string,
		clinicTitleSlug: string,
		lastReminderSentFrom: Date | null,
		lastReminderSentTo: Date | null,
		missedVaccinesScheduledDateFrom: Date | null,
		missedVaccinesScheduledDateTo: Date | null,
		exportPreccessResponse: (response: Response) => void
		) => {
		sendRequest(
			{
				url: `defaulters-admin/export/?${searchTerm && "search=" + searchTerm}${clinicTitleSlug && "&clinic_slug=" + clinicTitleSlug}${
					lastReminderSentFrom ? "&reminder_from=" + getFormattedDate(lastReminderSentFrom, false) : ""
				}${lastReminderSentTo ? "&reminder_to=" + getFormattedDate(lastReminderSentTo, false) : ""}${
					missedVaccinesScheduledDateFrom ? "&scheduled_from=" + getFormattedDate(missedVaccinesScheduledDateFrom, false) : ""
				}${missedVaccinesScheduledDateTo ? "&scheduled_to=" + getFormattedDate(missedVaccinesScheduledDateTo, false) : ""}`,
				method: "GET",
				
			},
			exportPreccessResponse
		);
	};

	return { getSearchedAndFilteredDefaulters, resendReminder, getDefaulterDetails, addActivityLog ,exportDefaulter};
};

export default DefaultersAPI;
