import React from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";

import Button from "./Button";
import { ButtonTypes } from "../../utils/Constants";

const ResendReminderModal: React.FC<{
    onCancel: () => void;
    onResendReminderButtonClicked: () => void;
    onBackdrop: () => void;
    loading: boolean;
    titleKey: string;
    message: string;
    resendButtonLabelKey: string;
}> = ({ onCancel, onResendReminderButtonClicked, onBackdrop, loading, titleKey, message, resendButtonLabelKey }) => {
    const { t } = useTranslation();

    return (
        <div className="backdrop" onClick={() => onBackdrop()}>
            <section className="bg-white shadow-md p-6 cursor-auto w-full 480:w-auto 480:rounded" onClick={(e) => e.stopPropagation()}>
                <h3 className="capitalize text-black27 text-lg font-semibold">{t(titleKey)}</h3>
                <p className="text-sm text-black41 first-letter:uppercase mt-10">{message}</p>
                <div className="flex mt-10 justify-end items-center">
                    <Button type={ButtonTypes.CANCEL} onClick={() => onCancel()} className="mx-3">
                        {t("cancel")}
                    </Button>
                    <Button type={ButtonTypes.PRIMARY} onClick={onResendReminderButtonClicked}>
                        {loading ? <CircularProgress size={18} style={{ color: "white" }} /> : t(resendButtonLabelKey)}
                    </Button>
                </div>
            </section>
        </div>
    );
};

export default ResendReminderModal;
