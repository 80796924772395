import { Row } from 'react-table';

export const tableStringSort = <T extends object>(rowA: Row<T>, rowB: Row<T>, columnId: string) => {
  const a = rowA.values[columnId];
  const b = rowB.values[columnId];

  if (a === null && b === null) return 0;
  if (a === null) return 1;
  if (b === null) return -1;

  return a.toLowerCase().localeCompare(b.toLowerCase());
};
