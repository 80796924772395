import { useContext } from "react";
import { AuthContext } from "../store/AuthContext";
import { AdminRole } from "../utils/Constants";

const useRole = () => {
  const { authData } = useContext(AuthContext);
  const isClinicAdmin = () => authData.role === AdminRole.Cla;

  return {
    isClinicAdmin
  };
};

export default useRole;
