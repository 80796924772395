import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import EN from "../../images/english.svg";
import FR from "../../images/french.svg";
import AR from "../../images/lebanon.svg";
import RW from "../../images/rwanda.svg";
import i18next from "i18next";

const TextAreaInput: React.FC<{
  title: string;
  placeholder: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
  className?: string;
  isRequired: boolean;
  hasFlag: boolean;
  showNumberOfChars: boolean;
  isRTL?:boolean;
}> = ({
  title,
  placeholder,
  onChange,
  value,
  className,
  isRequired,
  hasFlag,
  showNumberOfChars,
  isRTL,
}) => {
  const { t } = useTranslation();
  const [textAreaClicked, setTextAreaClicked] = useState(false);
  const [numberOfCharachter, setNumberOfCharachter] = useState(0);
  const [numberOfSms, setNumberOfSms] = useState(0);

  useEffect(() => {
    calculateNumberOfCharachter(value);
  }, [value]);

  const calculateNumberOfCharachter = (_input: string) => {
    const numberOfCharacters = _input.length;
    setNumberOfCharachter(numberOfCharacters);
    const numberOfInputSms = (numberOfCharacters - 1) / 160;

    if (numberOfCharacters === 0) {
      setNumberOfSms(0);
    } else {
      setNumberOfSms(Math.floor(numberOfInputSms) + 1);
    }
  };

  return (
    <div className="sm:mt-2 mt-3">
      <label
        className="text-sm flex relative"
        dir={i18next.language === "ar" ? "rtl" : "ltr"}
      >
        {title}
        {isRequired && <span className="text-[#D11C1C] mx-1.5">*</span>}
        {hasFlag && (
          <img
            className="w-[16px] h-[11px] d-none mt-1.5"
            src={
              placeholder.includes("(EN)")
                ? EN
                : placeholder.includes("(FR)")
                ? FR
                : placeholder.includes("(AR)")
                ? AR
                : placeholder.includes("(RW)")
                ? RW
                : ""
            }
            alt={
              placeholder.includes("(EN)")
                ? "English"
                : placeholder.includes("(FR)")
                ? "French"
                : placeholder.includes("(AR)")
                ? "Arabic"
                : placeholder.includes("(RW)")
                ? "Kinyarwanda"
                : ""
            }
          />
        )}
      </label>
      <div className="mt-2">
        <textarea
          dir={isRTL ? "rtl" : "ltr"}
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e)}
          onClick={() => setTextAreaClicked(true)}
          onBlur={() => setTextAreaClicked(false)}
          className={`resize-none ${
            !textAreaClicked && "bg-[#F6F6F6]"
          } border-[1px] border-[#DFDFDF] w-full h-[127px] rounded outline-none py-[7px] px-2.5 scroll-firefox ${
            textAreaClicked && "border-[#71A0CD]"
          } ${className}`}
        ></textarea>
      </div>
      {showNumberOfChars && (
        <div className="flex flex-row-reverse text-sm text-[#414141]">
          {i18next.language === "ar"
            ? Intl.NumberFormat("ar-EG").format(numberOfCharachter)
            : numberOfCharachter}{" "}
          {t("charachter")} (
          {i18next.language === "ar"
            ? Intl.NumberFormat("ar-EG").format(numberOfSms)
            : numberOfSms}{" "}
          SMS)
        </div>
      )}
    </div>
  );
};

export default TextAreaInput;
