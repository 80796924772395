import React from "react";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/pro-solid-svg-icons";
import Vaccine from "../../../types/Vaccine";
import i18next, { t } from "i18next";
import VaccineStatusBox from "../../UI/VaccineStatusBox";

const MissedVaccineCell: React.FC<{
  missedVaccines: Vaccine[];
  showOtherMissedVaccines: boolean;
  setShowOtherMissedVaccines: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  missedVaccines,
  showOtherMissedVaccines,
  setShowOtherMissedVaccines,
}) => {
  return (
    <section>
      <article className="flex">
        <div>
          <div className="flex items-center">
            <p className="font-semibold capitalize">{missedVaccines[0].name}</p>
            {missedVaccines.length > 1 && (
              <p
                className="w-6 h-6 rounded bg-gray-e8 text-blue-primary grid place-items-center text-xs mx-2 cursor-pointer hover:bg-blue-primary hover:text-white"
                onClick={() =>
                  setShowOtherMissedVaccines(
                    (preShowOtherMissedVaccine) => !preShowOtherMissedVaccine
                  )
                }
              >
                {showOtherMissedVaccines ? (
                  <FontAwesomeIcon icon={faMinus} />
                ) : (i18next.language === "ar" ? (
                  `${Intl.NumberFormat("ar-EG").format(missedVaccines.length - 1)}+`
                  ) : (`+${missedVaccines.length - 1}`)
                )}
              </p>
            )}
          </div>
          <p className="mt-2">
            <Moment
              date={missedVaccines[0].scheduledDate}
              format="DD/MM/YYYY"
              locale={i18next.language === "ar" ? "ar" : "en"}
            />
          </p>
        </div>
        <VaccineStatusBox
          statusName={t("optional")}
          status={missedVaccines[0].is_optional}
          className={`bg-[#e7eef5] text-[#11589a] mt-2 ${i18next.language === "ar" ? "mr-6": "ml-6"}`}
        />
      </article>
      {showOtherMissedVaccines &&
        missedVaccines.map((missedVaccine, index) => {
          if (index === 0) return;

          return (
            <article key={index} className="mt-4">
              <div className=" flex">
                <div>
                  <p className="font-semibold capitalize">
                    {missedVaccine.name}
                  </p>
                  <p className="mt-2">
                    <Moment
                      date={missedVaccine.scheduledDate}
                      format="DD/MM/YYYY"
                      locale={i18next.language === "ar" ? "ar" : "en"}
                    />
                  </p>
                </div>
                <VaccineStatusBox
                  statusName={t("optional")}
                  status={missedVaccine.is_optional}
                  className={`bg-[#e7eef5] text-[#11589a] mt-2 ${i18next.language === "ar" ? "mr-6": "ml-6"}`}
                />
              </div>
            </article>
          );
        })}
    </section>
  );
};

export default MissedVaccineCell;
