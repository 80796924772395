import useAPI from "../../hooks/useAPI";

const VaccineAPI = () => {
  const { sendRequest } = useAPI();

  const getVaccines = (processGetVaccineResponse: (response: Response) => void) => {
    sendRequest(
      {
        url: "vaccinations/",
      },
      processGetVaccineResponse
    );
  };

  const importVaccine = (processImportVaccineResponse: (response: Response) => void, formData: FormData) => {
    sendRequest(
      {
        url: `vaccinations/import/?country=${process.env.REACT_APP_Country}`,
        contentType: true,
        method: "POST",
        body: formData,
      },
      processImportVaccineResponse
    );
  };

  const deleteVaccine = (processDeleteVaccineResponse: (response: Response) => void, _id: number) => {
    const id = _id.toString();
    sendRequest(
      {
        url: `vaccinations/${id}/`,
        method: "DELETE",
      },
      (response: Response) => {
        processDeleteVaccineResponse(response);
      }
    );
  };

  const downloadVaccine = (downloadProcessResponse: (response: Response) => void) => {
    sendRequest(
      {
        url: `vaccinations/template/?country=${process.env.REACT_APP_Country}`,
        method: "GET",
        contentType: true,
      },
      downloadProcessResponse
    );
  };

  const discountinueVaccine = (
    discointinueProcessResponse: (response: Response) => void,
    vaccine_name: { en: string; fr: string; ar: string; rw: string },
    dose_detail: { en: string; fr: string; ar: string; rw: string },
    optional: boolean,
    preBirth: boolean,
    _id: string
  ) => {
    const id = _id.toString();
    sendRequest(
      {
        url: `vaccinations/${id}/`,
        method: "PATCH",
        body: JSON.stringify({
          name: { en: vaccine_name.en, fr: vaccine_name.fr, ar: vaccine_name.ar, rw: vaccine_name.rw },
          dose_detail: { en: dose_detail.en, fr: dose_detail.fr, ar: dose_detail.ar, rw: dose_detail.rw },
          is_discontinue: true,
          is_optional: optional,
          is_pre_birth: preBirth,
        }),
      },
      discointinueProcessResponse
    );
  };

  const searchVaccine = (discointinueProcessResponse: (response: Response) => void, _searchValue: string) => {
    sendRequest(
      {
        url: `vaccinations/?search=${_searchValue}`,
      },
      discointinueProcessResponse
    );
  };

  const updateVaccineOrder = (
    updateOrderProcessResponse: (response: Response) => void,
    rowId: number,
    _oldOrder: number,
    _newOrder: number
  ) => {
    const url = `vaccinations/${rowId}/`;
    sendRequest(
      {
        url: url,
        method: "PATCH",
        body: JSON.stringify({
          old_order: _oldOrder,
          new_order: _newOrder,
        }),
      },
      updateOrderProcessResponse
    );
  };

  const addVaccine = (
    addVaccineProcessResponse: (response: Response) => void,
    _name: { en: string; fr: string; ar: string; rw: string },
    _dose_detail: { en: string; fr: string; ar: string; rw: string },
    _optional: boolean,
    _preBirth: boolean
  ) => {
    sendRequest(
      {
        url: `vaccinations/?country=${process.env.REACT_APP_Country}`,
        method: "POST",
        body: JSON.stringify({
          name: { en: _name.en, fr: _name.fr, ar: _name.ar, rw: _name.rw },
          dose_detail: { en: _dose_detail.en, fr: _dose_detail.fr, ar: _dose_detail.ar, rw: _dose_detail.rw },
          is_optional: _optional,
          is_pre_birth: _preBirth,
        }),
      },
      addVaccineProcessResponse
    );
  };

  const editVaccine = (
    editVaccineProcessResponse: (response: Response) => void,
    _name: { en: string; fr: string; ar: string; rw: string },
    _dose_detail: { en: string; fr: string; ar: string; rw: string },
    _optional: boolean,
    _preBirth: boolean,
    _editVaccineId: number
  ) => {
    sendRequest(
      {
        url: `vaccinations/${_editVaccineId}/?country=${process.env.REACT_APP_Country}`,
        method: "PUT",
        body: JSON.stringify({
          name: { en: _name.en, fr: _name.fr, ar: _name.ar, rw: _name.rw },
          dose_detail: { en: _dose_detail.en, fr: _dose_detail.fr, ar: _dose_detail.ar, rw: _dose_detail.rw },
          is_optional: _optional,
          is_pre_birth: _preBirth,
        }),
      },
      editVaccineProcessResponse
    );
  };
  return {
    getVaccines,
    importVaccine,
    deleteVaccine,
    downloadVaccine,
    discountinueVaccine,
    searchVaccine,
    updateVaccineOrder,
    addVaccine,
    editVaccine,
  };
};

export default VaccineAPI;
