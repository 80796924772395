import { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";

import { AuthContext } from "../../store/AuthContext";
import AuthAPI from "../../store/api/authAPI";
import useEmailValidation from "../../hooks/UseEmailValidation";
import captchaValidation from "../../utils/captchaValidation";
import { validationState, ButtonTypes, AlertModalTypes } from "../../utils/Constants";
import AuthContainer from "../UI/AuthContainer";
import PasswordInput from "../UI/PasswordInput";
import ErrorBanner from "../UI/ErrorBanner";
import BlockedUserError from "../UI/BlockedUserError";
import TextInput from "../UI/TextInput";
import Button from "../UI/Button";
import AlertModal from "../UI/AlertModal";

export const enum errorType {
	CAPTCHA,
	EMAIL,
	PASSWORD,
	BLOCKED,
	EMAIL_FORMAT,
	NONE,
}

export type Error = {
	type: errorType;
	message?: string;
	remainingAttempts?: number;
};

export const ERROR_DEFAULT_VALUE = {
	type: errorType.NONE,
};

const CAPTCHA_ACTION = "login__submit";

const Login = () => {
	const [password, setPassword] = useState<string>();
	const [error, setError] = useState<Error>(ERROR_DEFAULT_VALUE);

	const { email, setEmail, emailHasError, setEmailHasError } = useEmailValidation();

	const { login } = useContext(AuthContext);
	const { loginRequest } = AuthAPI();

	const [emailIsEmpty, setEmailIsEmpty] = useState(false);
	const [passwordIsEmpty, setPasswordIsEmpty] = useState(false);
	const [loading, setLoading] = useState(false);
	const [somethingWentWrong, setSomethingWentWrong] = useState(false);

	useEffect(() => {
		if (emailHasError === validationState.INVALID && email !== "") {
			setError({
				type: errorType.EMAIL_FORMAT,
				message: "the email address is incorrect.",
			});
		}
	}, [emailHasError, email]);

	useEffect(() => {
		setEmailIsEmpty(email === "");
	}, [email]);

	useEffect(() => {
		setPasswordIsEmpty(password === "");
	}, [password]);

	const onLoginFormSubmitted = (event?: React.FormEvent) => {
		event?.preventDefault();
		setLoading(true);
		
		captchaValidation(
			CAPTCHA_ACTION,
			(token) => {
				if (email && password) loginRequest(email, password, token, processLoginResponse);
			},
			() => {
				setLoading(false);
				setSomethingWentWrong(true);
			}
		);
	};

	const processLoginResponse = async (response: Response) => {
		const result = await response.json();
		setLoading(false);

		if (response.ok) {
			setError(ERROR_DEFAULT_VALUE);

			login({
				access: result.access,
				refresh: result.refresh,
				firstName: result["first_name"],
				lastName: result["last_name"],
				avatar: result.photo,
				email: result.email,
				lang: result["preferred_language"],
				role: result["role"],
			});
		} else {
			switch (response.status) {
				case 400:
					if ("email" in result) {
						setEmailIsEmpty(true);
					}

					if ("password" in result) {
						setPasswordIsEmpty(true);
					}
					break;
				case 401:
					setError({
						type: errorType.PASSWORD,
						message: result.password,
						remainingAttempts: result["remained_try"],
					});
					break;
				case 403:
					setError({
						type: errorType.BLOCKED,
						message: result.lock,
					});
					break;
				case 404:
					setError({
						type: errorType.EMAIL,
						message: result.email,
					});
					break;
				case 406:
					setError({
						type: errorType.CAPTCHA,
						message: result.captcha,
					});
					break;
			}
		}
	};

	let emailErrorMessage = "";
	if (emailIsEmpty) {
		emailErrorMessage = "email is required.";
	} else {
		if (error.type === errorType.EMAIL_FORMAT) {
			emailErrorMessage = error.message || "";
		}
	}

	return (
		<AuthContainer>
			<form className="flex flex-col mt-[84px]" onSubmit={onLoginFormSubmitted}>
				{error.type !== errorType.NONE && error.type !== errorType.EMAIL_FORMAT && error.type !== errorType.BLOCKED && (
					<ErrorBanner message={error.message || ""} />
				)}
				{error.type === errorType.PASSWORD && (
					<p className="text-gray-27 text-xs mt-1">
						{`${error.remainingAttempts} ${error.remainingAttempts && error.remainingAttempts > 1 ? "attempts" : "attempt"
							} remaining`}
					</p>
				)}
				{error.type === errorType.BLOCKED && <BlockedUserError message={error.message || ""} />}
				<TextInput
					value={email || ""}
					placeholder="email"
					className="mt-5"
					errorMessage={emailErrorMessage}
					hasError={emailIsEmpty || error.type === errorType.EMAIL || error.type === errorType.EMAIL_FORMAT}
					onTextInputChanged={(value) => {
						setEmail(value);
						if (error.type !== errorType.PASSWORD) {
							setError(ERROR_DEFAULT_VALUE);
							setEmailHasError(validationState.WAITING);
						}
					}}
				/>
				<PasswordInput
					hasError={passwordIsEmpty || error.type === errorType.PASSWORD}
					onPasswordChange={(value) => {
						setPassword(value);
						if (error.type !== errorType.EMAIL) {
							setError(ERROR_DEFAULT_VALUE);
						}
					}}
					placeholder="password"
				/>
				<div className={`flex items-center ${passwordIsEmpty ? "justify-between" : "justify-end"}`}>
					{passwordIsEmpty && <p className="text-xs text-red-primary mt-2 first-letter:uppercase">password is required.</p>}
					<Link to="/forgot-password" className="cursor-pointer capitalize text-blue-primary text-xs mt-2 block text-right">
						forgot password
					</Link>
				</div>
				<Button
					type={ButtonTypes.PRIMARY}
					className="mt-8"
					disabled={
						email === undefined || password === undefined || emailIsEmpty || passwordIsEmpty || error.type !== errorType.NONE
					}
					dataAction={CAPTCHA_ACTION}>
					{loading ? <CircularProgress size={18} style={{ color: "white" }} /> : "login"}
				</Button>
			</form>
			{somethingWentWrong && (
				<AlertModal
					type={AlertModalTypes.SOMETHING_WENT_WRONG}
					onAction={() => window.location.reload()}
					onBackdrop={() => setSomethingWentWrong(false)}
				/>
			)}
		</AuthContainer>
	);
};

export default Login;
