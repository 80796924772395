import React, { PropsWithChildren, useState } from "react";

type SelectedVaccineUtilizationContextType = {
  selectedVaccineUtilization: string[];
  selectVaccineUtilization: (slug: string) => void;
  deselectVaccineUtilization: (slug: string) => void;
  selectAll: (slug: string[]) => void;
  deselectAll: (slug: string[]) => void;
  clearSelectedVaccineUtilization: () => void;
};

export const SelectedVaccineUtilizationContext =
  React.createContext<SelectedVaccineUtilizationContextType>({
    selectedVaccineUtilization: [],
    selectVaccineUtilization: () => {
      //
    },
    deselectVaccineUtilization: () => {
      //
    },
    selectAll: () => {
      //
    },
    deselectAll: () => {
      //
    },
    clearSelectedVaccineUtilization: () => {
      //
    },
  });

const SelectedVaccineUtilizationContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [selectedVaccineUtilization, setSelectedVaccineUtilization] = useState<string[]>([]);

  const selectVaccineUtilization = (slug: string) => {
    setSelectedVaccineUtilization((preSelectedVaccineUtilization) => {
      if (preSelectedVaccineUtilization.indexOf(slug) === -1) {
        return [...preSelectedVaccineUtilization, slug];
      } else {
        return preSelectedVaccineUtilization;
      }
    });
  };

  const deselectVaccineUtilization = (slug: string) => {
    setSelectedVaccineUtilization((preSelectedVaccineUtilization) =>
      preSelectedVaccineUtilization.filter(
        (preSelectedVaccineUtilization) => preSelectedVaccineUtilization !== slug
      )
    );
  };

  const selectAll = (slugs: string[]) => {
    setSelectedVaccineUtilization((preSelectedVaccineUtilization) => [
      ...preSelectedVaccineUtilization,
      ...slugs.filter((slug) => preSelectedVaccineUtilization.indexOf(slug) === -1),
    ]);
  };

  const deselectAll = (slugs: string[]) => {
    setSelectedVaccineUtilization((preSelectedVaccineUtilization) =>
      preSelectedVaccineUtilization.filter(
        (preSelectedVaccineUtilization) => slugs.indexOf(preSelectedVaccineUtilization) === -1
      )
    );
  };

  const clearSelectedVaccineUtilization = () => {
    setSelectedVaccineUtilization([]);
  };

  return (
    <SelectedVaccineUtilizationContext.Provider
      value={{
        selectedVaccineUtilization,
        selectVaccineUtilization,
        deselectVaccineUtilization,
        selectAll,
        deselectAll,
        clearSelectedVaccineUtilization,
      }}
    >
      {children}
    </SelectedVaccineUtilizationContext.Provider>
  );
};

export default SelectedVaccineUtilizationContextProvider;
