import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/pro-solid-svg-icons";

import ActivityLogType from "../../../../types/ActivityLog";
import DefaultersAPI from "../../../../store/api/defaultersAPI";
import ActivityLog from "./ActivityLog";
import TextInput from "../../../UI/TextInput";

const ActivityLogs: React.FC<{
	activityLogs: ActivityLogType[];
	slug: string;
	onAddActivityLog: (activityLog: ActivityLogType) => void;
}> = ({ activityLogs, slug, onAddActivityLog }) => {
	const [note, setNote] = useState("");
	const [loading, setLoading] = useState(false);

	const activityLogsContainer = useRef<HTMLDivElement>(null);

	const { t } = useTranslation();
	const { addActivityLog } = DefaultersAPI();

	const onSaveActivityLogButtonClicked = () => {
		if(note){
			setLoading(true);
			addActivityLog(slug, note, processAddActivityLogRespone);
		}
	};

	const processAddActivityLogRespone = async (response: Response) => {
		if (response.ok) {
			const result = await response.json();
			setLoading(false);
			onAddActivityLog({
				id: result.id,
				ownerName: result.owner_name,
				ownerRole: result.owner_role,
				date: result.date,
				activityText: result.activity_text,
				ownerPhoto: result.owner_photo,
			});
			activityLogsContainer.current!.scrollTo({ top: 0, behavior: "smooth" });
			setNote("");
		}
	};

	return (
		<section className="bg-white mt-4 rounded px-6 py-4 flex flex-col justify-between 888:h-[93%] 888:mt-0">
			<h3 className="text-sm text-black41 font-semibold">{t("activity_log")}</h3>
			<div ref={activityLogsContainer} className="h-[480px] mt-2 overflow-auto scroll-firefox 888:flex-1">
				{activityLogs !== undefined &&
					activityLogs.map((activityLog, index) => {
						let className = "mt-3";
						if (index === 0) className = "";

						return <ActivityLog key={activityLog.id} activityLog={activityLog} className={className} />;
					})}
			</div>
			<form className="mt-2">
				<label className="text-sm text-black41">{t("insert_a_note")}</label>
				<div className="flex justify-between items-center mt-1">
					<TextInput
						className="flex-1"
						value={note}
						onTextInputChanged={(value) => setNote(value)}
						hasError={false}
						errorMessage=""
						placeholder=""
					/>
					<div
						onClick={onSaveActivityLogButtonClicked}
						className={`w-10 h-10 mx-2 grid place-items-center rounded text-xl ${
							note ? "cursor-pointer bg-blue-primary text-white" : "cursor-not-allowed bg-gray-612d text-gray-bd"
						}`}>
						{loading ? <CircularProgress size={18} style={{ color: "white" }} /> : <FontAwesomeIcon icon={faSave} />}
					</div>
				</div>
			</form>
		</section>
	);
};

export default ActivityLogs;
