import React, { useState, useEffect } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import Pagination from "../../../UI/Pagination";
import VaccineRow, { Vaccine } from "./VaccineRow";

import { DragDropContext, Droppable, Draggable, DropResult, DraggableProvided } from "react-beautiful-dnd";
import i18next from "i18next";

interface Column {
  header: string;
  accessor: any;
}

//////////////////////////////////////////////
const VaccineTable: React.FC<{
  isDragDisabled: boolean;
  columns: Column[];
  data: Vaccine[];
  editHandler: (id: number) => void;
  deleteHandler: (id: number) => void;
  discontinueHandler: (
    vaccine_name: { en: string; fr: string; ar: string; rw: string },
    dose_detail: { en: string; fr: string; ar: string; rw: string },
    optional: boolean,
    preBirth: boolean,
    id: number
  ) => void;
  updateData: (result: DropResult, pageIndex: number, pageSize: number) => void;
  update_order: (id: number, _oldOrder: number, _newOrder: number) => void;
}> = ({ columns, data, editHandler, updateData, deleteHandler, discontinueHandler, isDragDisabled, update_order }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 20,
      },
      autoResetPage: false,
    },

    useSortBy,
    usePagination
  );
  const { pageIndex, pageSize } = state;
  const selectPage = (_pageNum: number) => {
    gotoPage(_pageNum - 1);
  };

  const [rowId, setRowId] = useState(0);
  const [_data, setData] = useState<Array<any>>(page);

  useEffect(() => {
    setData(page);
  }, [data, page]);

  const onDragEndHandler = (result: DropResult) => {
    if (!result.destination) return;

    update_order(rowId, pageIndex * pageSize + result.source.index, pageIndex * pageSize + result.destination.index);

    updateData(result, pageIndex, pageSize);
  };

  useEffect(() => {
    page.length === 0 && previousPage();
  }, [page]);

  return (
    <>
      {/* table */}
      <div className="relative">
        <div
          id="staff-table__container"
          className="w-full mx-auto  overflow-auto bg-white scroll-firefox overflow-x-auto overflow-y-hidden"
        >
          <DragDropContext onDragEnd={onDragEndHandler}>
            <Droppable droppableId="vaccine">
              {(provided) => (
                <table
                  {...getTableProps()}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="border-collapse w-full  "
                >
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()} className="border-b border-gray-200 ">
                        {headerGroup.headers.map((column, index) => (
                          // Add the sorting props to control sorting. For this example
                          // we can add them into the header props

                          <th
                            scope="col"
                            className={`border-b td-firefox border-gray-df table__cell sticky top-0 z-10 text-sm text-[#414141] font-extrabold tracking-wider ${
                              index === 0 && `z-20 ${i18next.language === "ar" ? "right-0" : "left-0"}`
                            }`}
                            {...column.getHeaderProps(column.getSortByToggleProps())} //i replace this method
                          >
                            {column.render("header") !== " " && (
                              <div onClick={() => selectPage(1)} className="flex items-center ">
                                <h3 className="text-sm font-bold text-black41">{column.render("header")}</h3>
                              </div>
                            )}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {_data.map((row, i) => (
                      <Draggable isDragDisabled={isDragDisabled} key={row.id} draggableId={String(row.id)} index={i}>
                        {(_provided: DraggableProvided) => (
                          <VaccineRow
                            isDragDisabled={isDragDisabled}
                            deleteHandler={(id) => deleteHandler(id)}
                            discontinueHandler={(vaccine_name, dose_detail, optional, preBirth, id) =>
                              discontinueHandler(vaccine_name, dose_detail, optional, preBirth, id)
                            }
                            row={row}
                            rowHandler={(x) => setRowId(x)}
                            key={row.id}
                            provided={_provided}
                            editHandler={(id) => {
                              editHandler(id);
                            }}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </tbody>
                </table>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
      <Pagination
        dropdownKeys={["5", "10", "20"]}
        previousPage={previousPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
        gotoPage={gotoPage}
        pageCount={pageCount}
        pageIndex={state.pageIndex}
        pageSize={state.pageSize}
        pageLength={page.length}
        totalLength={data.length}
      />
    </>
  );
};

export default VaccineTable;
