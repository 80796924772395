export const getDate = (input: string, isSlashedFormat: boolean) => {
  const dateObj = new Date(input);
  const yyyy = dateObj.getFullYear();
  const dd = String(dateObj.getDate()).padStart(2, "0");
  const mm = String(dateObj.getMonth() + 1).padStart(2, "0");

  if (isSlashedFormat) {
    return `${dd}/${mm}/${yyyy}`;
  } else return `${yyyy}-${mm}-${dd}`;
};
