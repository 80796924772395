export const ERROR_INLINR_STYLE = {
  borderColor: "#d11c1c",
  color: "#d11c1c",
};

export const MAP_CONTAINER_STYLE = {
  width: "100%",
  height: "100%",
};

export const enum AdminRole {
  Su = "Su", //Super User
  Stf = "Stf", // Staff
  Au = "Au", // Admin User
  Cg = "Cg", // CareGiver
  Cla = "Cla" // Clinic Admin
};

export const enum validationState {
  VALID,
  INVALID,
  WAITING,
};

export const enum ButtonTypes {
  PRIMARY,
  SECONDARY,
  CANCEL,
  ERROR,
};

export const enum AlertModalTypes {
  SOMETHING_WENT_WRONG,
};

export const enum StatusBadgeTypes {
  ACTIVE,
  INACTIVE,
  PENDING,
  SUBSCRIBE,
  UNSUBSCRIBE,
  RESPONDED,
};

export const enum AddOrEditModal {
  ADD,
  EDIT,
  ERROR,
  DELETE,
};

export const enum SnackBarType {
  ADD,
  EDIT,
  DELETE,
  DOWNLOAD,
  IMPORT,
  IMPORTFAILED,
  DISCONTINUE,
  ADDEDITFAILED,
};
