import React from "react";
import { HeaderGroup } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/pro-regular-svg-icons";

import Defaulter from "../../types/Defaulter";
import Clinic from "../../types/Clinic";
import Vaccine from "../../types/Vaccine";
import Subscriber from "../../types/Subscriber";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const TableHeaderCell: React.FC<{
  header: HeaderGroup<Clinic> | HeaderGroup<Defaulter> | HeaderGroup<Vaccine> | HeaderGroup<Subscriber>;
}> = ({ header }) => {
  const { t } = useTranslation();
  return (
    <>
      <h3 className="text-sm font-bold text-black41">
        {header.render("header")}
      </h3>
      {header.render("header") !== t("missed_vaccines") &&
        <div className={` flex flex-col-reverse ${i18next.language === "ar" ? "mr-4" : "ml-4"} `}>
          <FontAwesomeIcon
            className={`text-sm ${
              header.isSorted && header.isSortedDesc
                ? "text-black41"
                : "text-gray-df"
            }`}
            icon={faAngleDown}
          />
          <FontAwesomeIcon
            className={`text-sm rotate-180 ${
              header.isSorted && !header.isSortedDesc
                ? "text-black41"
                : "text-gray-df"
            }`}
            icon={faAngleDown}
          />
        </div>
      }
    </>
  );
};

export default TableHeaderCell;
