import i18next from "i18next";

const MainFooter = () => {
    return (
        <h3 dir="ltr" className={`text-sm text-gray-77 mt-4 flex items-center 888:h-[7%] ${i18next.language === "ar" ? "justify-start" : "justify-end"}`}>
            2024&copy; <b>(CIMA)</b>
        </h3>
    );
};

export default MainFooter;
