import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/pro-light-svg-icons";
import { faShare } from "@fortawesome/pro-solid-svg-icons";

const ExportStaffIcon = () => {
  return (
    <div className="relative w-4 h-4 grid place-items-center mx-[9px]">
      <FontAwesomeIcon icon={faUser} />
      <FontAwesomeIcon
        className="absolute text-[10px] -right-[2px] -bottom-[4px]"
        icon={faShare}
      />
    </div>
  );
};

export default ExportStaffIcon;
