import React, { useMemo, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useTable, useSortBy, Column, usePagination } from "react-table";
import Clinic from "../../../types/Clinic";
import { SelectedClinicsContext } from "../../../store/SelectedClinicsContext";
import { SearchFilterClinicsContext } from "../../../store/SearchFilterClinicsContext";
import TableHeader from "../../UI/TableHeader";
import ClinicRow from "./ClinicRow";
import Pagination from "../../UI/Pagination";
import { tableStringSort } from "../../../utils/tableStringSort";

const ClinicTable: React.FC<{
  clinics: Clinic[];
  onExportCompleted: () => void;
  onClinicEdited: () => void;
}> = ({ clinics, onExportCompleted, onClinicEdited }) => {
  const { t } = useTranslation();

  const { selectedClinics, selectAll, deselectAll } = useContext(
    SelectedClinicsContext
  );
  const { tableAutoReset } = useContext(SearchFilterClinicsContext);

  const columns: Column<Clinic>[] = useMemo(
    () => [
      {
        header: t("title"),
        accessor: "title",
        sortType: tableStringSort,
      },
      {
        header: t("status"),
        accessor: "status",
      },
      {
        header: t("phone_number"),
        accessor: "phoneNumber",
      },
      {
        header: t("address"),
        accessor: "address",
        sortType: tableStringSort,
      },
      {
        header: t("areas"),
        accessor: "areas",
      },
      {
        header: t("number_of_staff"),
        accessor: "staffCount",
      },
      {
        header: t("number_of_registered_children"),
        accessor: "patientCount",
      },
    ],
    [t]
  );
  const data = useMemo(() => clinics, [clinics]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    pageCount,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    gotoPage,
    setPageSize,
    state,
  } = useTable(
    {
      columns,
      data,
      autoResetPage: tableAutoReset,
    },
    useSortBy,
    usePagination
  );

  const { pageIndex, pageSize } = state;

  const visibleClinics = page.map((row) => row.original.slug);
  let allChecked = true;
  for (let visibleClinic of visibleClinics) {
    if (selectedClinics.indexOf(visibleClinic) === -1) {
      allChecked = false;
      break;
    }
  }
  if (selectedClinics.length === 0) {
    allChecked = false;
  }

  return (
    <div className="relative">
      <div
        id="clinic-table__container"
        className="overflow-x-auto overflow-y-hidden scroll-firefox"
      >
        <table {...getTableProps()} className="w-full border-collapse">
          <TableHeader
            headerGroups={headerGroups}
            allChecked={allChecked}
            selectAll={() => selectAll(page.map((row) => row.original.slug))}
            deselectAll={() =>
              deselectAll(page.map((row) => row.original.slug))
            }
            className="pl-0"
          />
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <ClinicRow
                  key={row.id}
                  row={row}
                  onExportCompleted={onExportCompleted}
                  onClinicEdited={onClinicEdited}
                />
              );
            })}
          </tbody>
        </table>
      </div>
      <Pagination
        dropdownKeys={["5", "10", "20"]}
        previousPage={previousPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
        gotoPage={gotoPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageLength={page.length}
        totalLength={clinics.length}
      />
    </div>
  );
};

export default ClinicTable;
