import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import MenuItem from "./MenuItem";

export type MenuOption = {
  name: string;
  location: string;
};

const DropdownMenu: React.FC<{
  title: string;
  options: MenuOption[];
  rounded?: boolean;
  secondary?: boolean;
}> = ({ title, options, rounded, secondary }) => {
  const [showMenu, setShowMenu] = useState(false);
  const history = useHistory();

  return (
    <div className="relative mx-1.5" tabIndex={0} onBlur={() => setShowMenu(false)}>
      <p
        className={`px-4 py-2 capitalize cursor-pointer ${
          secondary
            ? "text-blue-primary hover:bg-blue-e1"
            : "text-white hover:bg-white51"
        } ${rounded && "rounded"} ${showMenu && "bg-white51"}`}
        onClick={() => setShowMenu((preShowMenu) => !preShowMenu)}
      >
        {title}
      </p>
      {showMenu && (
        <ul className="absolute top-11 rounded shadow bg-white py-1 w-48">
          {options.map((option, index) => (
            <MenuItem
              key={index}
              className="px-4"
              titleKey={option.name}
              onClick={() => {
                setShowMenu(false);
                history.push(option.location);
              }}
            />
          ))}
        </ul>
      )}
    </div>
  );
};

export default DropdownMenu;
