import React, { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { saveAs } from "file-saver";

import { SelectedClinicsContext } from "../../../store/SelectedClinicsContext";
import ClinicsAPI from "../../../store/api/clinicsAPI";
import MenuItem from "../../UI/MenuItem";
import ExportPatientsIcon from "../../UI/ExportPatientsIcon";
import ExportStaffIcon from "../../UI/ExportStaffIcon";
import i18next from "i18next";

const SelectedClinicsExport: React.FC<{
	onExportCompleted: () => void;
}> = ({ onExportCompleted }) => {
	const { selectedClinics, clearSelectedClinics } = useContext(SelectedClinicsContext);

	const [showMenu, setShowMenu] = useState(false);
	const [showHint, setShowHint] = useState(false);

	const { t } = useTranslation();

	const { exportClinics } = ClinicsAPI();

	const onExportClicked = (isPatient: boolean) => {
		exportClinics(
			isPatient,
			selectedClinics.map((selectedClinic) => ({ slug: selectedClinic })),
			async (response) => {
				if (response.ok) {
					try {
						const blob = await response.blob();
						saveAs(blob, `${isPatient ? "patients" : "staff"}.csv`);
						onExportCompleted();
						clearSelectedClinics();
					} catch (e) {
						console.log(e);
					}
				}
			}
		);
	};

	if (selectedClinics.length === 0) {
		return null;
	}

	return (
		<section className="pt-[14px] pb-5 mb-2 bg-gray-f5">
			<header className="w-95/100 mx-auto flex items-center" >
				<p className="text-sm text-green-primary">
					{selectedClinics.length === 1
						? t("selected_record")
						: t("selected_records", {
								selectedRecordsNum: selectedClinics.length,
						  })}
				</p>
				<div className="relative mx-2" tabIndex={0} onBlur={() => setShowMenu(false)}>
					<div
						className="text-blue-primary cursor-pointer w-10 h-10 grid place-items-center rounded bg-[#8888882D] text-[20px]"
						onClick={() => setShowMenu(true)}
						onMouseOver={() => setShowHint(true)}
						onMouseLeave={() => setShowHint(false)}>
						<FontAwesomeIcon icon={faShare} />
					</div>
					{showHint && (
						<div className={`absolute ${i18next.language === "ar" ? "-left-[136px]": "left-12"} top-[6px] w-32 h-7 grid place-items-center bg-white rounded shadow-lg`}>
                            <div className={`w-2 h-2 bg-white absolute ${i18next.language === "ar" ? "left-[124px]": "-left-[4px]"} top-[10px] rotate-45 `} />
							<p className="capitalize text-black41 text-sm ">{t("export")}</p>
						</div>
					)}
					{showMenu && (
						<ul className={`absolute top-11 ${i18next.language === "ar" ? "right-0": "-right-12"} 480:left-0 rounded shadow bg-white py-1 w-[220px] z-20`}>
							<MenuItem
								titleKey={t("export_patients")}
								onClick={() => {
									setShowMenu(false);
									onExportClicked(true);
								}}>
								<ExportPatientsIcon />
							</MenuItem>
							<MenuItem
								titleKey={t("export_staff")}
								onClick={() => {
									setShowMenu(false);
									onExportClicked(false);
								}}>
								<ExportStaffIcon />
							</MenuItem>
						</ul>
					)}
				</div>
			</header>
		</section>
	);
};

export default SelectedClinicsExport;
