import React from "react";
const VaccineStatusBox: React.FC<{
  statusName: string;
  status: boolean;
  className?: string;
}> = ({ statusName, status, className }) => {
  return (
    <div>
      {status && (
        <div className={`h-[38px] ${className} px-2.5 py-2 rounded`}>
          {statusName}
        </div>
      )}
    </div>
  );
};

export default VaccineStatusBox;
