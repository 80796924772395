import React, { PropsWithChildren, useState } from "react";
import { Vaccine } from "../components/main/setting/vaccine/VaccineRow";

type VaccineContextType = {
  responseData: Vaccine[];
  setResponseData: (vaccines: Vaccine[]) => void;
  deleteloading: boolean;
  setDeleteloading: (status: boolean) => void;
  addLoading: boolean;
  setAddLoading: (status: boolean) => void;
  editLoading: boolean;
  setEditLoading: (status: boolean) => void;
  setDiscountinueLoading: (status: boolean) => void;
  discountinueLoading: boolean;
};

export const VaccineContext = React.createContext<VaccineContextType>({
  responseData: [],
  setResponseData: () => {
    //
  },
  deleteloading: false,
  setDeleteloading: () => {
    //
  },

  addLoading: false,
  setAddLoading: () => {
    //
  },
  editLoading: false,
  setEditLoading: () => {
    //
  },
  setDiscountinueLoading: () => {
    //
  },
  discountinueLoading: false,
});

const VaccineContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [responseData, setResponseData] = useState<Array<Vaccine>>([]);
  const [deleteloading, setDeleteloading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [discountinueLoading, setDiscountinueLoading] = useState(false);

  return (
    <VaccineContext.Provider
      value={{
        responseData,
        setResponseData,
        deleteloading,
        setDeleteloading,
        addLoading,
        setAddLoading,
        editLoading,
        setEditLoading,
        setDiscountinueLoading,
        discountinueLoading,
      }}
    >
      {children}
    </VaccineContext.Provider>
  );
};

export default VaccineContextProvider;
