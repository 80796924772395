import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TextAreaInput from "../../../UI/TextAreaInput";
import { AddOrEditModal } from "../../../../utils/Constants";
import CancelAndSaveButton from "../../../UI/CancelAndSaveButton";
import { HealthMessageContext } from "../../../../store/HealthMessageContext";
import LanguageBox from "../../../UI/LanguageBox";
import i18next from "i18next";

const AddOrEditHealthMessage: React.FC<{
  className: string;
  typeOfModal: AddOrEditModal;
  editMessageEn?: string;
  editMessageFr?: string;
  editMessageAr?: string;
  editMessageRw?: string;
  onCancel: () => void;
  id?: number;
  addHealthMessageHandler?: (
    enMessage: string,
    editMessageFr?: string,
    editMessageAr?: string,
    editMessageRw?: string
  ) => boolean;
  editHealthMessageHandler?: (
    id: number,
    enMessage: string,
    frMessage: string,
    arMessage: string,
    rwMessage: string
  ) => void;
}> = ({
  className,
  onCancel,
  typeOfModal,
  editMessageEn,
  editMessageFr,
  editMessageAr,
  editMessageRw,
  id,
  addHealthMessageHandler,
  editHealthMessageHandler,
}) => {
  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const [englishText, setEnglishText] = useState("");
  const [frenchText, setFrenchText] = useState("");
  const [arabicText, setArabicText] = useState("");
  const [kinyarwandaText, setKinyarwandaText] = useState("");
  const [disableSaveBtn, setDisableSaveBtn] = useState(true);
  const [inputChanged, setInputChanged] = useState(true);
  const { t } = useTranslation();
  const { loading, setLoading } = useContext(HealthMessageContext);

  const [selectedLanguage, setSelectedLanguage] = useState("en");

  const cancelBtn = () => {
    setEnglishText("");
    setFrenchText("");
    setArabicText("");
    setKinyarwandaText("");
    setInputChanged(true);
    setSelectedLanguage("en");
    onCancel();
  };
  useEffect(() => {
    if (process.env.REACT_APP_Country === "Cameroon") {
      if (englishText === "" || frenchText === "") {
        setDisableSaveBtn(true);
      } else {
        setDisableSaveBtn(false);
      }
    } else if (process.env.REACT_APP_Country === "Lebanon") {
      if (englishText === "" || frenchText === "" || arabicText === "") {
        setDisableSaveBtn(true);
      } else {
        setDisableSaveBtn(false);
      }
    } else if (process.env.REACT_APP_Country === "Rwanda") {
      if (englishText === "" || kinyarwandaText === "") {
        setDisableSaveBtn(true);
      } else {
        setDisableSaveBtn(false);
      }
    }

    editMessageEn && inputChanged && setEnglishText(editMessageEn);
    editMessageFr && inputChanged && setFrenchText(editMessageFr);
    editMessageAr && inputChanged && setArabicText(editMessageAr);
    editMessageRw && inputChanged && setKinyarwandaText(editMessageRw);
  }, [
    englishText,
    frenchText,
    arabicText,
    kinyarwandaText,
    editMessageEn,
    editMessageFr,
    editMessageAr,
    editMessageRw,
  ]);

  useEffect(() => {
    if (!loading) {
      setEnglishText("");
      setFrenchText("");
      setArabicText("");
      setKinyarwandaText("");
      setSelectedLanguage("en");
      onCancel();
    }
  }, [loading]);

  const saveBtn = (__type: AddOrEditModal) => {
    switch (__type) {
      case 0:
        setLoading(true);
        addHealthMessageHandler!(
          englishText,
          frenchText,
          arabicText,
          kinyarwandaText
        );
        break;
      case 1:
        setInputChanged(true);
        editHealthMessageHandler!(
          id!,
          englishText,
          frenchText,
          arabicText,
          kinyarwandaText
        );
        loading && onCancel();
    }
  };

  return (
    <>
      <div onClick={onCancel} className={`backdrop ${className}`}>
        <div
          onClick={(e) => e.stopPropagation()}
          className="sm:w-[492px] sm:h-[394px] bg-white cursor-auto w-8/12 h-auto rounded"
        >
          <form onSubmit={(e) => submitForm(e)} className="mx-6">
            <div
              className="flex flex-wrap relative justify-between mt-[21px]"
              dir={i18next.language === "ar" ? "rtl" : "ltr"}
            >
              <div className="text-lg">
                <div className="font-semibold">
                  {typeOfModal === AddOrEditModal.ADD
                    ? t("add_health_message")
                    : t("edit_health_message")}
                </div>
                <div className="text-[#414141] text-sm max-w-[240px] my-[8px]">
                  {t("fill_fields")}
                </div>
              </div>
              <div>
                <LanguageBox
                  onChange={(lng) => setSelectedLanguage(lng)}
                  selectedLanguage={selectedLanguage}
                />
              </div>
            </div>

            {selectedLanguage === "en" && (
              <TextAreaInput
                title={t("message")}
                placeholder={t("message") + " (EN)"}
                onChange={(e) => {
                  setEnglishText(e.target.value);
                  setInputChanged(false);
                }}
                value={englishText}
                isRequired= {true}
                hasFlag= {true}
                showNumberOfChars= {true}
              />
            )}

            {selectedLanguage === "fr" && (
              <TextAreaInput
                title={t("message")}
                placeholder={t("message") + " (FR)"}
                onChange={(e) => {
                  setFrenchText(e.target.value);
                  setInputChanged(false);
                }}
                value={frenchText}
                isRequired= {true}
                hasFlag= {true}
                showNumberOfChars= {true}
              />
            )}

            {selectedLanguage === "rw" && (
              <TextAreaInput
                title={t("message")}
                placeholder={t("message") + " (RW)"}
                onChange={(e) => {
                  setKinyarwandaText(e.target.value);
                  setInputChanged(false);
                }}
                value={kinyarwandaText}
                isRequired= {true}
                hasFlag= {true}
                showNumberOfChars= {true}
              />
            )}

            {process.env.REACT_APP_Country === "Lebanon" &&
              selectedLanguage === "ar" && (
                <TextAreaInput
                  title={t("message")}
                  placeholder={t("message") + " (AR)"}
                  onChange={(e) => {
                    setArabicText(e.target.value);
                    setInputChanged(false);
                  }}
                  value={arabicText}
                  isRequired= {true}
                  hasFlag= {true}
                  showNumberOfChars= {true}
                  isRTL={true}
                />
              )}

            <CancelAndSaveButton
              cancelBtn={cancelBtn}
              disableSaveBtn={disableSaveBtn}
              saveBtn={saveBtn}
              type={typeOfModal}
              loading={loading}
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default AddOrEditHealthMessage;
