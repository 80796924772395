import React, { useContext, useEffect } from "react";
import MainContainer from "../../UI/MainContainer";
import { useTranslation } from "react-i18next";
import MainFooter from "../../UI/MainFooter";
import HeaderLink from "./HeaderLink";
import { ProfileContext } from "../../../store/ProfileContext";
import { AuthContext } from "../../../store/AuthContext";
import Button from "../../UI/Button";
import { ButtonTypes } from "../../../utils/Constants";
import resetprofile from "../../../images/resetPass.svg";
import i18next from "i18next";

const AccountSetting: React.FC<{
  resetBtnHandler: () => void;
}> = ({ resetBtnHandler }) => {
  const { t } = useTranslation();
  const { profileOrAccountSettingHandler } = useContext(ProfileContext);
  const { authData } = useContext(AuthContext);

  useEffect(() => {
    profileOrAccountSettingHandler(false);
  });
  return (
    <MainContainer>
      <div className="alignment">
        <div className="text-[18px] capitalize">{t("account_setting")}</div>
        <div className="rounded shadow bg-white mt-[26px] relative">
          <HeaderLink />
          <div className="w-[97%] mx-auto mt-[30px] text-base text-[#414141] pb-[440px] ">
            {t("username")}
            <div className="mt-2 text-sm">{authData.email}</div>
            <div className="mt-[30px]">
              <Button
                children={t("change_password")}
                onClick={resetBtnHandler}
                type={ButtonTypes.PRIMARY}
              />
            </div>
          </div>
          <div className={`absolute w-[552px] bottom-0 ${i18next.language === "ar" ? "left-0" : "right-0"} invisible sm:visible`}>
            <img src={resetprofile} alt={t("background")} style={i18next.language === "ar" ? { transform: "scaleX(-1)" } : {}} />
          </div>
        </div>

        <MainFooter />
      </div>
    </MainContainer>
  );
};

export default AccountSetting;
