import { useContext } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";

import { AuthContext } from "../../store/AuthContext";
import Button from "./Button";
import { ButtonTypes } from "../../utils/Constants";
import placeholder from "../../images/user-placeholder.svg";
import i18next from "i18next";

const HeaderProfile: React.FC<{
  showProfileMenu: boolean;
  setShowProfileMenu: React.Dispatch<React.SetStateAction<boolean>>;
  onLogoutButtonClicked: () => void;
}> = (props) => {
  const { authData } = useContext(AuthContext);

  const { t } = useTranslation();

  const { showProfileMenu, setShowProfileMenu, onLogoutButtonClicked } = props;

  return (
    <div className="w-12 h-12 rounded relative">
      <img
        onClick={() => setShowProfileMenu((preValue) => !preValue)}
        className="w-full h-full object-cover rounded cursor-pointer"
        src={!authData.avatar ? placeholder : authData.avatar}
        alt={t("profile_image")}
        data-testid="profile-image"
      />
      {showProfileMenu && (
        <section
          className={`bg-white w-60 p-6 absolute ${i18next.language === "ar" ? "left-0" : "right-0"} top-14 shadow-md rounded"
          data-testid="profile-popup`}
        >
          <div className="relative">
            <FontAwesomeIcon
              data-testid="profile-popup__close"
              onClick={() => setShowProfileMenu(false)}
              icon={faTimes}
              className={`absolute top-0 ${i18next.language === "ar" ? "left-0" : "right-0"} text-black41 cursor-pointer`}
            />
            <h2 className="capitalize text-black27 text-lg font-semibold">
              {t("profile")}
            </h2>
            <h3 className="capitalize text-black41 text-sm font-semibold mt-5">
              {authData.firstName} {authData.lastName}
            </h3>
            <p className="text-black41 text-sm mt-2 mb-4">{authData.email}</p>
            <Link className="link" to="/account-profile" onClick={() => {setShowProfileMenu(false)}}>
              {t("account")}
            </Link>
            <br />
            <Button
              type={ButtonTypes.SECONDARY}
              onClick={() => {
                setShowProfileMenu(false);
                onLogoutButtonClicked();
              }}
              className="mt-6"
            >
              {t("logout")}
            </Button>
          </div>
        </section>
      )}
    </div>
  );
};

export default HeaderProfile;
