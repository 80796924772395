import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { SearchFilterClinicsContext } from "../../../store/SearchFilterClinicsContext";
import Dropdown, { DropdownType } from "../../UI/Dropdown";
import FromToInputContainer from "../../UI/FromToInputContainer";
import NumberInput from "../../UI/NumberInput";
import FilterActions from "../../UI/FilterActions";
import FilterTag from "../../UI/FilterTag";
import i18next from "i18next";

const ClinicFilterSection: React.FC<{
  onApply: () => void;
}> = ({ onApply }) => {
  const { t } = useTranslation();

  const {
    clinicFilter,
    setClinicFilter,
    clinicAppliedFilter,
    setClinicAppliedFilter,
    reset,
    isAnyFilterApplied,
  } = useContext(SearchFilterClinicsContext);

  let staffCountFilterTagValue = "";
  if (clinicAppliedFilter.minStaffCount && clinicAppliedFilter.maxStaffCount) {
    staffCountFilterTagValue = `${clinicAppliedFilter.minStaffCount}-${clinicAppliedFilter.maxStaffCount}`;
  } else if (
    clinicAppliedFilter.minStaffCount &&
    !clinicAppliedFilter.maxStaffCount
  ) {
    staffCountFilterTagValue = `${t("min")} (${clinicAppliedFilter.minStaffCount})`;
  } else if (
    !clinicAppliedFilter.minStaffCount &&
    clinicAppliedFilter.maxStaffCount
  ) {
    staffCountFilterTagValue = `${t("max")} (${clinicAppliedFilter.maxStaffCount})`;
  }

  let childPatientsNumFilterTagValue = "";
  if (
    clinicAppliedFilter.minPatientCount &&
    clinicAppliedFilter.maxPatientCount
  ) {
    childPatientsNumFilterTagValue = `${clinicAppliedFilter.minPatientCount}-${clinicAppliedFilter.maxPatientCount}`;
  } else if (
    clinicAppliedFilter.minPatientCount &&
    !clinicAppliedFilter.maxPatientCount
  ) {
    childPatientsNumFilterTagValue = `${t("min")} (${clinicAppliedFilter.minPatientCount})`;
  } else if (
    !clinicAppliedFilter.minPatientCount &&
    clinicAppliedFilter.maxPatientCount
  ) {
    childPatientsNumFilterTagValue = `${t("max")} (${clinicAppliedFilter.maxPatientCount})`;
  }

  return (
    <div>
      <section className="w-95/100 mx-auto xl:flex justify-between items-center pt-1 pb-6">
        <div className="flex flex-col 960:flex-row">
          <div>
            <label className="text-sm text-black41">{t("status")}</label>
            <Dropdown
              type={DropdownType.PRIMARY}
              onOptionSelected={(option) => {
                setClinicFilter((preClinicFilter) => {
                  return { ...preClinicFilter, status: option };
                });
              }}
              keys={["AL", "AC", "IC"]}
              selectedKey={clinicFilter.status}
            />
          </div>
          <div className="flex flex-col mt-3 960:mt-0 600:flex-row-reverse justify-end 960:justify-between">
            <FromToInputContainer titleKey="number_of_staff" className="600:mx-6 960:mx-0">
              <NumberInput
                className="w-[120px] 960:ml-0 placeholder:text-gray-d7"
                value={clinicFilter.minStaffCount}
                placeholder={t("from")}
                onNumberInputChanged={(value) =>
                  setClinicFilter((preClinicFilter) => {
                    return { ...preClinicFilter, minStaffCount: value };
                  })
                }
                max={clinicFilter.maxStaffCount}
              />
              <NumberInput
                className="w-[120px] mx-3 placeholder:text-gray-d7"
                value={clinicFilter.maxStaffCount}
                placeholder={t("to")}
                onNumberInputChanged={(value) =>
                  setClinicFilter((preClinicFilter) => {
                    return { ...preClinicFilter, maxStaffCount: value };
                  })
                }
                min={clinicFilter.minStaffCount}
              />
            </FromToInputContainer>
            <FromToInputContainer
              titleKey="number_of_registered_children"
              className="mt-3 600:mt-0 960:mx-6"
            >
              <NumberInput
                className="w-[120px] placeholder:text-gray-d7"
                value={clinicFilter.minPatientCount}
                placeholder={t("from")}
                onNumberInputChanged={(value) =>
                  setClinicFilter((preClinicFilter) => {
                    return { ...preClinicFilter, minPatientCount: value };
                  })
                }
                max={clinicFilter.maxPatientCount}
              />
              <NumberInput
                className={`w-[120px] ${i18next.language === "ar" ? "mr-3" : "ml-3"} placeholder:text-gray-d7`}
                value={clinicFilter.maxPatientCount}
                placeholder={t("to")}
                onNumberInputChanged={(value) =>
                  setClinicFilter((preClinicFilter) => {
                    return { ...preClinicFilter, maxPatientCount: value };
                  })
                }
                min={clinicFilter.minPatientCount}
              />
            </FromToInputContainer>
          </div>
        </div>
        <FilterActions
          isAnyFilterApplied={isAnyFilterApplied}
          reset={reset}
          onApply={onApply}
        />
      </section>
      {isAnyFilterApplied() && (
        <section className="w-95/100 mx-auto flex flex-wrap items-center py-4">
          <p className="capitalize mt-1 text-sm text-black41">
            {t("applied_filter")}:
          </p>
          {clinicAppliedFilter.status !== "AL" && (
            <FilterTag
              label={t("status")}
              value={t(clinicAppliedFilter.status.toLocaleLowerCase())}
              onClose={() => {
                setClinicAppliedFilter((preClinicAppliedFilter) => {
                  return { ...preClinicAppliedFilter, status: "AL" };
                });
              }}
            />
          )}
          {staffCountFilterTagValue && (
            <FilterTag
              label={t("number_of_staff")}
              value={staffCountFilterTagValue}
              onClose={() => {
                setClinicAppliedFilter((preClinicAppliedFilter) => {
                  return {
                    ...preClinicAppliedFilter,
                    minStaffCount: "",
                    maxStaffCount: "",
                  };
                });
              }}
            />
          )}
          {childPatientsNumFilterTagValue && (
            <FilterTag
              label={t("number_of_registered_children")}
              value={childPatientsNumFilterTagValue}
              onClose={() => {
                setClinicAppliedFilter((preClinicAppliedFilter) => {
                  return {
                    ...preClinicAppliedFilter,
                    minPatientCount: "",
                    maxPatientCount: "",
                  };
                });
              }}
            />
          )}
        </section>
      )}
    </div>
  );
};

export default ClinicFilterSection;
