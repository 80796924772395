import React from "react";

const ToggleSwitch: React.FC<{
  className?: string;
  checked: boolean;
  onClick: () => void;
}> = ({ className, checked, onClick }) => {
  return (
    <div
      onClick={() => onClick()}
      className={`w-[34px] h-5 grid place-items-center relative ${className}`}
    >
      <div className="w-full rounded-full h-4 bg-gray-d7" />
      <div
        className={`w-5 h-full rounded-full absolute shadow-md cursor-pointer top-0 left-0 duration-500 ${
          checked ? "bg-blue-primary" : "bg-white"
        } ${checked && "translate-x-[14px]"}`}
      />
    </div>
  );
};

export default ToggleSwitch;
