import React, { useContext, useEffect, useState } from "react";
import Button from "../../../UI/Button";
import { ButtonTypes } from "../../../../utils/Constants";
import DatePicker from "../../../UI/DatePicker";
import Dropdown, { DropdownType } from "../../../UI/Dropdown";
import { useTranslation } from "react-i18next";
import DropdownWithCheckbox from "../../../UI/DropdownWithCheckbox";
import IdValueType from "../../../../types/IdValueType";
import { VaccineUtilizationContext } from "../../../../store/VaccineUtilizationContext";
import i18next from "i18next";

export type VaccineUtilizationFilterBarType = {
  vaccine: IdValueType[];
  dateFrom: Date | null;
  dateTo: Date | null;
  clinic: string;
};

const VaccineUtilizationFilterBar: React.FC<{
  applyHandler: (filter: VaccineUtilizationFilterBarType) => void;
  resetHandler: () => void;
  vaccineValue: IdValueType[];
  dateValue: { dateFrom: Date | null; dateTo: Date | null };
  clinicValue: string;
}> = ({ applyHandler, resetHandler, vaccineValue, dateValue, clinicValue }) => {
  const { t } = useTranslation();
  const [selectedVaccines, setSelectedVaccines] = useState<IdValueType[]>([]);
  const [date, setDate] = useState<{
    dateFrom: Date | null;
    dateTo: Date | null;
  }>({ dateFrom: null, dateTo: null });
  const [selectedclinic, setSelectedClinic] = useState<string>("");
  const [applyIsClicked, setApplyIsClicked] = useState(false);
  const { vaccines, clinics } = useContext(VaccineUtilizationContext);

  useEffect(() => {
    setDate(dateValue);
  }, [dateValue]);

  useEffect(() => {
    setSelectedVaccines(vaccineValue);
  }, [vaccineValue]);

  useEffect(() => {
    setSelectedClinic(clinicValue);
  }, [clinicValue]);

  return (
    <div className="w-95/100 flex flex-wrap justify-between gap-x-6 mx-auto">
      <div className="flex flex-wrap gap-x-6 gap-y-1">
        <div>
          <label className="text-sm text-black41">{t("vaccine")}</label>
          <DropdownWithCheckbox
            type={DropdownType.PRIMARY}
            extraAvailableOptionsContainerClassName="top-12 h-[125px] overflow-auto"
            onOptionSelected={(option) => {
              setSelectedVaccines(option);
            }}
            keys={[
              { id: "", name: "", type: [] },
              ...vaccines.map((vaccine) => vaccine),
            ]}
            selectedKey={selectedVaccines}
            getValueByKey={(key) => {
              if (key === "") return t("all");
              return (
                vaccines.find((vaccine) => vaccine.name === key)?.name || ""
              );
            }}
          />
        </div>

        <div>
          <label className="text-sm text-black41">{t("interval_date")}</label>
          <div className="flex gap-x-3" dir={i18next.language === "ar" ? "rtl" : "ltr"}>
            <DatePicker
              inputClassName="w-[124px] placeholder:text-gray-d7"
              containerClassName="w-[124px]"
              selected={date.dateFrom}
              placeholderText={t("from")}
              onChange={(value) => {
                setDate({ ...date, dateFrom: value });
              }}
              maxDate={date.dateTo}
            />

            <DatePicker
              inputClassName="w-[124px] placeholder:text-gray-d7"
              containerClassName="w-[124px]"
              selected={date.dateTo}
              placeholderText={t("to")}
              onChange={(value) => {
                setDate({ ...date, dateTo: value });
              }}
              minDate={date.dateFrom}
            />
          </div>
        </div>
        
        {clinics &&
          <div>
            <label className="text-sm text-black41">{t("clinic")}</label>
            <Dropdown
              type={DropdownType.PRIMARY}
              extraAvailableOptionsContainerClassName="top-12 h-[125px] overflow-auto"
              onOptionSelected={(option) => {
                setSelectedClinic(option);
              }}
              keys={["", ...clinics.map((clinic) => clinic.slug)]}
              selectedKey={selectedclinic}
              getValueByKey={(key) => {
                if (key === "") return t("all");

                return clinics.find((clinic) => clinic.slug === key)!.name;
              }}
            />
          </div>
        }
      </div>
      <div className="flex flex-grow justify-end mt-6 gap-x-6">
        {applyIsClicked &&
          (selectedVaccines.length !== 0 ||
            date.dateFrom !== null ||
            date.dateTo !== null) && (
            <button
              onClick={() => {
                resetHandler();
                setSelectedVaccines([]);
                setDate({ dateFrom: null, dateTo: null });
                setApplyIsClicked(false);
              }}
              className="text-blue-primary hover:font-semibold text-sm my-auto"
            >
              {t("reset")}
            </button>
          )}

        <Button
          type={ButtonTypes.PRIMARY}
          onClick={() => {
            setApplyIsClicked(true);
            applyHandler({
              vaccine: selectedVaccines,
              dateFrom: date.dateFrom,
              dateTo: date.dateTo,
              clinic: selectedclinic,
            });
          }}
        >
          {t("apply")}
        </Button>
      </div>
    </div>
  );
};

export default VaccineUtilizationFilterBar;
