const captchaValidation = (action: string, callback: (token: string) => void, onError: () => void) => {
  try {
    (window as any).grecaptcha.ready(function () {
      (window as any).grecaptcha
        .execute("6LfPQ5IoAAAAAMZOWifm58sNLFN4egEf8KdXYIdQ", {
          action: action,
        })
        .then(function (token: string) {
          callback(token);
        });
    });
  } catch (error) {
    onError();
  }
};

export default captchaValidation;
