import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SearchFilterChildrenContext } from "../../../store/SearchFilterChildrenContext";
import FilterActions from "../../UI/FilterActions";
import FilterTag from "../../UI/FilterTag";
import DropdownWithCheckbox, { DropdownType, OptionType } from "../../UI/DropdownWithCheckbox";
import DropdownWithCheckboxAndIcon from "../../UI/DropdownWithCheckboxAndIcon";
import FilterTagWithIcon from "../../UI/FilterTagWithIcon";
import IdValueType from "../../../types/IdValueType";
import Dropdown from "../../UI/Dropdown";
import DatePicker from "../../UI/DatePicker";
import i18next from "i18next";
import { getFormattedDate } from "../../../utils/DateUtils";

const ChildrenFilterSection: React.FC<{
  onApply: () => void;
}> = ({ onApply }) => {
  const { t } = useTranslation();

  const {
    childrenFilter,
    setChildrenFilter,
    childrenAppliedFilter,
    setChildrenAppliedFilter,
    vaccines,
    vaccineStatuses,
    injectionStatuses,
    clinics,
    reset,
    isAnyFilterApplied,
  } = useContext(SearchFilterChildrenContext);

  const [isSecondDropdownDisabled, setIsSecondDropdownDisabled] = useState(false);

  useEffect(() => {
    if (childrenAppliedFilter.vaccines.length !== 0) {
      setIsSecondDropdownDisabled(!childrenAppliedFilter.vaccines?.some((item) => item.name === ""));
    }
    if (isSecondDropdownDisabled) {
      childrenFilter.vaccineStatuses = [];
    }
  }, [childrenAppliedFilter]);

  let contactDateFilterTagValue =
    childrenAppliedFilter.dateFrom && childrenAppliedFilter.dateTo
      ? `${getFormattedDate(childrenAppliedFilter.dateFrom)} - ${getFormattedDate(
        childrenAppliedFilter.dateTo
      )}`
      : childrenAppliedFilter.dateFrom
        ? `${t("from")} ${getFormattedDate(childrenAppliedFilter.dateFrom)}`
        : childrenAppliedFilter.dateTo
          ? `${t("to")} ${getFormattedDate(childrenAppliedFilter.dateTo)}`
          : "";

  return (
    <div>
      <section className="w-95/100 mx-auto 2xl:flex justify-between items-center pt-1 pb-6">
        <div className="flex flex-wrap gap-x-3 gap-y-2">
          <div>
            <label className="text-sm text-black41">{t("visible_vaccine_columns")}</label>
            <DropdownWithCheckbox
              type={DropdownType.PRIMARY}
              extraAvailableOptionsContainerClassName="top-12 h-[125px] overflow-auto"
              onOptionSelected={(option) => {
                const isIdValueType = (option: OptionType): option is IdValueType =>
                  (option as IdValueType).id !== undefined && (option as IdValueType).name !== '';
                const filteredOptions = option.filter(isIdValueType);
                setIsSecondDropdownDisabled(filteredOptions.length !== 0);
                setChildrenFilter((prevChildrenFilter) => ({
                  ...prevChildrenFilter,
                  vaccines: filteredOptions,
                }));
              }}
              keys={[{ id: "", name: "", type: [] }, ...vaccines.map((vaccine) => vaccine)]}
              selectedKey={childrenFilter.vaccines!}
              getValueByKey={(key) => {
                if (key === "") return t("all");
                return vaccines.find((vaccine) => vaccine.name === key)?.name || '';
              }}
            />
          </div>
          <div>
            <label className="text-sm text-black41">{t("vaccine_statuses")}</label>
            <DropdownWithCheckboxAndIcon
              type={DropdownType.PRIMARY}
              isDisabled={isSecondDropdownDisabled}
              extraAvailableOptionsContainerClassName="top-12 h-[125px] overflow-auto"
              onOptionSelected={(option) => {
                setChildrenFilter((preChildrenFilter) => {
                  return { ...preChildrenFilter, vaccineStatuses: option.filter((_option) => _option.name) };
                });
              }}
              keys={[{ key: "", name: "", icon: "", color: "" }, ...vaccineStatuses.map((status) => status)]}
              selectedKey={isSecondDropdownDisabled ? [] : childrenFilter.vaccineStatuses!}
              getValueByKey={(key) => {
                if (key === "") return t("all");
                return vaccineStatuses.find((status) => status.name === key)!.name;
              }}
            />
          </div>
          <div>
            <label className="text-sm text-black41">{t("injection_statuses")}</label>
            <DropdownWithCheckboxAndIcon
              type={DropdownType.PRIMARY}
              isDisabled={false}
              extraAvailableOptionsContainerClassName="top-12 h-[125px] overflow-auto"
              onOptionSelected={(option) => {
                setChildrenFilter((preChildrenFilter) => {
                  return { ...preChildrenFilter, injectionStatuses: option.filter((_option) => _option.name) };
                });
              }}
              keys={[{ key: "", name: "", icon: "", color: "" }, ...injectionStatuses.map((status) => status)]}
              selectedKey={childrenFilter.injectionStatuses!}
              getValueByKey={(key) => {
                if (key === "") return t("all");
                return injectionStatuses.find((status) => status.name === key)!.name;
              }}
            />
          </div>

          {clinics &&
            <div>
              <label className="text-sm text-black41">{t("clinic")}</label>
              <Dropdown
                type={DropdownType.PRIMARY}
                extraAvailableOptionsContainerClassName="top-12 h-[125px] overflow-auto"
                onOptionSelected={(option) => {
                  setChildrenFilter((prevChildrenFilter) => ({
                    ...prevChildrenFilter,
                    clinic: option,
                  }));
                }}
                keys={["", ...clinics.map((clinic) => clinic.slug)]}
                selectedKey={childrenFilter.clinic}
                getValueByKey={(key) => {
                  if (key === "") return t("all");
                  return clinics.find((clinic) => clinic.slug === key)!.name;
                }}
              />
            </div>
          }

          <div>
            <label className="text-sm text-black41">{t("interval_date")}</label>
            <div className="flex gap-x-3" dir={i18next.language === "ar" ? "rtl" : "ltr"}>
              <DatePicker
                inputClassName="w-[124px] placeholder:text-gray-d7"
                containerClassName="w-[124px]"
                selected={childrenFilter.dateFrom}
                placeholderText={t("from")}
                onChange={(value) => {
                  setChildrenFilter((prevChildrenFilter) => ({
                    ...prevChildrenFilter,
                    dateFrom: value
                  }));
                }}
                maxDate={childrenFilter.dateTo}
              />

              <DatePicker
                inputClassName="w-[124px] placeholder:text-gray-d7"
                containerClassName="w-[124px]"
                selected={childrenFilter.dateTo}
                placeholderText={t("to")}
                onChange={(value) => {
                  setChildrenFilter((prevChildrenFilter) => ({
                    ...prevChildrenFilter,
                    dateTo: value
                  }));
                }}
                minDate={childrenFilter.dateFrom}
              />
            </div>
          </div>
        </div>
        <FilterActions
          isAnyFilterApplied={isAnyFilterApplied}
          reset={() => {
            reset();
            setIsSecondDropdownDisabled(false);
          }}
          onApply={onApply}
        />
      </section>

      {isAnyFilterApplied() &&
        ((childrenAppliedFilter.vaccines?.length !== 0 && !childrenAppliedFilter.vaccines.some((item) => item.name === "")) ||
          (childrenAppliedFilter.vaccineStatuses?.length !== 0 &&
            !childrenAppliedFilter.vaccineStatuses.some((item) => item.name === "")) ||
          (childrenAppliedFilter.injectionStatuses?.length !== 0 &&
            !childrenAppliedFilter.injectionStatuses.some((item) => item.name === "")) || childrenAppliedFilter.clinic !== "" || childrenAppliedFilter.dateFrom !== null || childrenAppliedFilter.dateTo !== null) && (
          <section className="w-95/100 mx-auto py-4 flex flex-wrap items-center">
            <p className="capitalize mt-1 text-sm text-black41">{t("applied_filter")}:</p>
            {childrenAppliedFilter.vaccines?.length !== 0 && !childrenAppliedFilter.vaccines.some((item) => item.name === "") && (
              <FilterTag
                label={t("visible_vaccine_columns")}
                value={childrenAppliedFilter.vaccines!.map((key) => key.name).join(", ")}
                onClose={() => {
                  setChildrenAppliedFilter((preChildrenAppliedFilter) => {
                    setIsSecondDropdownDisabled(false);
                    return {
                      ...preChildrenAppliedFilter,
                      vaccines: [],
                    };
                  });
                  setChildrenFilter((preChildrenFilter) => {
                    return { ...preChildrenFilter, vaccines: [] };
                  });
                }}
              />
            )}
            {!isSecondDropdownDisabled &&
              childrenAppliedFilter.vaccineStatuses?.length !== 0 &&
              !childrenAppliedFilter.vaccineStatuses.some((item) => item.name === "") && (
                <FilterTagWithIcon
                  label={t("vaccine_statuses")}
                  value={childrenAppliedFilter.vaccineStatuses!}
                  onClose={() => {
                    setChildrenAppliedFilter((preChildrenAppliedFilter) => {
                      return {
                        ...preChildrenAppliedFilter,
                        vaccineStatuses: [],
                      };
                    });
                    setChildrenFilter((preChildrenFilter) => {
                      return { ...preChildrenFilter, vaccineStatuses: [] };
                    });
                  }}
                />
              )}
            {childrenAppliedFilter.injectionStatuses?.length !== 0 &&
              !childrenAppliedFilter.injectionStatuses.some((item) => item.name === "") && (
                <FilterTagWithIcon
                  label={t("injection_statuses")}
                  value={childrenAppliedFilter.injectionStatuses!}
                  onClose={() => {
                    setChildrenAppliedFilter((preChildrenAppliedFilter) => {
                      return {
                        ...preChildrenAppliedFilter,
                        injectionStatuses: [],
                      };
                    });
                    setChildrenFilter((preChildrenFilter) => {
                      return { ...preChildrenFilter, injectionStatuses: [] };
                    });
                  }}
                />
              )}
            {childrenAppliedFilter.clinic !== "" && (
              <FilterTag
                label={t("clinic")}
                value={clinics.find((clinic) => clinic.slug === childrenAppliedFilter.clinic)?.name!}
                onClose={() => {
                  setChildrenAppliedFilter((preChildrenAppliedFilter) => {
                    return {
                      ...preChildrenAppliedFilter,
                      clinic: "",
                    };
                  });
                  setChildrenFilter((preChildrenFilter) => {
                    return { ...preChildrenFilter, clinic: "" };
                  });
                }}
              />
            )}
            {(childrenAppliedFilter.dateFrom !== null || childrenAppliedFilter.dateTo !== null) && (
              <FilterTag
                label={t("interval_date")}
                value={contactDateFilterTagValue}
                onClose={() => {
                  setChildrenAppliedFilter((preChildrenAppliedFilter) => {
                    return {
                      ...preChildrenAppliedFilter,
                      dateFrom: null,
                      dateTo: null
                    };
                  });
                  setChildrenFilter((preChildrenFilter) => {
                    return { ...preChildrenFilter, dateFrom: null, dateTo: null };
                  });
                }}
              />
            )}
          </section>
        )}
    </div>
  );
};

export default ChildrenFilterSection;
