import React, { useContext, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MenuItem from "../../../UI/MenuItem";
import { faPen, faTrash, faBan } from "@fortawesome/pro-light-svg-icons";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons";
import { faGripVertical } from "@fortawesome/pro-solid-svg-icons";
import LastConfirmWarningModal from "../../../UI/LastConfirmWarningModal";
import { DraggableProvided } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { VaccineContext } from "../../../../store/VaccineContext";
import i18next from "i18next";
import VaccineStatusBox from "../../../UI/VaccineStatusBox";

export type Vaccine = {
    id: number;
    name: { en: string; fr: string; ar: string, rw: string };
    is_discontinue: boolean;
    is_optional: boolean;
    is_pre_birth: boolean;
    dose_detail: { en: string; fr: string; ar: string, rw: string };
    order: number;
    is_used: boolean;
};

type RowType = {
    id: string;
    original: Vaccine;
    index: number;
};

const VaccineRow: React.FC<{
    isDragDisabled: boolean;
    editHandler: (id: number) => void;
    deleteHandler: (id: number) => void;
    discontinueHandler: (
        vaccine_name: { en: string; fr: string; ar: string, rw: string },
        dose_detail: { en: string; fr: string; ar: string, rw: string },
        optional:boolean,
        preBirth: boolean,
        id: number
    ) => void;
    row: RowType;
    provided: DraggableProvided;
    rowHandler: (id: number) => void;
}> = ({
    row,
    provided,
    rowHandler,
    editHandler,
    deleteHandler,
    isDragDisabled,
    discontinueHandler,
}) => {
    const vaccination_name_en = row.original.name.en;
    const vaccination_name_fr = row.original.name.fr;
    const vaccination_name_ar = row.original.name.ar;
    const vaccination_name_rw = row.original.name.rw;
    const __vaccine_dose_en = row.original.dose_detail.en;
    const __vaccine_dose_fr = row.original.dose_detail.fr;
    const __vaccine_dose_ar = row.original.dose_detail.ar;
    const __vaccine_dose_rw = row.original.dose_detail.rw;
    const isUsed = row.original.is_used;
    const row_id = row.original.id;
    const is_discontinue = row.original.is_discontinue;
    const is_optional = row.original.is_optional;
    const is_pre_birth = row.original.is_pre_birth;
    const [hoverState, setHoverState] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [showDiscontinueModal, setShowDiscontinueModal] = useState(false);
    const parentContainerRef = useRef<HTMLDivElement>(null);
    const childContainerRef = useRef<HTMLDivElement>(null);
    const [showHelpTooltip, setshowHelpTooltip] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const { t } = useTranslation();
    const { discountinueLoading, deleteloading } = useContext(VaccineContext);

    useEffect(() => {
        discountinueLoading === false && setShowDiscontinueModal(false);
        deleteloading === false && setShowDeleteModal(false);
    }, [discountinueLoading, deleteloading]);

    return (
        <>
            <tr
                className="border-t border-gray-df "
                {...provided.draggableProps}
                onMouseOut={() => setHoverState(false)}
                onMouseOver={() => setHoverState(true)}
                ref={provided.innerRef}
                tabIndex={0}
                onMouseDown={() => rowHandler(row.original.id)}
            >
                <td className={`border-t td-firefox border-gray-df table__cell text-sm text-[#414141] sticky z-10 ${i18next.language === "ar" ? "right-0" : "left-0"}`}>
                    <div
                        {...provided.dragHandleProps}
                        className={`absolute mb-2.5 ${i18next.language === "ar" ? "right-0" : "left-0"} bottom-[30px]`}
                    >
                        {!isDragDisabled && (
                            <FontAwesomeIcon
                                icon={faGripVertical}
                                className={`text-base  text-[#989898] ${
                                    hoverState ? "visible" : "invisible"
                                }`}
                            />
                        )}
                        {isDragDisabled && (
                            <div className="relative">
                                <FontAwesomeIcon
                                    onMouseOver={() => setshowHelpTooltip(true)}
                                    onMouseOut={() => setshowHelpTooltip(false)}
                                    icon={faGripVertical}
                                    className={`text-base text-[#989898]  ${
                                        hoverState ? "visible" : "invisible"
                                    }`}
                                />
                                {showHelpTooltip && (
                                    <div className="  left-[30px] top-[-30px] absolute  rounded shadow p-2 text-black41 text-xs bg-white w-[200px] whitespace-pre-wrap">
                                        {t("drag_drop_tooltip__message")}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>

                    <div className="flex">
                         <div className="text-[#1C68AF] text-[15px] font-bold">
                            EN
                        </div>
                        <div className={` ${i18next.language === "ar" ? "mr-[50px]" : "ml-[50px]"} text-[#414141] `}>
                            {vaccination_name_en}
                        </div>
                    </div>
                    <div className="flex">
                        <div className="text-[#1C68AF] text-[15px] font-bold">
                        {process.env.REACT_APP_Country === "Rwanda"
                            ? "RW"
                            : "FR"}
                        </div>
                        <div className={` ${process.env.REACT_APP_Country === "Rwanda" ? (i18next.language === "ar" ? "mr-[46px]" : "ml-[46px]"): (i18next.language === "ar" ? "mr-[52px]" : "ml-[52px]")} text-[#414141] `}>
                            {process.env.REACT_APP_Country === "Rwanda" ? vaccination_name_rw : vaccination_name_fr}
                        </div>
                    </div>
                    {process.env.REACT_APP_Country === "Lebanon" && (
                      <div className="flex">
                        <div className="text-[#1C68AF] text-[15px] font-bold">
                            AR
                        </div>
                        <div className={` ${i18next.language === "ar" ? "mr-[50px]" : "ml-[50px]"} text-[#414141] `}>
                            {vaccination_name_ar}
                        </div>
                      </div>
                    )}
                </td>
                <td className="border-t td-firefox border-gray-df table__cell px-0 text-sm text-[#414141]">
                    <div className="ml-[25px]">
                        <div className="flex">{__vaccine_dose_en}</div>
                        <div className="flex">{process.env.REACT_APP_Country === "Rwanda" ? __vaccine_dose_rw : __vaccine_dose_fr}</div>
                        {process.env.REACT_APP_Country === "Lebanon" && (<div className="flex">{__vaccine_dose_ar}</div>)}
                    </div>
                </td>
                <td className="border-t td-firefox border-gray-df table__cell text-sm text-[#414141]">
                    <div
                        tabIndex={0}
                        onBlur={() => setShowMenu(false)}
                        className="flex justify-between"
                    >
                        <div className="flex flex-row">
                            <VaccineStatusBox statusName={t("optional")} status={is_optional} className="bg-[#e7eef5] text-[#11589a] mr-2"/>
                            <VaccineStatusBox statusName={t("pre-birth")} status={is_pre_birth} className="bg-[#e7f5f0] text-[#119a71] mr-2"/>
                            <VaccineStatusBox statusName={t("discontinued")} status={is_discontinue} className="bg-[#F7F5FE] text-[#6A43E1] mr-2"/>
                        </div>
                        <div
                            className="mr-1"
                            tabIndex={0}
                            onBlur={() => setShowMenu(false)}
                        >
                            <div
                                onMouseOver={() => {
                                    const child = childContainerRef.current!;
                                    const parent = parentContainerRef.current!;
                                    const scrollableContainer =
                                        document.getElementById(
                                            "staff-table__container"
                                        )!;

                                    child.style.top = `${
                                        parent.offsetTop + 32
                                    }px`;
                                    let childLeft = parent.offsetLeft - scrollableContainer.scrollLeft;
                                    if (i18next.language !== "ar") {
                                      childLeft -= 148;
                                    }
                                    child.style.left = `${childLeft}px`;


                                    is_discontinue && setShowMenu(true);
                                }}
                                onMouseOut={() =>
                                    is_discontinue && setShowMenu(false)
                                }
                                ref={parentContainerRef}
                                className="py-1 px-3 rounded cursor-pointer hover:bg-gray-f5 relative"
                                onClick={() => {
                                    setShowMenu((preShowMenu) => !preShowMenu);
                                    const child = childContainerRef.current!;
                                    const parent = parentContainerRef.current!;
                                    const scrollableContainer =
                                        document.getElementById(
                                            "staff-table__container"
                                        )!;

                                    child.style.top = `${
                                        parent.offsetTop + 32
                                    }px`;
                                    child.style.left = `${
                                        parent.offsetLeft -
                                        scrollableContainer.scrollLeft -
                                        176 +
                                        28
                                    }px`;
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faEllipsisV}
                                    className={`${
                                        is_discontinue
                                            ? "text-gray-300"
                                            : "text-blue-primary"
                                    } text-base`}
                                />
                            </div>
                            <div
                                ref={childContainerRef}
                                className={`absolute z-10 ${
                                    showMenu ? "block" : "hidden"
                                }`}
                            >
                                {!is_discontinue ? (
                                    <div className="rounded shadow bg-white py-1 w-44">
                                        <MenuItem
                                            disable={isUsed}
                                            tooltipMessage={t(
                                                "edit_tooltip__message"
                                            )}
                                            titleKey={t("edit")}
                                            onClick={() => {
                                                !isUsed && setShowMenu(false);
                                                !isUsed && editHandler(row_id);
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faPen}
                                                className="mx-2"
                                            />
                                        </MenuItem>

                                        <MenuItem
                                            disable={isUsed}
                                            tooltipMessage={t("delete_tooltip")}
                                            titleKey={t("delete")}
                                            onClick={() => {
                                                !isUsed &&
                                                    setShowDeleteModal(true);
                                                !isUsed && setShowMenu(false);
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                className="mx-2"
                                                icon={faTrash}
                                            />
                                        </MenuItem>
                                        <MenuItem
                                            disable={false}
                                            tooltipMessage={t(
                                                "discontinue_tooltip"
                                            )}
                                            titleKey={t("discontinue")}
                                            onClick={() => {
                                                setShowDiscontinueModal(true);
                                                setShowMenu(false);
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                className="mx-2"
                                                icon={faBan}
                                            />
                                        </MenuItem>
                                    </div>
                                ) : (
                                    <div className="rounded shadow bg-white py-1 w-44 relative whitespace-pre-wrap px-[5px]">
                                        {t("no_action__message")}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {showDeleteModal && (
                        <LastConfirmWarningModal
                            onBackdrop={() => setShowDeleteModal(false)}
                            onCancel={() => setShowDeleteModal(false)}
                            onConfirm={() => {
                                deleteHandler(row_id);
                            }}
                            titleKey="delete_vaccine"
                            messageKey="delete_vaccine_message"
                            confirmButtonLabelKey="yes_delete"
                        />
                    )}
                    {showDiscontinueModal && (
                        <LastConfirmWarningModal
                            onBackdrop={() => setShowDiscontinueModal(false)}
                            onCancel={() => setShowDiscontinueModal(false)}
                            onConfirm={() => {
                                discontinueHandler(
                                    {
                                        en: vaccination_name_en,
                                        fr: vaccination_name_fr,
                                        ar: vaccination_name_ar,
                                        rw: vaccination_name_rw
                                    },
                                    {
                                        en: __vaccine_dose_en,
                                        fr: __vaccine_dose_fr,
                                        ar: __vaccine_dose_ar,
                                        rw: __vaccine_dose_rw
                                    },
                                    is_optional,
                                    is_pre_birth,
                                    row_id
                                );
                            }}
                            titleKey="discontinue_vaccine"
                            messageKey="discontinue_vaccine_message"
                            confirmButtonLabelKey="yes_discontinue"
                        />
                    )}
                </td>
            </tr>
        </>
    );
};

export default VaccineRow;
