import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserFriends } from "@fortawesome/pro-light-svg-icons";
import { faShare } from "@fortawesome/pro-solid-svg-icons";

const ExportPatientsIcon = () => {
  return (
    <div className="relative w-4 h-4 grid place-items-center mx-[9px]">
      <FontAwesomeIcon icon={faUserFriends} />
      <FontAwesomeIcon
        className="absolute text-[10px] -right-[4px] -bottom-[2px]"
        icon={faShare}
      />
    </div>
  );
};

export default ExportPatientsIcon;
