import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ProfileContext } from "../../../store/ProfileContext";

const HeaderLink: React.FC<{}> = () => {
  const { t } = useTranslation();
  const { profileOrAccountSetting, profileOrAccountSettingHandler } =
    useContext(ProfileContext);
  const linkHandler = (isProfilePage: boolean) => {
    profileOrAccountSettingHandler(isProfilePage);
  };

  return (
    <div>
      <div className=" flex pt-[15px] w-[97%] mx-auto">
        <div>
          <Link
            onClick={() => linkHandler(true)}
            className={` capitalize  text-sm ${
              profileOrAccountSetting ? "text-[#11589A]" : "text-[#8D8D8D]"
            } `}
            to="/account-profile"
          >
            {t("profile")}
            <div
              className={`${
                profileOrAccountSetting &&
                "mt-2.5  border-[#11589A] border-t-2 border-solid"
              }`}
            ></div>
          </Link>
        </div>

        <div className="mx-12">
          <Link
            onClick={() => linkHandler(false)}
            className={`text-sm ${profileOrAccountSetting ? "text-[#8D8D8D]" : "text-[#11589A]"}`}
            to="/account-settings"
          >
            {t("account_setting")}
            <div
              className={`${!profileOrAccountSetting && `mt-2.5 border-[#11589A] border-t-2 border-solid`}`}
            ></div>
          </Link>
        </div>
      </div>
      <div className="border-t-[0.2px]"></div>
    </div>
  );
};

export default HeaderLink;
