import React, { useState } from "react";

const MenuItem: React.FC<{
  titleKey: string;
  onClick: () => void;
  children?: React.ReactNode;
  disable?: boolean;
  tooltipMessage?: string;
  className?: string;
}> = ({ titleKey, onClick, children, disable, tooltipMessage, className }) => {
  const [showDisableHint, setShowDisableHint] = useState(false);

  return (
    <li
      className={`clinic__menu-item ${className} ${disable
          ? "text-gray-c0 relative"
          : "text-black41 cursor-pointer hover:bg-blue-e2 hover:text-blue-primary"
        } `}
      onClick={() => onClick()}
      onMouseOver={() => setShowDisableHint(true)}
      onMouseLeave={() => setShowDisableHint(false)}
    >
      {disable && showDisableHint && (
        <p className="absolute bottom-9 right-0 rounded shadow p-2 text-black41 text-xs bg-white w-5/6 whitespace-pre-wrap">
          {tooltipMessage}
        </p>
      )}
      {children}
      <p>{titleKey}</p>
    </li>
  );
};

export default MenuItem;
