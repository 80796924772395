import React from "react";
import noData from "../../images/NoData.svg";
import { useTranslation } from "react-i18next";
import PrimaryButton from "./PrimaryButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BreadCrumbButton } from "./TitleBreadCrumb";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
const NoResultMessage: React.FC<{
  noResultOrNoData: boolean;
  button?: BreadCrumbButton;
}> = ({ noResultOrNoData, button }) => {
  const { t } = useTranslation();

  return (
    <div className="my-[50px]">
      <div className="justify-center flex">
        <img src={noData} alt={t("result_not_found")} />
      </div>

      <div className="justify-center flex mt-[30px] font-semibold text-sm">
        {noResultOrNoData ? t("result_not_found") : t("no_data")}
      </div>
      <div className="justify-center flex mt-5 font-semibold text-sm">
        {!noResultOrNoData && (
          <PrimaryButton primaryOrNot={true} title={button!.buttonTitle} onClick={button!.onClick}>
            <FontAwesomeIcon className="text-sm" icon={faPlus} />
          </PrimaryButton>
        )}
      </div>
    </div>
  );
};

export default NoResultMessage;
