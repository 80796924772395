import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Row } from "react-table";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCommentAltDots } from "@fortawesome/pro-regular-svg-icons";
import { faEye } from "@fortawesome/pro-light-svg-icons";

import Defaulter from "../../../types/Defaulter";
import { DEFAULT_DATE } from "../../../utils/DateUtils";
import { SelectedDefaultersContext } from "../../../store/SelectedDefaultersContext";
import MissedVaccineCell from "./MissedVaccineCell";
import i18next from "i18next";

const DefaulterRow: React.FC<{
    row: Row<Defaulter>;
    isFirstRow: boolean;
}> = ({ row, isFirstRow }) => {
    const { slug, name, note, phoneNumber, nationalID, clinicTitle, area, missedVaccines, lastReminderSent } = row.original;

    const [showOtherMissedVaccines, setShowOtherMissedVaccines] = useState(false);
    const [showHint, setShowHint] = useState(false);
    const [showNote, setShowNote] = useState(false);
    const { t } = useTranslation();
    const history = useHistory();

    const { selectedDefaulters, selectDefaulter, deselectDefaulter } = useContext(SelectedDefaultersContext);

    const checked = selectedDefaulters.indexOf(slug) !== -1;

    return (
        <tr {...row.getRowProps()} className="border-t border-gray-df align-baseline" >
            <td
                {...row.cells[0].getCellProps()}
                className={`table__cell text-sm text-black41 whitespace-normal sticky ${i18next.language === "ar" ? "right-0" : "left-0"} px-3 border-t border-gray-df td-firefox`}
            >
                <div className="flex items-center">
                    <div
                        className={`table__checkbox grid place-items-center ${i18next.language === "ar" ? "ml-4" : "mr-4"} ${checked && "border-blue-primary bg-blue-primary"}`}
                        onClick={() => {
                            if (checked) {
                                deselectDefaulter(slug);
                            } else {
                                selectDefaulter(slug);
                            }
                        }}
                    >
                        {checked && <FontAwesomeIcon icon={faCheck} className="text-sm text-white" />}
                    </div>
                    <p className="whitespace-nowrap">{name}</p>
                    {note && (
                        <div className="ml-3 cursor-pointer text-gray-8d relative">
                            {showNote && (
                                <p
                                    className={`absolute w-[176px] bg-white rounded shadow text-xs text-black41 p-2 whitespace-pre-wrap -left-[70px] ${
                                        isFirstRow ? "top-6" : "bottom-6"
                                    }`}
                                >
                                    {note}
                                </p>
                            )}
                            <FontAwesomeIcon
                                onMouseOver={() => setShowNote(true)}
                                onMouseLeave={() => setShowNote(false)}
                                icon={faCommentAltDots}
                            />
                        </div>
                    )}
                </div>
            </td>
            <td {...row.cells[1].getCellProps()} className={`table__cell text-sm text-black41 ${i18next.language === "ar" && "text-right"}`} dir="ltr">
                {phoneNumber}
            </td>
            <td {...row.cells[2].getCellProps()} className="table__cell text-sm text-black41">
                {nationalID}
            </td>
            <td {...row.cells[3].getCellProps()} className="table__cell text-sm text-black41 whitespace-normal">
                {clinicTitle}
            </td>
            <td {...row.cells[4].getCellProps()} className="table__cell text-sm text-black41 whitespace-normal">
                {area}
            </td>
            <td {...row.cells[5].getCellProps()} className="table__cell text-sm text-black41">
                {missedVaccines.length > 0 && (
                    <MissedVaccineCell
                        missedVaccines={missedVaccines}
                        showOtherMissedVaccines={showOtherMissedVaccines}
                        setShowOtherMissedVaccines={setShowOtherMissedVaccines}
                    />
                )}
            </td>

            <td {...row.cells[6].getCellProps()} className="table__cell text-sm text-black41">
                <div className="flex justify-between items-center">
                    <p>{lastReminderSent.getTime() !== DEFAULT_DATE.getTime() && <Moment date={lastReminderSent} format="DD/MM/YYYY" locale={i18next.language === "ar" ? "ar" : "en"} />}</p>
                    <div
                        className="w-8 h-8 rounded grid place-items-center text-blue-primary hover:bg-blue-e2 cursor-pointer relative"
                        onMouseOver={() => setShowHint(true)}
                        onMouseLeave={() => setShowHint(false)}
                        onClick={() => history.push(`/defaulters/${slug}`)}
                    >
                        <FontAwesomeIcon icon={faEye} />
                        {showHint && (
                            <div className="absolute -top-7 w-16 h-6 grid place-items-center bg-white rounded border border-gray-df shadow-lg">
                                <div className="w-2 h-2 bg-white absolute -bottom-[4px] left-[28px] rotate-45 border-r border-b border-gray-df" />
                                <p className="capitalize text-black41 text-sm">{t("view")}</p>
                            </div>
                        )}
                    </div>
                </div>
            </td>
        </tr>
    );
};

export default DefaulterRow;
