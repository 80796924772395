import { PhoneNumberUtil } from "google-libphonenumber";

export const PHONE_NUMBER_REGEX = /[0-9+()-]/;

const phoneUtil = PhoneNumberUtil.getInstance();
const isPhoneNumberValid = (phoneNumber: string | undefined, isClinicPhoneNumber?: boolean) => {
    if (phoneNumber === undefined) return true;

    if (isClinicPhoneNumber && phoneNumber === "") return true;

    try {
        const parsedNumber = phoneUtil.parse(phoneNumber);
        return phoneUtil.isValidNumber(parsedNumber);
    } catch (error) {
        return false;
    }
};

export default isPhoneNumberValid;
