import React, { useContext } from "react";
import Button from "../../UI/Button";
import { ButtonTypes } from "../../../utils/Constants";
import { useTranslation } from "react-i18next";
import Dropdown, { DropdownType } from "../../UI/Dropdown";
import { StaffContext } from "../../../store/StaffContext";

const StaffFilterBar: React.FC<{
  applyClick(apply: string): void;
  resetClick(val: string): void;
  filterArray: string[];
}> = ({ applyClick, resetClick, filterArray }) => {
  const { statusState, setStatusState } = useContext(StaffContext);
  const { t } = useTranslation();
  const selectChanged = (option: string) => {
    setStatusState(option);
  };

  const applyHandler = (apply: string) => {
    applyClick(apply);
  };

  return (
    <div className="md:flex md:justify-between h-full bg-white  pb-5 md:pb-0 w-95/100 mx-auto">
      <div>
        <label className="text-[#414141] text-sm">{t("staff_status")}</label>

        <div>
          <Dropdown
            type={DropdownType.PRIMARY}
            onOptionSelected={(option) => selectChanged(option)}
            keys={filterArray}
            selectedKey={statusState}
          />
        </div>
      </div>

      <div className=" flex flex-row-reverse">
        <div>
          {statusState !== "AL" && (
            <button
              onClick={() => {
                resetClick(filterArray[0]);
              }}
              className="text-blue-primary hover:font-semibold text-sm mx-6"
            >
              {t("reset")}
            </button>
          )}

          <Button className="mt-6" type={ButtonTypes.PRIMARY} onClick={() => applyHandler(statusState)}>
            {t("apply")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default StaffFilterBar;
