import React, { useState, useRef, useEffect, useContext } from "react";
import StatusBadge from "../../UI/StatusBadge";
import { StatusBadgeTypes } from "../../../utils/Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row } from "react-table";
import LastConfirmWarningModal from "../../UI/LastConfirmWarningModal";
import { useTranslation } from "react-i18next";
import MenuItem from "../../UI/MenuItem";
import Moment from "react-moment";
import { DEFAULT_DATE } from "../../../utils/DateUtils";
import {
  faPen,
  faTrash,
  faCheckCircle,
  faBan,
} from "@fortawesome/pro-light-svg-icons";
import { faEllipsisV, faUserNurse } from "@fortawesome/pro-regular-svg-icons";
import { StaffContext } from "../../../store/StaffContext";
import i18next from "i18next";
import { language } from "../../../utils/language";
import Staff from "../../../types/Staff";

const StaffRow: React.FC<{
  row: Row<Staff>;
  updateStatusHandler: (_status: string, _slugStaff: string) => void;
  editHandler: (slug_Staff: string) => void;
}> = ({ row, editHandler, updateStatusHandler }) => {
  const first_name = row.original.first_name;
  const last_name = row.original.last_name;
  const last_sync = row.original.last_sync;
  const phone_number = row.original.phone_number;
  const email = row.original.email;
  const preferred_language = row.original.preferred_language;
  const is_clinic_admin = row.original.is_clinic_admin;
  const slug_Staff = row.original.slug;
  const status_key = row.original.status_key;
  const [showMenu, setShowMenu] = useState(false);
  const { t } = useTranslation();
  const parentContainerRef = useRef<HTMLDivElement>(null);
  const childContainerRef = useRef<HTMLDivElement>(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const actionHandler = (_status: string, _slugStaff: string) => {
    switch (_status) {
      case "IC":
        updateStatusHandler("EB", _slugStaff);
        break;
      case "AC":
        setShowDisableModal(true);
        break;
      case "PD":
        setShowDeleteModal(true);
        break;
    }
  };
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDisableModal, setShowDisableModal] = useState(false);
  const { loading } = useContext(StaffContext);
  useEffect(() => {
    loading === false && setShowDisableModal(false);
    loading === false && setShowDeleteModal(false);
  }, [loading]);
  const deleteHandler = (_slugStaff: string) => {
    updateStatusHandler("DL", _slugStaff);
  };

  const disableHandler = (_slugStaff: string) => {
    updateStatusHandler("DB", _slugStaff);
  };

  const getTitleKey = (_statusKey: string) => {
    switch (_statusKey) {
      case "AC":
        return t("disable");
      case "IC":
        return t("enable");
      case "PD":
        return t("delete");
      default:
        return "";
    }
  };

  return (
    <>
      <tr className="border-t border-gray-df">
        <td
          className={`border-t td-firefox border-gray-df table__cell text-sm text-[#414141] sticky ${
            i18next.language === "ar" ? "right-0" : "left-0"
          }`}
        >
          {first_name}
          {is_clinic_admin && (
            <div
              className="inline relative mx-2"
              onMouseOver={() => setShowTooltip(true)}
              onMouseOut={() => setShowTooltip(false)}
            >
              <FontAwesomeIcon
                icon={faUserNurse}
                className="text-blue-primary"
              />
              {showTooltip && (
                <div className="absolute top-[-30px] left-[-32px] bg-white rounded shadow px-2 py-1 z-30  w-auto text-xs">
                  {t("clinic_admin")}
                </div>
              )}
            </div>
          )}
        </td>
        <td className="border-t td-firefox border-gray-df table__cell text-sm text-[#414141]">
          {last_name}
        </td>
        <td
          className={`border-t td-firefox border-gray-df table__cell text-sm text-[#414141] ${
            i18next.language === "ar" && "text-right"
          }`}
          dir="ltr"
        >
          {phone_number}
        </td>
        <td className="border-t td-firefox border-gray-df table__cell text-sm text-[#414141]">
          {email}
        </td>
        <td className="border-t td-firefox border-gray-df table__cell text-sm text-[#414141]">
          {language(preferred_language)}
        </td>
        <td className="border-t td-firefox border-gray-df table__cell text-sm text-[#414141]">
          {last_sync.getTime() !== DEFAULT_DATE.getTime() && (
            <div>
              <Moment
                date={last_sync}
                format="DD/MM/YYYY"
                locale={i18next.language === "ar" ? "ar" : "en"}
              ></Moment>
            </div>
          )}

          {last_sync.getTime() !== DEFAULT_DATE.getTime() && (
            <div>
              <Moment
                date={last_sync}
                format="HH:mm:ss"
                locale={i18next.language === "ar" ? "ar" : "en"}
              ></Moment>
            </div>
          )}
        </td>
        <td className="td-firefox border-gray-df table__cell text-sm flex justify-between items-center">
          {(() => {
            switch (status_key) {
              case "AC":
                return (
                  <div>
                    <StatusBadge type={StatusBadgeTypes.ACTIVE} />
                  </div>
                );

              case "IC":
                return (
                  <div>
                    <StatusBadge type={StatusBadgeTypes.INACTIVE} />
                  </div>
                );

              case "PD":
                return (
                  <div>
                    <StatusBadge type={StatusBadgeTypes.PENDING} />
                  </div>
                );
            }
          })()}

          <div
            className="mx-1.5"
            tabIndex={0}
            onBlur={() => setShowMenu(false)}
          >
            <div
              ref={parentContainerRef}
              className="py-1 px-3 rounded cursor-pointer hover:bg-gray-f5 relative"
              onClick={() => {
                setShowMenu((preShowMenu) => !preShowMenu);
                const child = childContainerRef.current!;
                const parent = parentContainerRef.current!;
                const scrollableContainer = document.getElementById(
                  "staff-table__container"
                )!;

                child.style.top = `${parent.offsetTop + 32}px`;
                let childLeft =
                  parent.offsetLeft - scrollableContainer.scrollLeft;
                if (i18next.language !== "ar") {
                  childLeft -= 148;
                }
                child.style.left = `${childLeft}px`;
              }}
            >
              <FontAwesomeIcon
                icon={faEllipsisV}
                className="text-base text-blue-primary"
              />
            </div>
            <div
              ref={childContainerRef}
              className={`absolute z-10 ${showMenu ? "block" : "hidden"}`}
            >
              <ul className="rounded shadow bg-white py-1 w-44">
                <MenuItem
                  titleKey={t("edit")}
                  onClick={() => {
                    editHandler(slug_Staff);
                    setShowMenu(false);
                  }}
                >
                  <FontAwesomeIcon className="mx-2" icon={faPen} />
                </MenuItem>
                <MenuItem
                  titleKey={getTitleKey(status_key)}
                  onClick={() => {
                    actionHandler(status_key, slug_Staff);
                    setShowMenu(false);
                  }}
                >
                  {status_key === "PD" && (
                    <FontAwesomeIcon className="mx-2" icon={faTrash} />
                  )}
                  {status_key === "IC" && (
                    <FontAwesomeIcon className="mx-2" icon={faCheckCircle} />
                  )}
                  {status_key === "AC" && (
                    <FontAwesomeIcon className="mx-2" icon={faBan} />
                  )}
                </MenuItem>
                {status_key === "IC" && (
                  <MenuItem
                    titleKey={getTitleKey("PD")}
                    onClick={() => {
                      actionHandler("PD", slug_Staff);
                      setShowMenu(false);
                    }}
                  >
                    {status_key === "IC" && (
                      <FontAwesomeIcon className="mx-2" icon={faTrash} />
                    )}
                  </MenuItem>
                )}
              </ul>
            </div>
          </div>

          {showDeleteModal && (
            <LastConfirmWarningModal
              onBackdrop={() => setShowDeleteModal(false)}
              onCancel={() => setShowDeleteModal(false)}
              onConfirm={() => deleteHandler(slug_Staff)}
              titleKey={t("delete_staff")}
              messageKey={t("delete_staff_message")}
              confirmButtonLabelKey={t("yes_delete")}
            />
          )}

          {showDisableModal && (
            <LastConfirmWarningModal
              onBackdrop={() => setShowDisableModal(false)}
              onCancel={() => setShowDisableModal(false)}
              onConfirm={() => disableHandler(slug_Staff)}
              titleKey="disable_staff"
              messageKey="disable_staff_message"
              confirmButtonLabelKey="yes_disable"
            />
          )}
        </td>
      </tr>
    </>
  );
};

export default StaffRow;
