import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import AuthContextProvider from "./store/AuthContext";
import App from "./App";

i18n
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    lng: localStorage.getItem("auth_data") ? JSON.parse(localStorage.getItem("auth_data")!).lang : "en",
    fallbackLng: "en",
    react: { useSuspense: false },
  });

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <Router>
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  </Router>
);
