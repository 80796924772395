import React, { useEffect, useRef } from "react";

import background from "../../../images/background.png";
import logo from "../../../images/www.svg";

const AuthenticatedNotFound404: React.FC<{
  message: string;
}> = ({ message }) => {
  const container = useRef<HTMLElement>(null);

  useEffect(() => {
    const onWindowResize = () => {
      if (container.current) {
        if (window.innerWidth >= 768) {
          container.current.style.height = `${window.innerHeight - 168}px`;
          container.current.style.marginTop = "-30px";
        } else {
          container.current.style.height = `${window.innerHeight - 60.32}px`;
          container.current.style.marginTop = "0";
        }
      }
    };

    window.addEventListener("resize", onWindowResize);
    onWindowResize();

    return () => window.removeEventListener("resize", onWindowResize);
  }, []);

  return (
    <main ref={container} className="grid place-items-center p-4">
      <img
        className="w-auto h-full absolute right-0 bottom-0 -z-10"
        src={background}
        alt=""
      />
      <section className="flex items-center flex-col-reverse 960:flex-row">
        <img src={logo} alt="www" />
        <h1 className="font-bold text-4xl text-blue-primary text-center m-8">
          {message}
        </h1>
      </section>
    </main>
  );
};

export default AuthenticatedNotFound404;
