import React, { useState, useEffect, PropsWithChildren } from "react";

import ClinicsApi from "../store/api/clinicsAPI";

type DefaulterFilter = {
    clinicTitleSlug: string;
    lastReminderSentFrom: Date | null;
    lastReminderSentTo: Date | null;
    missedVaccinesScheduledDateFrom: Date | null;
    missedVaccinesScheduledDateTo: Date | null;
    isMandatory: boolean,
};

export type ClinicTitle = {
    slug: string;
    name: string;
};

const DEFAULT_DEFAULTER_FILTER = {
    clinicTitleSlug: "",
    lastReminderSentFrom: null,
    lastReminderSentTo: null,
    missedVaccinesScheduledDateFrom: null,
    missedVaccinesScheduledDateTo: null,
    isMandatory: false,
};

type SearchFilterDefaultersContextType = {
    searchTerm: string;
    setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
    defaulterFilter: DefaulterFilter;
    defaulterAppliedFilter: DefaulterFilter;
    setDefaulterFilter: React.Dispatch<React.SetStateAction<DefaulterFilter>>;
    setDefaulterAppliedFilter: React.Dispatch<React.SetStateAction<DefaulterFilter>>;
    clinicTitles: ClinicTitle[];
    reset: () => void;
    isAnyFilterApplied: () => boolean;
};

export const SearchFilterDefaultersContext = React.createContext<SearchFilterDefaultersContextType>({
    searchTerm: "",
    setSearchTerm: () => {
        //
    },
    defaulterFilter: DEFAULT_DEFAULTER_FILTER,
    defaulterAppliedFilter: DEFAULT_DEFAULTER_FILTER,
    clinicTitles: [],
    setDefaulterFilter: () => {
        //
    },
    setDefaulterAppliedFilter: () => {
        //
    },
    reset: () => {
        //
    },
    isAnyFilterApplied: () => false,
});

const SearchFilterDefaultersContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [defaulterFilter, setDefaulterFilter] = useState<DefaulterFilter>(DEFAULT_DEFAULTER_FILTER);
    const [defaulterAppliedFilter, setDefaulterAppliedFilter] = useState<DefaulterFilter>(DEFAULT_DEFAULTER_FILTER);
    const [clinicTitles, setClinicTitles] = useState<ClinicTitle[]>([]);

    const { getClinicTitles } = ClinicsApi();

    const reset = () => {
        setDefaulterAppliedFilter(DEFAULT_DEFAULTER_FILTER);
    };

    useEffect(() => {
        getClinicTitles((clinicTitles) => setClinicTitles(clinicTitles));
    }, []);

    const isAnyFilterApplied = () => {
        return (
            defaulterAppliedFilter.clinicTitleSlug !== DEFAULT_DEFAULTER_FILTER.clinicTitleSlug ||
            defaulterAppliedFilter.lastReminderSentFrom !== DEFAULT_DEFAULTER_FILTER.lastReminderSentFrom ||
            defaulterAppliedFilter.lastReminderSentTo !== DEFAULT_DEFAULTER_FILTER.lastReminderSentTo ||
            defaulterAppliedFilter.missedVaccinesScheduledDateFrom !== DEFAULT_DEFAULTER_FILTER.missedVaccinesScheduledDateFrom ||
            defaulterAppliedFilter.missedVaccinesScheduledDateTo !== DEFAULT_DEFAULTER_FILTER.missedVaccinesScheduledDateTo || defaulterAppliedFilter.isMandatory !== DEFAULT_DEFAULTER_FILTER.isMandatory
        );
    };

    return (
        <SearchFilterDefaultersContext.Provider
            value={{
                searchTerm,
                setSearchTerm,
                defaulterFilter,
                defaulterAppliedFilter,
                setDefaulterFilter,
                setDefaulterAppliedFilter,
                clinicTitles,
                reset,
                isAnyFilterApplied,
            }}
        >
            {children}
        </SearchFilterDefaultersContext.Provider>
    );
};

export default SearchFilterDefaultersContextProvider;
