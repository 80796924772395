import useAPI from "../../hooks/useAPI";

const ProfileAndAccountAPI = () => {
	const { sendRequest } = useAPI();

	const editProfile = (editProfileProcessResponse: (response: Response) => void, _formData: FormData) => {
		sendRequest(
			{
				url: "profile/",
				method: "PATCH",
				body: _formData,
				contentType: true,
			},
			editProfileProcessResponse
		);
	};

	const editPassword = (editPasswordProcessResponse: (response: Response) => void, currentPassword: string, secondPassword: string) => {
		sendRequest(
			{
				url: "profile/update-pass/",
				method: "PUT",
				body: JSON.stringify({
					current_password: currentPassword,
					new_password: secondPassword,
				}),
			},
			editPasswordProcessResponse
		);
	};
	return { editProfile, editPassword };
};

export default ProfileAndAccountAPI;
