import Button from "./PrimaryButton";
import { useTranslation } from "react-i18next";

const ExportButton: React.FC<{
  onClick?: () => void;
  title: string;
  children: React.ReactNode;
  className?: string;
  onBlur?: () => void;
}> = (props) => {
  const { t } = useTranslation();
  const { onClick, title, children, className, onBlur } = props;

  return (
    <>
      <Button
        primaryOrNot={false}
        title={t(title)}
        onClick={() => onClick?.()}
        className={className}
        onBlur={() => onBlur?.()}
      >
        {children}
      </Button>
    </>
  );
};

export default ExportButton;
