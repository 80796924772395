export const getFormattedDate = (
  date: Date | null,
  display: boolean = true
) => {
  if (!date) return;

  const month = `${date.getMonth() + 1}`.padStart(2, "0");
  const day = `${date.getDate()}`.padStart(2, "0");

  if (display) {
    return `${day}/${month}/${date.getFullYear()}`;
  } else {
    return `${date.getFullYear()}-${month}-${day}`;
  }
};

export const DEFAULT_DATE = new Date("1970-01-01");
