import React from "react";

import ErrorBanner from "./ErrorBanner";

const BlockedUserError: React.FC<{
  message: string;
  className?: string;
}> = (props) => {
  const { message, className } = props;

  return (
    <div className={className}>
      <ErrorBanner message={message} />
      <p className="text-center text-sm text-gray-8d mt-4 first-letter:uppercase">
        please contact the support <b>(support@cima.com)</b> to assist you with
        that.
      </p>
    </div>
  );
};

export default BlockedUserError;
