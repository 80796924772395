import { useContext, useState } from "react";
import { SnackBarType, AddOrEditModal } from "../../../utils/Constants";
import { Snackbar, SnackbarContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { saveAs } from "file-saver";
import { VaccineContext } from "../../../store/VaccineContext";
import VaccineAPI from "../../../store/api/vaccineAPI";
import LoadingSpinner from "../../UI/LoadingSpinner";
import i18next from "i18next";

export const vaccineDetailLoader = (Component: any) => {
  return (props: any) => {
    const { setResponseData, responseData, setDeleteloading, setDiscountinueLoading, setAddLoading, setEditLoading } =
      useContext(VaccineContext);
    const [numberOfImportedVaccine, setNumberOfImportedVaccine] = useState(0);
    const [exporting, setExporting] = useState(false);
    const [snackBarType, setSnackBarType] = useState<SnackBarType>();
    const [showSnackbar, setShowSnackbar] = useState(false);
    const { t } = useTranslation();
    const {
      getVaccines,
      importVaccine,
      deleteVaccine,
      downloadVaccine,
      discountinueVaccine,
      searchVaccine,
      updateVaccineOrder,
      addVaccine,
      editVaccine,
    } = VaccineAPI();

    const getDataFromApi = () => {
      getVaccines(async (response: Response) => {
        if (response.ok) {
          const result = await response.json();
          setResponseData(result);
        } else {
          console.log("vaccine: vaccine not found ");
        }
      });
    };

    const importHandler = (formData: FormData, _searchInput: string) => {
      importVaccine((response) => importProcessResponse(response, _searchInput), formData);
    };

    const importProcessResponse = async (response: Response, _searchInput: string) => {
      if (response.ok) {
        const result = await response.json();
        searchVaccineHandler(_searchInput);
        setNumberOfImportedVaccine(result.length);
        setSnackBarType(SnackBarType.IMPORT);
        setShowSnackbar(true);
      } else {
        setShowSnackbar(true);
        setSnackBarType(SnackBarType.IMPORTFAILED);
      }
    };

    const deleteHandler = (_id: number) => {
      setDeleteloading(true);
      deleteVaccine((response) => deleteVaccineProcessResponse(response, _id), _id);
    };

    const deleteVaccineProcessResponse = async (response: Response, _id: number) => {
      if (response.ok) {
        setSnackBarType(SnackBarType.DELETE);
        setShowSnackbar(true);
        deleteResponse(_id);
        setDeleteloading(false);
      } else {
        console.log("message: vaccination not found");
      }
    };

    const deleteResponse = (_id: number) => {
      const newData = [...responseData];
      const index = newData.findIndex((vaccine) => vaccine.id === _id);
      newData.splice(index, 1);
      setResponseData(newData);
    };

    const downloadVaccineHandler = () => {
      setSnackBarType(SnackBarType.DOWNLOAD);
      downloadVaccine(downloadProcessResponse);
    };

    const downloadProcessResponse = async (response: Response) => {
      if (response.ok) {
        setExporting(true);
        setTimeout(async () => {
          try {
            const blob = await response.blob();
            saveAs(blob, `Cima_Vaccination_Details_Template.csv`);
            setExporting(false);
            setShowSnackbar(true);
          } catch (e) {
            console.log("error:file not found");
          } finally {
            setExporting(false);
          }
        }, 1000);
      }
    };

    const discontinueHandler = (
      vaccine_name: { en: string; fr: string; ar: string; rw: string },
      dose_detail: { en: string; fr: string; ar: string; rw: string },
      optional: boolean,
      preBirth: boolean,
      _id: number
    ) => {
      setDiscountinueLoading(true);
      const id = _id.toString();
      discountinueVaccine(
        (response) => discountinueProcessResponse(response, _id),
        vaccine_name,
        dose_detail,
        optional,
        preBirth,
        id
      );
    };

    const discountinueProcessResponse = async (response: Response, _id: number) => {
      if (response.ok) {
        setSnackBarType(SnackBarType.DISCONTINUE);
        setShowSnackbar(true);
        discontinueResponese(_id);
        setDiscountinueLoading(false);
      } else {
        console.log("message: vaccination not found");
      }
    };

    const discontinueResponese = (_id: number) => {
      const newData = [...responseData];
      const index = newData.findIndex((vaccine) => vaccine.id === _id);
      newData[index] = {
        id: newData[index].id,
        name: {
          en: newData[index].name.en,
          fr: newData[index].name.fr,
          ar: newData[index].name.ar,
          rw: newData[index].name.rw,
        },
        dose_detail: {
          en: newData[index].dose_detail.en,
          fr: newData[index].dose_detail.fr,
          ar: newData[index].dose_detail.ar,
          rw: newData[index].dose_detail.rw,
        },
        is_discontinue: true,
        is_optional: newData[index].is_optional,
        is_pre_birth: newData[index].is_pre_birth,
        is_used: newData[index].is_used,
        order: newData[index].order,
      };
      setResponseData(newData);
    };

    const searchVaccineHandler = (_searchValue: string) => {
      searchVaccine(async (response: Response) => {
        if (response.ok) {
          const result = await response.json();
          setResponseData(result);
        } else {
          console.log("vaccine: vaccine not found");
        }
      }, _searchValue);
    };

    const update_order = (rowId: number, _oldOrder: number, _newOrder: number) => {
      updateVaccineOrder(
        (response) => {
          if (!response.ok) {
            console.log("vaccine: vaccine not found");
          }
        },
        rowId,
        _oldOrder,
        _newOrder
      );
    };

    const addOrEditVaccinationHandler = (
      _type: AddOrEditModal,
      _name: { en: string; fr: string; ar: string; rw: string },
      _dose_detail: { en: string; fr: string; ar: string; rw: string },
      _optional: boolean,
      _preBirth: boolean,
      searchInput: string,
      _editVaccineId?: number
    ) => {
      switch (_type) {
        case 0:
          setAddLoading(true);
          addVaccine(
            (response) => addVaccineProcessResponse(response, searchInput),
            _name,
            _dose_detail,
            _optional,
            _preBirth
          );

          break;

        case 1:
          setEditLoading(true);
          editVaccine(
            (response) => editVaccineProcessResponse(response, searchInput),
            _name,
            _dose_detail,
            _optional,
            _preBirth,
            _editVaccineId!
          );
          break;
      }
    };

    const addVaccineProcessResponse = async (response: Response, searchInput: string) => {
      if (response.ok) {
        searchVaccineHandler(searchInput);
        setSnackBarType(SnackBarType.ADD);
      } else {
        setSnackBarType(SnackBarType.ADDEDITFAILED);
      }

      setShowSnackbar(true);
      setAddLoading(false);
    };

    const editVaccineProcessResponse = async (response: Response, searchInput: string) => {
      setEditLoading(false);

      if (response.ok) {
        searchVaccineHandler(searchInput);
        setSnackBarType(SnackBarType.EDIT);
      } else {
        setSnackBarType(SnackBarType.ADDEDITFAILED);
      }

      setShowSnackbar(true);
      setAddLoading(false);
    };

    return (
      <>
        <Snackbar
          dir={i18next.language === "ar" ? "rtl" : "ltr"}
          open={showSnackbar}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          sx={{
            "& .MuiSnackbarContent-root": {
              backgroundColor: `${
                snackBarType === SnackBarType.IMPORTFAILED || snackBarType === SnackBarType.ADDEDITFAILED
                  ? "#D11C1C"
                  : "#03A678"
              }`,
            },
            "& .MuiSnackbarContent-message": {
              fontSize: "16px",
              fontFamily: "'Open Sans', sans-serif",
              fontWeight: "100",
            },
          }}
          onClose={() => setShowSnackbar(false)}
        >
          <SnackbarContent
            message={
              <p>
                {snackBarType === SnackBarType.DELETE && t("vaccine_deleted_message")}
                {snackBarType === SnackBarType.IMPORT && (
                  <>
                    {numberOfImportedVaccine}
                    <span className="ml-[5px]">{t("rows_added")}</span>
                  </>
                )}
                {snackBarType === SnackBarType.DOWNLOAD && <>{t("template_downloaded_successfully")}</>}
                {snackBarType === SnackBarType.IMPORTFAILED && t("import_vaccine_faild_message")}
                {snackBarType === SnackBarType.DISCONTINUE && <>{t("discontinue_message")}</>}
                {snackBarType === SnackBarType.ADD && <>{t("vaccine_added")}</>}
                {snackBarType === SnackBarType.ADDEDITFAILED && <>{t("vaccine_available")}</>}
                {snackBarType === SnackBarType.EDIT && <>{t("vaccine_updated_message")}</>}
              </p>
            }
          />
        </Snackbar>

        {exporting && <LoadingSpinner onBackdrop={() => setExporting(false)} titleKey={t("download_in_progress")} />}

        <Component
          {...props}
          getDataFromApi={getDataFromApi}
          importHandler={importHandler}
          deleteHandler={deleteHandler}
          downloadVaccineHandler={downloadVaccineHandler}
          discontinueHandler={discontinueHandler}
          searchVaccineHandler={searchVaccineHandler}
          update_order={update_order}
          addOrEditVaccinationHandler={addOrEditVaccinationHandler}
        />
      </>
    );
  };
};
