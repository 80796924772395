import { useContext, useState } from "react";
import { AuthContext } from "../../../store/AuthContext";
import { Snackbar, SnackbarContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import ProfileAndAccountSettinApi from "../../../store/api/profileAndAccountAPI";
import i18next from "i18next";

export const profileLoader = (Component: any) => {
  return (props: any) => {
    const { updateProfile } = useContext(AuthContext);
    const [responseText, setResponseText] = useState("");
    const [responseIsOk, setResponseIsOk] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [cancelCalled, setCancelCalled] = useState(false);
    const [photoChanged, setPhotoChanged] = useState(false);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const { editProfile } = ProfileAndAccountSettinApi();

    const saveHandler = (
      photoDeleted: boolean,
      photo: string,
      firstname: string,
      lastname: string
    ) => {
      const formData = new FormData();
      photoDeleted && formData.append("photo", "");
      photoChanged && formData.append("photo", photo);
      formData.append("first_name", firstname);
      formData.append("last_name", lastname);
      setLoading(true);
      editProfile(editProfileProcessResponse, formData);
    };

    const editProfileProcessResponse = async (response: Response) => {
      const result = await response.json();

      if (response.ok) {
        setTimeout(() => {
          updateProfile(result.first_name, result.last_name, result.photo);
        }, 500);
        setResponseIsOk(response.ok);
        setShowSnackbar(true);
        setCancelCalled(!cancelCalled);
        setLoading(false);
      } else {
        setResponseText(result.photo[0]);
        setResponseIsOk(response.ok);
        setShowSnackbar(true);
        setLoading(false);
      }
    };

    const photoChangeHandler = (isChanged: boolean) => {
      setPhotoChanged(isChanged);
    };
    return (
      <>
        <Snackbar
          dir={i18next.language === "ar" ? "rtl": "ltr"}
          open={showSnackbar}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          sx={{
            "& .MuiSnackbarContent-root": {
              backgroundColor: `${responseIsOk ? "#03A678" : "#D11C1C"}`,
            },
            "& .MuiSnackbarContent-message": {
              fontSize: "16px",
              fontFamily: "'Open Sans', sans-serif",
              fontWeight: "100",
            },
          }}
          onClose={() => setShowSnackbar(false)}
        >
          <SnackbarContent
            message={
              <p>
                {responseIsOk && t("information_updated")}
                {!responseIsOk && responseText}
              </p>
            }
          />
        </Snackbar>
        <Component
          {...props}
          saveHandler={saveHandler}
          loading={loading}
          cancelCalled={cancelCalled}
          photoChangeHandler={photoChangeHandler}
        />
      </>
    );
  };
};
