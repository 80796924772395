import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faUserCheck, faPen, faCloudUpload } from "@fortawesome/pro-regular-svg-icons";
import { useHistory } from "react-router-dom";
import MenuItem from "../../UI/MenuItem";
import ExportStaffIcon from "../../UI/ExportStaffIcon";
import ExportPatientsIcon from "../../UI/ExportPatientsIcon";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

interface ClinicEllipsisVMenuProps {
  slug: string;
  onExportClicked: (isPatient: boolean) => void;
  onEditClinic: () => void;
  onImportNewBornChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onImportFollwsUpChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  staffCount: number;
  patientCount: number;
}

const ClinicEllipsisVMenu: React.FC<ClinicEllipsisVMenuProps> = ({
  slug,
  onExportClicked,
  onEditClinic,
  onImportNewBornChange,
  onImportFollwsUpChange,
  staffCount,
  patientCount,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const parentContainerRef = useRef<HTMLDivElement>(null);
  const childContainerRef = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState(false);
  const hiddenNewbornFileInput = useRef<HTMLInputElement>(null);
  const hiddenFollowUpFileInput = useRef<HTMLInputElement>(null);

  return (
    <div className="mr-1" tabIndex={0} onBlur={() => setShowMenu(false)}>
      <div
        ref={parentContainerRef}
        className="py-1 px-3 rounded cursor-pointer hover:bg-gray-f5 relative"
        onClick={() => {
          setShowMenu((preShowMenu) => !preShowMenu);
          const child = childContainerRef.current!;
          const parent = parentContainerRef.current!;
          const scrollableContainer = document.getElementById("clinic-table__container")!;

          if (scrollableContainer) {
          child.style.top = `${parent.offsetTop + 32}px`;
          let childLeft = parent.offsetLeft - scrollableContainer.scrollLeft;
          if (i18next.language !== "ar") {
            childLeft -= 196;
          }
          child.style.left = `${childLeft}px`;
        } else {
          child.style.top = `${parent.offsetTop + 36}px`;
          let childLeft = parent.offsetLeft;
          if (i18next.language !== "ar") {
            childLeft -= 196;
          }
          child.style.left = `${childLeft}px`;
        }
        }}
      >
        <FontAwesomeIcon icon={faEllipsisV} className="text-base text-blue-primary" />
      </div>
      <div
        ref={childContainerRef}
        className={`absolute z-10  ${i18next.language === "ar" ? "left-14" : "right-16"} bottom-full ${showMenu ? "block" : "hidden"}`}
      >
        <ul className="rounded shadow bg-white py-1 w-56">
          <MenuItem titleKey={t("manage_staff")} onClick={() => history.push(`/clinics/${slug}/staff`)}>
            <FontAwesomeIcon icon={faUserCheck} className="mx-2" />
          </MenuItem>
          <MenuItem
            titleKey={t("edit")}
            onClick={() => {
              setShowMenu(false);
              onEditClinic();
            }}
          >
            <FontAwesomeIcon icon={faPen} className={`${i18next.language === "ar" ? "ml-3 mr-2" : "mr-3 ml-2"}`} />
          </MenuItem>
          {patientCount > 0 && (
          <MenuItem
            titleKey={t("export_patients")}
            onClick={() => {
              setShowMenu(false);
              onExportClicked(true);
            }}
          >
            <ExportPatientsIcon />
          </MenuItem>
           )}
           {staffCount > 0 && (
          <MenuItem
            titleKey={t("export_staff")}
            onClick={() => {
              setShowMenu(false);
              onExportClicked(false);
            }}
          >
            <ExportStaffIcon />
          </MenuItem>
          )}
          <MenuItem
            titleKey={t("import_newborns")}
            onClick={() => {
              setShowMenu(false);
              if (hiddenNewbornFileInput.current) hiddenNewbornFileInput.current.click();
            }}
          >
            <FontAwesomeIcon icon={faCloudUpload} className="mx-2" />
            <input
              onChange={onImportNewBornChange}
              ref={hiddenNewbornFileInput}
              type="file"
              accept=".csv"
              className="hidden"
            />
          </MenuItem>
          <MenuItem
            titleKey={t("import_follow_ups")}
            onClick={() => {
              setShowMenu(false);
              if (hiddenFollowUpFileInput.current) hiddenFollowUpFileInput.current.click();
            }}
          >
            <FontAwesomeIcon icon={faCloudUpload} className="mx-2" />
            <input
              onChange={onImportFollwsUpChange}
              ref={hiddenFollowUpFileInput}
              type="file"
              accept=".csv"
              className="hidden"
            />
          </MenuItem>
        </ul>
      </div>
    </div>
  );
};

export default ClinicEllipsisVMenu;
