import React from "react";

const ToggleInput: React.FC<{
  id: string;
  type: string;
  label: string;
  checked: boolean;
  onChange: () => void;
}> = ({ id, type, label, checked, onChange }) => {
  return (
    <div>
      <input
        type={type}
        id={id}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id} className="mx-2">
        {label}
      </label>
    </div>
  );
};

export default ToggleInput;
