import React, { PropsWithChildren, useState } from "react";

type SelectedDefaultersContextType = {
  selectedDefaulters: string[];
  selectDefaulter: (slug: string) => void;
  deselectDefaulter: (slug: string) => void;
  selectAll: (slug: string[]) => void;
  deselectAll: (slug: string[]) => void;
  clearSelectedDefaulters: () => void;
};

export const SelectedDefaultersContext =
  React.createContext<SelectedDefaultersContextType>({
    selectedDefaulters: [],
    selectDefaulter: () => {
      //
    },
    deselectDefaulter: () => {
      //
    },
    selectAll: () => {
      //
    },
    deselectAll: () => {
      //
    },
    clearSelectedDefaulters: () => {
      //
    },
  });

const SelectedDefaultersContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [selectedDefaulters, setSelectedDefaulters] = useState<string[]>([]);

  const selectDefaulter = (slug: string) => {
    setSelectedDefaulters((preSelectedDefaulters) => {
      if (preSelectedDefaulters.indexOf(slug) === -1) {
        return [...preSelectedDefaulters, slug];
      } else {
        return preSelectedDefaulters;
      }
    });
  };

  const deselectDefaulter = (slug: string) => {
    setSelectedDefaulters((preSelectedDefaulters) =>
      preSelectedDefaulters.filter(
        (preSelectedDefaulter) => preSelectedDefaulter !== slug
      )
    );
  };

  const selectAll = (slugs: string[]) => {
    setSelectedDefaulters((preSelectedDefaulters) => [
      ...preSelectedDefaulters,
      ...slugs.filter((slug) => preSelectedDefaulters.indexOf(slug) === -1),
    ]);
  };

  const deselectAll = (slugs: string[]) => {
    setSelectedDefaulters((preSelectedDefaulters) =>
      preSelectedDefaulters.filter(
        (preSelectedDefaulter) => slugs.indexOf(preSelectedDefaulter) === -1
      )
    );
  };

  const clearSelectedDefaulters = () => {
    setSelectedDefaulters([]);
  };

  return (
    <SelectedDefaultersContext.Provider
      value={{
        selectedDefaulters,
        selectDefaulter,
        deselectDefaulter,
        selectAll,
        deselectAll,
        clearSelectedDefaulters,
      }}
    >
      {children}
    </SelectedDefaultersContext.Provider>
  );
};

export default SelectedDefaultersContextProvider;
