import React, { useState } from "react";
import TextAreaInput from "../../UI/TextAreaInput";
import { useTranslation } from "react-i18next";
import CancelAndSend from "./CancelAndSend";

const ResendMessageModal: React.FC<{
  onCancel: () => void;
  onSend: (message: string) => void;
  loading: boolean;
}> = ({ onCancel, onSend, loading }) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };
  
  return (
    <div onClick={onCancel} className="backdrop">
      <div
        onClick={(e) => e.stopPropagation()}
        className="md:w-[492px] py-[21px] bg-white cursor-auto w-8/12 h-auto rounded "
      >
        <form onSubmit={(e) => submitForm(e)} className="mx-6">
          <div className="text-lg font-bold">{t("send_message")}</div>
          <TextAreaInput
            className="h-[200px]"
            title={t("message")}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            value={message}
            placeholder={""}
            isRequired= {true}
            hasFlag= {false}
            showNumberOfChars= {true}
          />
        </form>
        <CancelAndSend
          cancelBtn={onCancel}
          disableSendBtn={message.length === 0}
          loading={loading}
          sendBtn={() => onSend(message)}
        />
      </div>
    </div>
  );
};

export default ResendMessageModal;
