import useAPI from "../../hooks/useAPI";

const HealthMessageAPI = () => {
	const { sendRequest } = useAPI();
	const getMessagesFromServer = (processGetMessageResponse: (response: Response) => void, searchInput?: string) => {
		let url: string = "";
		if (searchInput === "") {
			url = `health-messages/`;
		} else {
			url = `health-messages/?page=${1}&page_size=${20}&search=${searchInput}`;
		}
		sendRequest(
			{
				url: url,
			},
			processGetMessageResponse
		);
	};

	const deleteHealthMessage = (processGetMessageResponse: (response: Response) => void, _id: number) => {
		sendRequest(
			{
				url: `health-messages/${_id}/`,
				method: "DELETE",
			},
			processGetMessageResponse
		);
	};

	const downloadMessageFile = (responseDownloadMessageFile: (response: Response) => void) => {
		sendRequest(
			{
				url: `health-messages/template/?country=${process.env.REACT_APP_Country}`,
				method: "GET",
				contentType: true,
			},

			responseDownloadMessageFile
		);
	};

	const resendMessage = (resendMessageProcessResponse: (response: Response) => void) => {
		sendRequest(
			{
				url: "/health-messages/resend/",
				method: "POST",
				contentType: true,
			},
			resendMessageProcessResponse
		);
	};

	const importMessage = (importMessageProcessResponse: (response: Response) => void, formData: FormData) => {
		sendRequest(
			{
				url: `health-messages/import/?country=${process.env.REACT_APP_Country}`,
				contentType: true,
				method: "POST",
				body: formData,
			},
			importMessageProcessResponse
		);
	};

	const addHealthMessage = (
		addMessageProcessResponse: (response: Response) => void,
		message: { _enMessage: string; _frMessage: string; _arMessage: string; _rwMessage: string }
	) => {
		sendRequest(
			{
				url: `health-messages/?country=${process.env.REACT_APP_Country}`,
				method: "POST",
				body: JSON.stringify({
					text: { en: message._enMessage, fr: message._frMessage, ar: message._arMessage, rw: message._rwMessage },
				}),
			},
			addMessageProcessResponse
		);
	};

	const editHealthMessage = (
		editMessageProcessResponse: (response: Response) => void,
		message: { _enMessage: string; _frMessage: string; _arMessage: string; _rwMessage: string },
		id: number
	) => {
		sendRequest(
			{
				url: `health-messages/${id}/?country=${process.env.REACT_APP_Country}`,
				method: "PUT",
				body: JSON.stringify({
					text: { en: message._enMessage, fr: message._frMessage, ar: message._arMessage, rw: message._rwMessage },
				}),
			},
			(response) => editMessageProcessResponse(response)
		);
	};
	return {
		getMessagesFromServer,
		deleteHealthMessage,
		downloadMessageFile,
		resendMessage,
		importMessage,
		addHealthMessage,
		editHealthMessage,
	};
};
export default HealthMessageAPI;
