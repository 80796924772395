import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import React from "react";

const StatusBar: React.FC<{
  status: string;
  setStatus: (status: string) => void;
  deleteStatus: () => void;
  statusTitle: string;
}> = ({ status, setStatus, deleteStatus, statusTitle }) => {
  const { t } = useTranslation();

  return (
    <>
      {status !== "al" && (
          <div className="h-10 mt-3 md:ml-2 flex">
            {status !== "" && (
              <div className="bg-[#E2ECF5] mr-2 rounded-[3px]">
                <span data-testid="staff_status" className="text-blue-primary pt-2 block text-sm px-3">
                  {t(statusTitle)}:
                  <span className="font-bold">
                    <span className="ml-2 mr-2">{t(status)}</span>
                    <span className="mr-1">
                      <FontAwesomeIcon
                        icon={faTimes}
                        onClick={() => {
                          setStatus("");
                          deleteStatus();
                        }}
                      />
                    </span>
                  </span>
                </span>
              </div>
            )}
          </div>
      )}
    </>
  );
};

export default StatusBar;
