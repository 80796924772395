import React, { useEffect, useState, useContext } from "react";
import { AddOrEditModal } from "../../../utils/Constants";
import { useTranslation } from "react-i18next";
import isPhoneNumberValid from "../../../utils/isPhoneNumberValid";
import TextInput from "../../UI/TextInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import CancelAndSaveButton from "../../UI/CancelAndSaveButton";
import { StaffContext } from "../../../store/StaffContext";
import i18next from "i18next";
import { EMAIL_REGEX } from "../../../hooks/UseEmailValidation";
import ToggleInput from "../../UI/ToggleInput";
import Staff from "../../../types/Staff";
import useRole from "../../../hooks/useRole";

const AddEditStaffModal: React.FC<{
  className: string;
  onCancel: () => void;
  staff?: Staff;
  status?: string;
  saveAddOrEditHandler: (
    _type: AddOrEditModal,
    name: string,
    lastName: string,
    phone: string,
    email: string,
    language: string,
    isClinicAdmin: boolean,
    editStaffSlug?: string
  ) => void;
}> = ({ className, onCancel, staff, status, saveAddOrEditHandler }) => {
  const { t } = useTranslation();
  const DEFAULT_LANGUAGE = "en";
  const { isClinicAdmin } = useRole();

  const initialStaffState: Staff = {
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    preferred_language: DEFAULT_LANGUAGE,
    is_clinic_admin: false,
    last_sync: new Date(),
    slug: "",
    status: "",
    status_key: ""
  };
  const [staffState, setStaffState] = useState<Staff>(staff || initialStaffState);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [phoneValid, setPhoneValid] = useState(true);
  const [emailErrorMsg, setEmailErrorMsg] = useState<string | undefined>(
    undefined
  );
  const [showInfo, setShowInfo] = useState(false);
  const { loading } = useContext(StaffContext);

  const languages = (() => {
    switch (process.env.REACT_APP_Country) {
      case "Cameroon":
        return [
          { lang: "en", label: t("en") },
          { lang: "fr", label: t("fr") },
        ];
      case "Lebanon":
        return [
          { lang: "en", label: t("en") },
          { lang: "fr", label: t("fr") },
          { lang: "ar", label: t("ar") },
        ];
      case "Rwanda":
        return [
          { lang: "en", label: t("en") },
          { lang: "rw", label: t("rw") },
        ];
      default:
        return [];
    }
  })();

  useEffect(() => {
    setDisableSaveBtn(
      !Boolean(
        staffState?.first_name && staffState.last_name && staffState.phone_number && phoneValid && staffState.email && !emailErrorMsg
      )
    );
  }, [staffState, phoneValid, emailErrorMsg]);

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormSubmitted(true);
  };

  const cancelBtn = () => {
    !phoneValid === true && setPhoneValid(!phoneValid);
    setEmailErrorMsg(undefined);
    setStaffState(initialStaffState)
    onCancel();
  };

  const saveBtn = (_type: AddOrEditModal) => {
    saveAddOrEditHandler(
      _type,
      staffState!.first_name,
      staffState!.last_name,
      staffState!.phone_number,
      staffState!.email,
      staffState!.preferred_language,
      staffState!.is_clinic_admin,
      staff ? staff.slug : undefined
    );
  };

  return (
    <>
      <div onClick={cancelBtn} className={`backdrop ${className}`}>
        <div
          onClick={(e) => e.stopPropagation()}
          className="md:w-[492px] bg-white cursor-auto w-8/12 h-auto rounded overflow-auto"
        >
          <form
            onSubmit={(e) => submitForm(e)}
            dir={i18next.language === "ar" ? "rtl" : "ltr"}
            className="mx-6 mb-4"
          >
            <div className="mt-[21px] text-lg font-bold">
              {staff ? t("edit_staff") : t("add_staff")}
            </div>
            <div className="md:mt-5 mt-3">
              <label className="block">
                {t("first_name")}
                <span className="text-[#D11C1C] ml-1.5">*</span>
              </label>

              <div className="h-[60px]">
                <TextInput
                  className="mt-[5px]"
                  value={staffState?.first_name || ""}
                  placeholder=""
                  onTextInputChanged={(_name) => {
                    setStaffState(prevState => {
                      if (!prevState) return prevState;
                      return {
                        ...prevState,
                        first_name: _name,
                      };
                    });
                  }}
                  hasError={formSubmitted && staffState?.first_name === ""}
                  errorMessage={t("firstName_error")}
                />
              </div>
            </div>

            <div className="mt-[5px]">
              <label className="block">
                {t("last_name")}
                <span className="text-[#D11C1C] ml-1.5">*</span>
              </label>

              <div className="h-[60px]">
                <TextInput
                  className="mt-[5px]"
                  value={staffState?.last_name || ""}
                  placeholder=""
                  onTextInputChanged={(_lastName) => {
                    setStaffState(prevState => {
                      if (!prevState) return prevState;
                      return {
                        ...prevState,
                        last_name: _lastName,
                      };
                    });
                  }}
                  hasError={formSubmitted && staffState?.last_name === ""}
                  errorMessage={t("lastName_error")}
                />
              </div>
            </div>

            <div className="mt-[5px]">
              <label className="block">
                {t("phone_number")}
                <span className="text-[#D11C1C] ml-1.5">*</span>
                {status === "AC" && (
                  <span
                    onMouseOver={() => setShowInfo(true)}
                    onMouseOut={() => setShowInfo(false)}
                    className="relative"
                  >
                    <FontAwesomeIcon
                      className="ml-1 cursor-pointer text-blue-400 text-base"
                      icon={faInfoCircle}
                    />
                    {showInfo && (
                      <div className="absolute top-[-76px] left-[25px] w-[216px] text-[#414141] shadow z-40 text-xs px-1.5 py-1.5 bg-white rounded">
                        {t("info-text__message")}
                      </div>
                    )}
                  </span>
                )}
              </label>

              <div className="h-[60px]">
                <TextInput
                  className="mt-[5px]"
                  value={staffState?.phone_number || ""}
                  placeholder=""
                  onTextInputChanged={(_phoneNumber) => {
                    setStaffState(prevState => {
                      if (!prevState) return prevState;
                      return {
                        ...prevState,
                        phone_number: _phoneNumber,
                      };
                    });
                    setPhoneValid(isPhoneNumberValid(_phoneNumber || ""));
                  }}
                  hasError={!phoneValid || (formSubmitted && staffState?.phone_number === "")}
                  errorMessage={
                    staffState?.phone_number === "" ? t("phone_number_error") : t("number_valid")
                  }
                />
              </div>
            </div>

            <div className="mt-2">
              <label className="block">
                {t("email")}
                <span className="text-[#D11C1C] ml-1.5">*</span>
              </label>

              <div className="h-[60px]" dir="ltr">
                <TextInput
                  className="mt-[5px]"
                  value={staffState?.email || ""}
                  placeholder=""
                  onTextInputChanged={(_email) => {
                    setStaffState(prevState => {
                      if (!prevState) return prevState;
                      return {
                        ...prevState,
                        email: _email,
                      };
                    });
                    setEmailErrorMsg(
                      _email.match(EMAIL_REGEX) === null
                        ? t("email_valid")
                        : undefined
                    );
                  }}
                  hasError={emailErrorMsg !== undefined}
                  errorMessage={
                    staffState?.email === "" ? t("email_error") : t("email_valid")
                  }
                />
              </div>
            </div>

            {!isClinicAdmin() && (
              <>
                <div className="mt-[5px]">
                  <label className="block">{t("communication_language")}</label>
                  <div className="flex flex-row mt-[5px]">
                    {languages.map(({ lang, label }) => (
                      <ToggleInput
                        key={lang}
                        id={lang}
                        type="radio"
                        label={label}
                        checked={staffState?.preferred_language?.toLowerCase() === lang}
                        onChange={() => {
                          setStaffState(prevState => {
                            if (!prevState) return prevState;
                            return {
                              ...prevState,
                              preferred_language: lang,
                            };
                          });
                        }}
                      />
                    ))}
                  </div>
                </div>

                <div className="mt-5">
                  <ToggleInput
                    id="isClinicAdmin"
                    type="checkbox"
                    label={t("set_as_clinic_admin")}
                    checked={staffState ? staffState.is_clinic_admin : false}
                    onChange={() => {
                      setStaffState(prevState => {
                        if (!prevState) return prevState;
                        return {
                          ...prevState,
                          is_clinic_admin: !staffState?.is_clinic_admin,
                        };
                      });
                    }}
                  />
                </div>
              </>
            )}

            <CancelAndSaveButton
              cancelBtn={cancelBtn}
              disableSaveBtn={disableSaveBtn}
              saveBtn={saveBtn}
              type={staff ? AddOrEditModal.EDIT : AddOrEditModal.ADD}
              loading={loading}
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default AddEditStaffModal;
