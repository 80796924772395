import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { HeaderGroup } from "react-table";

import Defaulter from "../../types/Defaulter";
import Clinic from "../../types/Clinic";
import Subscriber from "../../types/Subscriber";
import TableHeaderCell from "./TableHeaderCell";
import i18next from "i18next";

const TableHeader: React.FC<{
    headerGroups: HeaderGroup<Clinic>[] | HeaderGroup<Defaulter>[] | HeaderGroup<Subscriber>[];
    allChecked: boolean;
    selectAll: () => void;
    deselectAll: () => void;
    className?: string;
}> = ({ headerGroups, allChecked, selectAll, deselectAll, className }) => {
    return (
        <thead>
            {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((header, index) => (
                        <th
                            {...header.getHeaderProps(header.getSortByToggleProps())}
                            className={`table__cell border-b border-gray-df td-firefox ${index === 0 && `sticky z-10 ${i18next.language === "ar" ? "right-0 pr-0" : `left-0 ${className}`}`}`}
                        >
                            <div className="flex items-center">
                                {index === 0 && (
                                    <div
                                        className={`table__checkbox grid place-items-center ${
                                            allChecked && "border-blue-primary bg-blue-primary"
                                        } mx-3`}
                                        onClick={(e) => {
                                            e.stopPropagation();

                                            if (allChecked) {
                                                deselectAll();
                                            } else {
                                                selectAll();
                                            }
                                        }}
                                    >
                                        {allChecked && <FontAwesomeIcon icon={faCheck} className="text-sm text-white" />}
                                    </div>
                                )}
                                <TableHeaderCell header={header} />
                            </div>
                        </th>
                    ))}
                </tr>
            ))}
        </thead>
    );
};

export default TableHeader;
