import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress, Snackbar } from "@mui/material";
import { SnackBarType, StatusBadgeTypes } from "../../../utils/Constants";
import Clinic from "../../../types/Clinic";
import ClinicsAPI from "../../../store/api/clinicsAPI";
import MainContainer from "../../UI/MainContainer";
import MainFooter from "../../UI/MainFooter";
import SearchSection from "../../UI/SearchSection";
import NoResultMessage from "../../UI/NoResultMessage";
import AreaTable from "../setting/areas/AreaTable";
import { parseCoordinates } from "../../../utils/parseCoordinates";
import ClinicEllipsisVMenu from "./ClinicEllipsisVMenu";
import ClinicModal from "./ClinicModal";
import ChildrenAPI from "../../../store/api/childrenAPI";
import StatusBadge from "../../UI/StatusBadge";
import Area from "../../../types/Area";
import { SnackbarType } from "../reports/vaccineUtilization/VaccineUtilization";
import saveAs from "file-saver";
import LoadingSpinner from "../../UI/LoadingSpinner";

const ClinicDetails = () => {
  const { t } = useTranslation();
  const [clinic, setClinic] = useState<Clinic>();
  const [areas, setAreas] = useState<Array<Area>>([]);
  const [showLoading, setShowLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(2);
  const [pageCount, setPageCount] = useState<number>(0);
  const [areasCount, setAreasCount] = useState<number>(0);
  const [createEditClinicModal, setCreateEditClinicModal] = useState(false);
  const [displayEditClinicModal, setDisplayEditClinicModal] = useState(true);
  const { getClinicDetails, exportClinics } = ClinicsAPI();
  const { importNewBornsAPI, importFollowUpsAPI } = ChildrenAPI();
  const [searchInput, setSearchInput] = useState<string>("");
  const [exporting, setExporting] = useState(false);
  const [snackbar, setSnackbar] = useState<SnackbarType>({
    message: "",
    type: 0,
    show: false,
  });

  const columns = [
    {
      header: t("text"),
      accessor: "text",
    },
  ];

  useEffect(() => {
    if (searchInput) {
      setPageNumber(0);
    }
  }, [searchInput]);

  useEffect(() => {
    getDataFromServer(searchInput);
  }, [pageNumber, pageSize, searchInput]);

  const getDataFromServer = (searchInput?: string) => {
    setShowLoading(true);
    getClinicDetails(async (response: Response) => {
      const result = await response.json();

      if (response.ok) {
        setClinic({
          slug: result.clinic.slug,
          title: result.clinic.name,
          phoneNumber: result.clinic.phone_number,
          address: result.clinic.address,
          areaNames: result.clinic.areas,
          latitude: result.clinic.latitude,
          longitude: result.clinic.longitude,
          status: result.clinic.active,
          staffCount: result.clinic.staff_count,
          patientCount: result.clinic.patient_count,
        } as Clinic);

        const areasWithParsedCoordinates = result.areas.map((area: any) =>
          parseCoordinates(area)
        );
        setAreas(areasWithParsedCoordinates);
        setPageCount(result.page_count);
        setAreasCount(result.areas_count);
        setShowLoading(false);
      }
    },
    pageNumber + 1,
    pageSize,
    searchInput);
  };

  const onExportClicked = (isPatient: boolean) => {
    exportClinics(isPatient, [{ slug: clinic!.slug }], async (response) => {
      if (response.ok) {
        setExporting(true);
        setTimeout(async () => {
          try {
            const blob = await response.blob();
            saveAs(
              blob,
              `${
                isPatient
                  ? clinic?.title + "_patients"
                  : clinic?.title + "_staff"
              }.csv`
            );
            setExporting(false);
            setSnackbar({
              message: t("data_exported_successfully"),
              type: SnackBarType.DOWNLOAD,
              show: true,
            });
          } catch (e) {
            console.log(e);
          } finally {
            setExporting(false);
          }
        }, 1000);
      }
    });
  };

  const onImportNewBornChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const fileUpload = Object(event.target.files)[0];
    const formData = new FormData();
    formData.append("csv", fileUpload);
    importNewBornsAPI(clinic!.slug, formData, async (response: Response) => {
      if (response.ok) {
        setSnackbar({
          message: t("import_completed__message"),
          type: SnackBarType.IMPORT,
          show: true,
        });
        getDataFromServer();
      } else {
        setSnackbar({
          message: t("import_faild_message"),
          type: SnackBarType.IMPORTFAILED,
          show: true,
        });
      }
    });
  };

  const onImportFollwsUpChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const fileUpload = Object(event.target.files)[0];
    const formData = new FormData();
    formData.append("csv", fileUpload);
    importFollowUpsAPI(clinic!.slug, formData, async (response: Response) => {
      if (response.ok) {
        setSnackbar({
          message: t("import_completed__message"),
          type: SnackBarType.IMPORT,
          show: true,
        });
        getDataFromServer();
      } else {
        setSnackbar({
          message: t("import_faild_message"),
          type: SnackBarType.IMPORTFAILED,
          show: true,
        });
      }
    });
  };

  return (
    <MainContainer>
      <Snackbar
        open={snackbar.show}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: `${
              snackbar.type === SnackBarType.IMPORTFAILED
                ? "#D11C1C"
                : "#03A678"
            }`,
          },
          "& .MuiSnackbarContent-message": {
            fontSize: "16px",
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: "100",
          },
        }}
        onClose={() => {
          setSnackbar({ message: "", type: 0, show: false });
        }}
        message={snackbar.message}
      />
      <main className="alignment mb-8">
        <header className="flex flex-col mt-4 md:flex-row md:justify-between md:items-center md:mt-0">
          <h3 className="text-lg font-semibold text-gray-27">
            {t("clinic_list")}
          </h3>
        </header>

        <section className="w-full rounded mt-6 min-h-[621px] bg-white">
          <SearchSection
            onSearch={(_searchInput) => {
              setSearchInput(_searchInput);
            }}
            noFilter={true}
            onSliderClicked={() => {}}
          />

          <hr className="h-[10px] bg-gray-f5 border-none" />

          <main className="w-full mx-auto">
            {clinic && (
              <div className="flex flex-row justify-between p-6">
                <div className="flex flex-col space-y-10 768:space-y-0 768:flex-row justify-between bg-white w-5/6">
                  <div className="flex flex-col space-y-10">
                    <div className="text-sm text-black41">
                      <label className="font-semibold">{t("title")}</label>
                      <p className="mt-[10px]">{clinic.title}</p>
                    </div>
                    <div className="text-sm text-black41">
                      <label className="font-semibold">
                        {t("phone_number")}
                      </label>
                      <p dir="ltr" className="mt-[10px]">{clinic.phoneNumber}</p>
                    </div>
                    <div className="text-sm text-black41">
                      <label className="font-semibold">
                        {t("number_of_staff")}
                      </label>
                      <p className="mt-[10px]">{clinic.staffCount}</p>
                    </div>
                  </div>

                  <div className="flex flex-col space-y-10 768:place-self-end md:max-w-[45%]">
                    <div className="text-sm text-black41">
                      <label className="font-semibold">{t("address")}</label>
                      <p className="mt-[10px]">{clinic.address}</p>
                    </div>
                    <div className="text-sm text-black41">
                      <label className="font-semibold">
                        {t("number_of_registered_children")}
                      </label>
                      <p className="mt-[10px]">{clinic.patientCount}</p>{" "}
                    </div>
                  </div>

                  <div className="flex flex-col space-y-10 justify-between">
                    <div className="768:mt-6">
                      {clinic.status === true ? (
                        <StatusBadge type={StatusBadgeTypes.ACTIVE} />
                      ) : (
                        <StatusBadge type={StatusBadgeTypes.INACTIVE} />
                      )}
                    </div>
                    <div className="text-sm text-black41">
                      <label className="font-semibold">{t("areas")}</label>
                      <p className="mt-[10px]">
                        {clinic.areaNames && clinic.areaNames.map((status) => status.name).join(", ")}
                      </p>
                    </div>
                  </div>
                </div>
                <ClinicEllipsisVMenu
                  slug={clinic.slug}
                  onExportClicked={onExportClicked}
                  onEditClinic={() => {
                    setCreateEditClinicModal(true);
                    setDisplayEditClinicModal(true);
                  }}
                  onImportNewBornChange={onImportNewBornChange}
                  onImportFollwsUpChange={onImportFollwsUpChange}
                  staffCount={clinic.staffCount}
                  patientCount={clinic.patientCount}
                />
              </div>
            )}

            <hr className="h-[10px] border-none bg-gray-f5" />

            <div dir="ltr" className="bg-gray-f5">
              {showLoading ? (
                <div className="flex w-full h-[500px] items-center justify-center">
                  <CircularProgress size={64} style={{ color: "#11589a" }} />
                </div>
              ) : areas.length > 0 ? (
                <AreaTable
                  columns={columns}
                  data={areas}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  pageCount={pageCount}
                  totalDataCount={areasCount}
                  paginationDropdownKeys={["2", "4", "8"]}
                />
              ) : (
                <div className="text-center py-2.5 rounded mt-[5px] bg-white">
                  <NoResultMessage noResultOrNoData={true} />
                </div>
              )}
            </div>
          </main>
          {exporting && (
            <LoadingSpinner
              onBackdrop={() => setExporting(false)}
              titleKey={t("exporting_to_csv")}
            />
          )}
        </section>
        <MainFooter />
      </main>

      {createEditClinicModal && (
        <ClinicModal
          isAdd={false}
          className={displayEditClinicModal ? "grid" : "hidden"}
          onBackdrop={() => setDisplayEditClinicModal(false)}
          onCancel={() => setCreateEditClinicModal(false)}
          clinic={clinic}
          onClinicEdited={() => {
            setCreateEditClinicModal(false);
            setSnackbar({
              message: t("clinic_edited__message"),
              type: SnackBarType.EDIT,
              show: true,
            });
            setShowLoading(true);
            getDataFromServer();
          }}
        />
      )}
    </MainContainer>
  );
};

export default ClinicDetails;
