import { useContext, useState } from "react";
import { Snackbar, SnackbarContent } from "@mui/material";
import { SnackBarType } from "../../../utils/Constants";
import { useTranslation } from "react-i18next";
import { saveAs } from "file-saver";
import { HealthMessageContext } from "../../../store/HealthMessageContext";
import HealthMessageAPI from "../../../store/api/healthMessageAPI";
import LoadingSpinner from "../../UI/LoadingSpinner";
import i18next from "i18next";

export const healthMessageLoader = (Component: any) => {
	type Message = {
		id: number;
		text: { en: string; fr: string; ar: string; rw: string };
	};
	return (props: any) => {
		const [allMessageSent, setAllMessageSent] = useState(false);
		const [showSnackbar, setShowSnackbar] = useState(false);
		const [exporting, setExporting] = useState(false);
		const [snackBarType, setSnackBarType] = useState<SnackBarType>();
		const [numberOfImportedRows, setNumberOfImportedRows] = useState(0);
		const {
			setResponseData,
			responseData,
			setLoading,
			setResendClicked,
			setSkipPageReset,
		} = useContext(HealthMessageContext);

		const { t } = useTranslation();

		const {
			getMessagesFromServer,
			deleteHealthMessage,
			downloadMessageFile,
			resendMessage,
			importMessage,
			addHealthMessage,
			editHealthMessage,
		} = HealthMessageAPI();
		const getDataFromServer = (searchInput?: string) => {
			getMessagesFromServer(processGetMessageResponse, searchInput);
		};

		const processGetMessageResponse = async (response: Response) => {
			if (response.ok) {
				const result = await response.json();
				setAllMessageSent(result.all_sent);
				setResponseData(result.results);
			} else {
				console.log("message: health message not found ");
			}
		};
		/////////////// delete data ///////////////////

		const deleteMessage = (_id: number) => {
			setSkipPageReset(false);
			deleteHealthMessage((response) => processDeleteMessageResponse(response, _id), _id);
		};

		const processDeleteMessageResponse = async (response: Response, _id: number) => {
			if (response.ok) {
				setShowSnackbar(true);
				setSnackBarType(SnackBarType.DELETE);
				deleteResponse(_id);
			} else {
				console.log("message: health message not found ");
			}
		};

		const deleteResponse = (_id: number) => {
			const newData = [...responseData];
			const index = newData.findIndex((vaccine) => vaccine.id === _id);
			newData.splice(index, 1);
			setResponseData(newData);
		};

		const downloadMessageFileHandler = () => {
			setSnackBarType(SnackBarType.DOWNLOAD);
			downloadMessageFile(downloadProcessResponse);
		};

		const downloadProcessResponse = async (response: Response) => {
			if (response.ok) {
				setExporting(true);
				setTimeout(async () => {
					try {
						const blob = await response.blob();
						saveAs(blob, ` "cima_health_messages".csv`);
						setExporting(false);
						setShowSnackbar(true);
					} catch (e) {
						console.log("error:file not found");
					}finally {
						setExporting(false);
					  }
				}, 1000);
			}
		};

		const resendMessageHandler = () => {
			resendMessage(resendMessageProcessResponse);
		};

		const resendMessageProcessResponse = async (response: Response) => {
			if (response.ok) {
				try {
					setResendClicked(true);
				} catch (e) {
					console.log("error:link not found");
				}
			}
		};

		const importMessageHandler = (formData: FormData, searchValue: string) => {
			importMessage((response) => importMessageProcessResponse(response, searchValue), formData);
		};

		const importMessageProcessResponse = async (response: Response, _searchValue: string) => {
			if (response.ok) {
				const result = await response.json();
				result.length && setSkipPageReset(true);
				getDataFromServer(_searchValue);
				setNumberOfImportedRows(result.length);
				setShowSnackbar(true);
				setSnackBarType(SnackBarType.IMPORT);
			} else {
				setShowSnackbar(true);
				setSnackBarType(SnackBarType.IMPORTFAILED);
			}
		};

		const addHealthMessageHandler = (enMessage: string, frMessage: string, arMessage: string, rwMessage: string, searchValue: string) => {
			setLoading(true);

			addHealthMessage((response) => addMessageProcessResponse(response, searchValue), {
				_enMessage: enMessage,
				_frMessage: frMessage,
				_arMessage: arMessage,
				_rwMessage: rwMessage,
			});
		};

		const addMessageProcessResponse = async (response: Response, _searchValue: string) => {
			if (response.ok) {
				setSkipPageReset(true);
				setSnackBarType(SnackBarType.ADD);
				setShowSnackbar(true);
				getDataFromServer(_searchValue);
			}
			setLoading(false);
		};

		const dataUpdated = (id: number, text_en: string, text_fr: string, text_ar: string, text_rw: string) => {
			const newData: Message[] = [];
			const responseMessage: Message = {
				id: id,
				text: { en: text_en, fr: text_fr, ar: text_ar, rw: text_rw },
			};
			newData.push(responseMessage);
			newData.push(...responseData);
			setResponseData(newData);
		};
		const editHealthMessageHandler = (id: number, enMessage: string, frMessage: string, arMessage: string, rwMessage: string, searchValue: string) => {
			setLoading(true);

			editHealthMessage(
				(response) => editMessageProcessResponse(response, searchValue),
				{ _enMessage: enMessage, _frMessage: frMessage, _arMessage: arMessage, _rwMessage: rwMessage },
				id
			);
		};

		const editMessageProcessResponse = async (response: Response, _searchValue: string) => {
			if (response.ok) {
				setSkipPageReset(true);
				setShowSnackbar(true);
				setSnackBarType(SnackBarType.EDIT);
				getDataFromServer(_searchValue);
			}
			setLoading(false);
		};

		return (
			<>
				<Snackbar
					dir={i18next.language === "ar" ? "rtl": "ltr"}
					open={showSnackbar}
					autoHideDuration={3000}
					anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
					sx={{
						"& .MuiSnackbarContent-root": {
							backgroundColor: `${snackBarType === SnackBarType.IMPORTFAILED ? "#D11C1C" : "#03A678"}`,
						},
						"& .MuiSnackbarContent-message": {
							fontSize: "16px",
							fontFamily: "'Open Sans', sans-serif",
							fontWeight: "100",
						},
					}}
					onClose={() => setShowSnackbar(false)}>
					<SnackbarContent
						message={
							<p>
								{snackBarType === SnackBarType.DELETE && t("record_deleted_message")}
								{snackBarType === SnackBarType.DOWNLOAD && t("template_downloaded_successfully")}
								{snackBarType === SnackBarType.IMPORT && (
									<>
										{numberOfImportedRows}
										<span className="ml-[5px]">{t("rows_added")}</span>
									</>
								)}
								{snackBarType === SnackBarType.IMPORTFAILED && t("import_faild_message")}
								{snackBarType === SnackBarType.ADD && t("record_added")}
								{snackBarType === SnackBarType.EDIT && t("record_updated_message")}
							</p>
						}
					/>
				</Snackbar>
				{exporting && 
					<LoadingSpinner
					onBackdrop={() => setExporting(false)}
					titleKey={t("download_in_progress")}
					/>
				}
				<Component
					{...props}
					getDataFromServer={getDataFromServer}
					allMessageSent={allMessageSent}
					deleteMessage={deleteMessage}
					dataUpdated={dataUpdated}
					downloadMessageFile={downloadMessageFileHandler}
					resendMessageHandler={resendMessageHandler}
					importMessageHandler={importMessageHandler}
					addHealthMessageHandler={addHealthMessageHandler}
					editHealthMessageHandler={editHealthMessageHandler}
					searchHandler={getDataFromServer}
				/>
			</>
		);
	};
};
