import React from "react";
import Button from "../../UI/Button";
import { ButtonTypes } from "../../../utils/Constants";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";

const CancelAndSend: React.FC<{
  cancelBtn: () => void;
  sendBtn: () => void;
  disableSendBtn: boolean;
  loading: boolean;
}> = ({ cancelBtn, sendBtn, disableSendBtn, loading }) => {
  const { t } = useTranslation();

  return (
    <div className="mt-5 sm:flex sm:justify-end mx-6 mb-2 sm:mb-0">
      <div className="sm:mx-2 mb-2 sm:mb-0">
        <Button type={ButtonTypes.CANCEL} onClick={cancelBtn}>
          {t("cancel")}
        </Button>
      </div>
      <div>
        <Button
          disabled={disableSendBtn}
          type={ButtonTypes.PRIMARY}
          onClick={sendBtn}
        >
          {loading ? (
            <CircularProgress size={18} style={{ color: "white" }} />
          ) : (
            t("send")
          )}
        </Button>
      </div>
    </div>
  );
};

export default CancelAndSend;
