import React, { PropsWithChildren, useState } from "react";
import { SnackBarType } from "../utils/Constants";
import Clinic from "../types/Clinic";

type ClinicFilter = {
    status: string;
    minStaffCount: string;
    maxStaffCount: string;
    minPatientCount: string;
    maxPatientCount: string;
};

export const DEFAULT_CLINIC_FILTER = {
    status: "AL",
    minStaffCount: "",
    maxStaffCount: "",
    minPatientCount: "",
    maxPatientCount: "",
};

type SearchFilterClinicsContextType = {
    clinics: Clinic[];
    setClinics: React.Dispatch<React.SetStateAction<Clinic[]>>;
    searchTerm: string;
    setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
    clinicFilter: ClinicFilter;
    clinicAppliedFilter: ClinicFilter;
    setClinicFilter: React.Dispatch<React.SetStateAction<ClinicFilter>>;
    setClinicAppliedFilter: React.Dispatch<React.SetStateAction<ClinicFilter>>;
    reset: () => void;
    isAnyFilterApplied: () => boolean;
    showClinicAddedSnackbar: boolean;
    setShowClinicAddedSnackbar: (showClinicAddedSnackar: boolean) => void;
    showClinicImportedNewbornSnackbar: boolean,
    setShowClinicImportedNewbornSnackbar: (ShowClinicImportedNewbornSnackbar: boolean) => void;
    showClinicImportedFollowUpsSnackbar: boolean,
    setShowClinicImportedFollowUpsSnackbar: (ShowClinicImportedFollowUpsSnackbar: boolean) => void,
    showClinicEditedSnackbar: boolean;
    setShowClinicEditedSnackbar: (showClinicEditedSnackar: boolean) => void;
    tableAutoReset: boolean;
    setTableAutoReset: (tableAutoReset: boolean) => void;
    snackBarType: SnackBarType;
    setSnackBarType: (type: SnackBarType) => void;
    importErrorMessage: string;
    setImportErrorMessage: (message: string) => void
};

export const SearchFilterClinicsContext = React.createContext<SearchFilterClinicsContextType>({
    clinics: [],
    setClinics: () => {
        //
    },
    searchTerm: "",
    setSearchTerm: () => {
        //
    },
    clinicFilter: DEFAULT_CLINIC_FILTER,
    clinicAppliedFilter: DEFAULT_CLINIC_FILTER,
    setClinicFilter: () => {
        //
    },
    setClinicAppliedFilter: () => {
        //
    },
    reset: () => {
        //
    },
    isAnyFilterApplied: () => false,
    showClinicAddedSnackbar: false,
    setShowClinicAddedSnackbar: () => {
        //
    },
    showClinicEditedSnackbar: false,
    setShowClinicEditedSnackbar: () => {
        //
    },
    showClinicImportedNewbornSnackbar: false,
    setShowClinicImportedNewbornSnackbar: () => {
        //
    },
    showClinicImportedFollowUpsSnackbar: false,
    setShowClinicImportedFollowUpsSnackbar: () => {
        //
    },
    tableAutoReset: true,
    setTableAutoReset: () => {
        //
    },
    snackBarType: SnackBarType.IMPORT,
    setSnackBarType: (type: SnackBarType) => {
        //
    },
    importErrorMessage: "",
    setImportErrorMessage: () => {
        //
    }
});

const SearchFilterClinicsContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const [clinics, setClinics] = useState<Clinic[]>([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [clinicFilter, setClinicFilter] = useState<ClinicFilter>(DEFAULT_CLINIC_FILTER);
    const [clinicAppliedFilter, setClinicAppliedFilter] = useState<ClinicFilter>(DEFAULT_CLINIC_FILTER);
    const [showClinicAddedSnackbar, setShowClinicAddedSnackbar] = useState(false);
    const [showClinicEditedSnackbar, setShowClinicEditedSnackbar] = useState(false);
    const [showClinicImportedNewbornSnackbar, setShowClinicImportedNewbornSnackbar] = useState(false);
    const [showClinicImportedFollowUpsSnackbar, setShowClinicImportedFollowUpsSnackbar] = useState(false);
    const [tableAutoReset, setTableAutoReset] = useState(true);
    const [snackBarType, setSnackBarType] = useState(SnackBarType.IMPORT);
    const [importErrorMessage, setImportErrorMessage] = useState('');
    const reset = () => {
        setClinicAppliedFilter(DEFAULT_CLINIC_FILTER);
    };

    const isAnyFilterApplied = () => {
        return (
            clinicAppliedFilter.status !== DEFAULT_CLINIC_FILTER.status ||
            clinicAppliedFilter.minStaffCount !== DEFAULT_CLINIC_FILTER.minStaffCount ||
            clinicAppliedFilter.maxStaffCount !== DEFAULT_CLINIC_FILTER.maxStaffCount ||
            clinicAppliedFilter.minPatientCount !== DEFAULT_CLINIC_FILTER.minPatientCount ||
            clinicAppliedFilter.maxPatientCount !== DEFAULT_CLINIC_FILTER.maxPatientCount
        );
    };

    return (
        <SearchFilterClinicsContext.Provider
            value={{
                clinics,
                setClinics,
                searchTerm,
                setSearchTerm,
                clinicFilter,
                clinicAppliedFilter,
                setClinicFilter,
                setClinicAppliedFilter,
                reset,
                isAnyFilterApplied,
                showClinicAddedSnackbar,
                setShowClinicAddedSnackbar,
                showClinicEditedSnackbar,
                setShowClinicEditedSnackbar,
                tableAutoReset,
                setTableAutoReset,
                showClinicImportedNewbornSnackbar,
                setShowClinicImportedNewbornSnackbar,
                showClinicImportedFollowUpsSnackbar,
                setShowClinicImportedFollowUpsSnackbar,
                snackBarType,
                setSnackBarType,
                importErrorMessage,
                setImportErrorMessage
            }}
        >
            {children}
        </SearchFilterClinicsContext.Provider>
    );
};

export default SearchFilterClinicsContextProvider;
