import { useContext } from "react";

import { AuthContext } from "../../store/AuthContext";
import EN from "../../images/english.svg";
import RW from "../../images/rwanda.svg";
import FR from "../../images/french.svg";
import AR from "../../images/lebanon.svg";
import i18next from "i18next";

const selectLangs = () => {
  if (process.env.REACT_APP_Country === "Cameroon") {
    return [
      {
        flag: EN,
        title: "english",
      },
      {
        flag: FR,
        title: "french",
      },
    ];
  } else if (process.env.REACT_APP_Country === "Lebanon") {
    return [
      {
        flag: EN,
        title: "english",
      },
      {
        flag: FR,
        title: "french",
      },
      {
        flag: AR,
        title: "arabic",
      },
    ];
  } else if (process.env.REACT_APP_Country === "Rwanda") {
    return [
      {
        flag: EN,
        title: "english",
      },
      {
        flag: RW,
        title: "kinyarwanda",
      },
    ];
  }
};

const LanguageSelection: React.FC<{
  showLangs: boolean;
  setShowLangs: React.Dispatch<React.SetStateAction<boolean>>;
}> = (props) => {
  const { authData, changeLang } = useContext(AuthContext);

  const { showLangs, setShowLangs } = props;

  return (
    <div className="w-11 h-6 relative">
      <img
        data-testid="preferred-lang__img"
        onClick={() => setShowLangs((preShowLang) => !preShowLang)}
        className="w-full h-full cursor-pointer"
        src={authData.lang === "en" ? EN : authData.lang === "fr" ? FR : authData.lang === "ar" ? AR : RW}
        alt={
          authData.lang === "en"
            ? "English"
            : authData.lang === "fr"
            ? "French"
            : authData.lang === "ar"
            ? "Arabic"
            : "Kinyarwanda"
        }
      />
      {showLangs && (
        <ul
          className={`bg-white absolute left-0 top-8 w-48 py-2 rounded shadow-md z-10 ${
            i18next.language === "ar" ? "md:left-0" : "md:right-0 md:left-auto"
          } `}
          data-testid="available-langs__list"
        >
          {selectLangs()!.map((lang, index) => (
            <li
              className="flex items-center cursor-pointer pl-6 py-2 hover:bg-gray-f5"
              dir="ltr"
              onClick={() => {
                if (
                  (lang.title === "english" && authData.lang === "en") ||
                  (lang.title === "french" && authData.lang === "fr") ||
                  (lang.title === "arabic" && authData.lang === "ar") ||
                  (lang.title === "kinyarwanda" && authData.lang === "rw")
                ) {
                  setShowLangs(false);
                  return;
                }
                changeLang(
                  lang.title === "english"
                    ? "en"
                    : lang.title === "french"
                    ? "fr"
                    : lang.title === "arabic"
                    ? "ar"
                    : "rw"
                );
                setShowLangs(false);
              }}
              key={index}
            >
              <img className="w-10 h-5 object-fill" src={lang.flag} alt="" />
              <p className="capitalize text-black41 text-sm ml-3">{lang.title}</p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LanguageSelection;
