import React from "react";

const AddressBox: React.FC<{
  onMouseOver: () => void;
  onMouseOut: () => void;
  showAddressTooltip: boolean;
  address: string;
}> = ({ onMouseOver, onMouseOut, showAddressTooltip, address }) => {
  return (
    <div
      className="inline relative"
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      ...
      {showAddressTooltip && (
        <div className="absolute top-[-50px] left-[-100px] bg-white rounded shadow px-2 py-1 z-30 min-w-[200px] w-auto">
          {address}
        </div>
      )}
    </div>
  );
};

export default AddressBox;
