import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import PrimaryButton from "./PrimaryButton";
import ExportButton from "./ExportButton";
import BreadCrumb from "./BreadCrumb";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export type BreadCrumbLinks = {
  title: string;
  firstAddress: string;
  firstAddressLink?: string;
  secondAddress: string;
  thirdAddress?: string;
};

export type BreadCrumbButton = {
  buttonTitle: string;
  onClick: () => void;
  onBlur?: () => void;
};

const TitleBreadCrumb: React.FC<{
  secondaryButton: BreadCrumbButton;
  primaryButton: BreadCrumbButton;
  links: BreadCrumbLinks;
  children: React.ReactNode;
  className?: string;
  hasSecondButton?: boolean;
}> = (props) => {
  const {
    primaryButton,
    secondaryButton,
    links,
    children,
    className,
    hasSecondButton = true,
  } = props;
  const { t } = useTranslation();

  const {
    title,
    firstAddress,
    secondAddress,
    thirdAddress = "",
    firstAddressLink,
  } = links;
  const { buttonTitle, onClick, onBlur } = secondaryButton;
  const primaryButtonTitle = primaryButton.buttonTitle;
  const primaryButtonClicked = primaryButton.onClick;
  let availableLink = [];
  thirdAddress
    ? (availableLink = [
        { title: t(firstAddress), path: firstAddressLink },
        { title: t(secondAddress) },
        { title: t(thirdAddress) },
      ])
    : (availableLink = [
        { title: t(firstAddress), path: firstAddressLink },
        { title: t(secondAddress) },
      ]);

  return i18next.language !== "fr" ? (
    <div className="md:flex md:justify-between relative">
      <div className="text-gray-500 md:mt-[5px] 802:flex text-[13px] items-center">
        <div className="font-semibold text-[18px] text-black">
          {t(title)}
        </div>
        <BreadCrumb className="mx-2" availableLinks={availableLink} />
      </div>

      <div className="flex md:justify-between mt-3 md:-mt-px">
        <div
          tabIndex={0}
          onBlur={onBlur}
          onClick={onClick}
          className="relative "
        >
          {hasSecondButton && (
            <ExportButton title={buttonTitle} className={className}>
              {children}
            </ExportButton>
          )}
        </div>
        <div>
          <PrimaryButton
            primaryOrNot={true}
            title={primaryButtonTitle}
            onClick={primaryButtonClicked}
          >
            <FontAwesomeIcon className="text-sm" icon={faPlus} />
          </PrimaryButton>
        </div>
      </div>
    </div>
  ) : (
    <>
      <div className="992:flex 992:justify-between" >
        <div>
          <div className="font-semibold text-[18px] text-black">{t(title)}</div>
          <div className="flex mt-[13px] text-gray-500 text-[13px] ml-[2px] items-center">
            <BreadCrumb availableLinks={availableLink} />
          </div>
        </div>

        <div>
          <div className="flex 970:justify-between mt-3 970:mt-0">
            <div className="relative ">
              {hasSecondButton && (
                <ExportButton
                  title={buttonTitle}
                  onClick={hasSecondButton && onClick}
                  className={className}
                  onBlur={hasSecondButton && onBlur}
                >
                  {children}
                </ExportButton>
              )}
            </div>
            <div>
              <PrimaryButton
                primaryOrNot={true}
                title={primaryButtonTitle}
                onClick={primaryButtonClicked}
              >
                <FontAwesomeIcon className="text-sm" icon={faPlus} />
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TitleBreadCrumb;
