import { useState, useEffect, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { CircularProgress, Snackbar, SnackbarContent } from "@mui/material";
import { SnackBarType, ButtonTypes } from "../../../utils/Constants";
import Clinic from "../../../types/Clinic";
import {
  SearchFilterClinicsContext,
  DEFAULT_CLINIC_FILTER,
} from "../../../store/SearchFilterClinicsContext";
import ClinicsAPI from "../../../store/api/clinicsAPI";
import MainContainer from "../../UI/MainContainer";
import MainFooter from "../../UI/MainFooter";
import ClinicTable from "./ClinicTable";
import SelectedClinicsExport from "./SelectedClinicsExport";
import SearchSection from "../../UI/SearchSection";
import ClinicFilterSection from "./ClinicFilterSection";
import Button from "../../UI/Button";
import ClinicModal from "./ClinicModal";
import NoResultMessage from "../../UI/NoResultMessage";
import i18next from "i18next";
import IdValueType from "../../../types/IdValueType";

const Clinics = () => {
  const [showFilterSection, setShowFilterSection] = useState(false);
  const [createAddClinicModal, setCreateAddClinicModal] = useState(false);
  const [displayAddClinicModal, setDisplayAddClinicModal] = useState(true);
  const [showExportCompletedSnackbar, setShowExportCompletedSnackbar] =
    useState(false);
  const [showListLoading, setShowListLoading] = useState(true);

  const {
    clinics,
    setClinics,
    searchTerm,
    setSearchTerm,
    clinicFilter,
    setClinicFilter,
    clinicAppliedFilter,
    setClinicAppliedFilter,
    showClinicAddedSnackbar,
    setShowClinicAddedSnackbar,
    showClinicEditedSnackbar,
    setShowClinicEditedSnackbar,
    setTableAutoReset,
    setShowClinicImportedNewbornSnackbar,
    showClinicImportedNewbornSnackbar,
    setShowClinicImportedFollowUpsSnackbar,
    showClinicImportedFollowUpsSnackbar,
    snackBarType,
    importErrorMessage,
    setImportErrorMessage,
  } = useContext(SearchFilterClinicsContext);

  const { getSearchedAndFilteredClinics } = ClinicsAPI();

  const { t } = useTranslation();
  const isInitialRender = useRef(true);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    setClinicFilter({ ...clinicAppliedFilter });
    onGetSearchedAndFilteredClinics();
  }, [clinicAppliedFilter]);

  useEffect(() => {
    if (isInitialRender.current) return;
    if (!showFilterSection) {
      onGetSearchedAndFilteredClinics();
    }
  }, [searchTerm]);

  const onGetSearchedAndFilteredClinics = () => {
    setShowListLoading(true);

    getSearchedAndFilteredClinics(
      searchTerm,
      clinicAppliedFilter.status,
      clinicAppliedFilter.minStaffCount,
      clinicAppliedFilter.maxStaffCount,
      clinicAppliedFilter.minPatientCount,
      clinicAppliedFilter.maxPatientCount,
      processGetSeaerchedAndFilterResponse
    );
  };

  const onApply = () => {
    setClinicAppliedFilter({ ...clinicFilter });
  };

  const processGetSeaerchedAndFilterResponse = (_clinics: Clinic[]) => {
    setClinics(_clinics);
    setShowListLoading(false);
  };

  let snackbarMessage = "";
  if (showClinicAddedSnackbar) {
    snackbarMessage = t("clinic_added__message");
  } else if (showClinicEditedSnackbar) {
    snackbarMessage = t("clinic_edited__message");
  } else if (showExportCompletedSnackbar) {
    snackbarMessage = t("data_exported_successfully");
  } else if (showClinicImportedNewbornSnackbar) {
    snackbarMessage = t("import_completed__message");
  } else if (showClinicImportedFollowUpsSnackbar) {
    snackbarMessage = t("import_completed__message");
  } else if (importErrorMessage) {
    snackbarMessage = importErrorMessage;
  }

  const addSuccessHandler = (
    slug: string,
    title: string,
    active: boolean,
    phone_number: string,
    address: string,
    areas: number[],
    areaNames: IdValueType[],
    latitude: number,
    longitude: number
  ) => {
    const newClinics: Clinic[] = [];
    newClinics.push({
      title: title,
      phoneNumber: phone_number,
      address: address,
      areas: areas,
      areaNames: areaNames,
      longitude: longitude,
      latitude: latitude,
      slug: slug,
      status: active ? "active" : "",
      patientCount: 0,
      staffCount: 0,
      defaulterCount: 0,
      savedCount: 0,
    });
    const addedClinic = newClinics.concat(clinics);
    setClinics(addedClinic);
  };

  return (
    <MainContainer>
      <Snackbar
        open={
          showClinicAddedSnackbar ||
          showClinicEditedSnackbar ||
          showExportCompletedSnackbar ||
          showClinicImportedFollowUpsSnackbar ||
          showClinicImportedNewbornSnackbar
        }
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: `${
              snackBarType === SnackBarType.IMPORTFAILED ? "#D11C1C" : "#03A678"
            }`,
          },
          "& .MuiSnackbarContent-message": {
            fontSize: "16px",
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: "100",
          },
        }}
        onClose={() => {
          if (showClinicAddedSnackbar) {
            setShowClinicAddedSnackbar(false);
          } else if (showClinicEditedSnackbar) {
            setShowClinicEditedSnackbar(false);
          } else if (showExportCompletedSnackbar) {
            setShowExportCompletedSnackbar(false);
          } else if (showClinicImportedFollowUpsSnackbar) {
            setShowClinicImportedFollowUpsSnackbar(false);
          } else if (showClinicImportedNewbornSnackbar) {
            setShowClinicImportedNewbornSnackbar(false);
          } else if (importErrorMessage) {
            setImportErrorMessage("");
          }
        }}
      >
        <SnackbarContent message={<p>{snackbarMessage}</p>} />
      </Snackbar>
      <main className="alignment mb-8">
        <header className="flex flex-col mt-4 md:flex-row md:justify-between md:items-center md:mt-0">
          <h3 className="text-lg font-semibold text-gray-27">
            {t("clinic_list")}
          </h3>
          <Button
            className="self-start mt-2 md:mt-0"
            type={ButtonTypes.PRIMARY}
            onClick={() => {
              setCreateAddClinicModal(true);
              setDisplayAddClinicModal(true);
            }}
          >
            <div className="flex items-center">
              <FontAwesomeIcon
                icon={faPlus}
                className={`${
                  i18next.language === "ar" ? "ml-[10px]" : "mr-[10px]"
                } mt-[2px]`}
              />
              <p className="normal-case">{t("add_clinic")}</p>
            </div>
          </Button>
        </header>

        <section className="w-full rounded mt-6 bg-white pb-6 min-h-[621px]">
          <SearchSection
            onSearch={(_searchTerm) => setSearchTerm(_searchTerm)}
            onSliderClicked={() =>
              setShowFilterSection(
                (preShowFilterSection) => !preShowFilterSection
              )
            }
            isFilterMode={showFilterSection}
          />
          {showFilterSection && <ClinicFilterSection onApply={onApply} />}
          <hr className="h-[6px] bg-gray-f5 border-none" />
          <SelectedClinicsExport
            onExportCompleted={() => setShowExportCompletedSnackbar(true)}
          />
          <main className="w-95/100 mx-auto">
            {clinics.length > 0 ? (
              <ClinicTable
                clinics={clinics}
                onExportCompleted={() => setShowExportCompletedSnackbar(true)}
                onClinicEdited={onGetSearchedAndFilteredClinics}
              />
            ) : showListLoading ? (
              <div className="flex w-full h-[500px] items-center justify-center">
                <CircularProgress size={64} style={{ color: "#11589a" }} />
              </div>
            ) : (
              <NoResultMessage noResultOrNoData={true} />
            )}
          </main>
        </section>

        <MainFooter />
      </main>
      {createAddClinicModal && (
        <ClinicModal
          isAdd={true}
          className={displayAddClinicModal ? "grid" : "hidden"}
          onBackdrop={() => setDisplayAddClinicModal(false)}
          onCancel={() => setCreateAddClinicModal(false)}
          onClinicAdded={(
            slug,
            title,
            active,
            phoneNumber,
            address,
            areas,
            areaNames,
            latitude,
            longitude
          ) => {
            setTableAutoReset(true);
            setClinicAppliedFilter(DEFAULT_CLINIC_FILTER);
            setCreateAddClinicModal(false);
            setShowClinicAddedSnackbar(true);
            addSuccessHandler(
              slug,
              title,
              active,
              phoneNumber,
              address,
              areas,
              areaNames,
              latitude,
              longitude
            );
          }}
        />
      )}
    </MainContainer>
  );
};

export default Clinics;
