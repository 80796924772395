import React, { useState, useEffect, PropsWithChildren } from "react";
import { useHistory } from "react-router-dom";
import i18next from "i18next";

type AuthDataType = {
  access: string;
  refresh: string;
  firstName: string;
  lastName: string;
  avatar: string;
  email: string;
  lang: string;
  role: string;
};

const DEFAULT_AUTH_DATA = {
  access: "",
  refresh: "",
  firstName: "",
  lastName: "",
  avatar: "",
  email: "",
  lang: "",
  role: "",
};

type AuthContextType = {
  authData: AuthDataType;
  login: (authData: AuthDataType) => void;
  logout: () => void;
  changeLang: (lang: string) => void;
  updateProfile: (firstName: string, lastName: string, avatar: string) => void;
};

export const AuthContext = React.createContext<AuthContextType>({
  authData: DEFAULT_AUTH_DATA,
  login: () => {
    // ATTENTION: This comment and all its similars are placed because of Sonar issues!
    // Please don't think this is our creativity!
  },
  logout: () => {
    //
  },
  changeLang: () => {
    //
  },
  updateProfile: () => {
    //
  },
});

const AuthContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [authData, setAuthData] = useState<AuthDataType>(
    localStorage.getItem("auth_data") ? JSON.parse(localStorage.getItem("auth_data")!) : DEFAULT_AUTH_DATA
  );

  const history = useHistory();

  useEffect(() => {
    localStorage.setItem("auth_data", JSON.stringify(authData));
    i18next.changeLanguage(
      authData.lang
    );
  }, [authData]);

  const login = (_authData: AuthDataType) => {
    setAuthData(_authData);
    localStorage.setItem("auth_data", JSON.stringify(authData));
    history.replace("/");
  };

  const logout = () => {
    setAuthData(DEFAULT_AUTH_DATA);
    localStorage.removeItem("auth_data");
  };

  const changeLang = async (lang: string) => {
    const formData = new FormData();
    formData.append("preferred_language", lang);

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}profile/`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${authData.access}`,
        },
        body: formData,
      });

      if (response.ok) {
        setAuthData((preAuthData) => {
          return { ...preAuthData, lang };
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateProfile = (firstName: string, lastName: string, avatar: string) => {
    setAuthData((preAuthData) => {
      return { ...preAuthData, firstName, lastName, avatar };
    });
  };

  return (
    <AuthContext.Provider
      value={{
        authData,
        login,
        logout,
        changeLang,
        updateProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
