import { useContext, useState } from "react";
import { Snackbar, SnackbarContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import ProfileAndAccountAPI from "../../../store/api/profileAndAccountAPI";
import { AccountSettingContext } from "../../../store/AccountSettingContext";
import i18next from "i18next";

export const accountSettingLoader = (Component: any) => {
	return (props: any) => {
		const { t } = useTranslation();

		const [showSnackbar, setShowSnackbar] = useState(false);
		const [cancelStatusChanged, setCancelStatusChanged] = useState(false);

		const { editPassword } = ProfileAndAccountAPI();
		const { setCurrentPasswordHasError, setPasswordErrorText, setLoading } = useContext(AccountSettingContext);
		const saveChangePassword = (currentPassword: string, secondPassword: string) => {
			setLoading(true);
			editPassword(editPasswordProcessResponse, currentPassword, secondPassword);
		};

		const editPasswordProcessResponse = async (response: Response) => {
			if (response.ok) {
				setCurrentPasswordHasError(false);
				setShowSnackbar(true);
				setCancelStatusChanged(!cancelStatusChanged);
				setLoading(false);
			} else {
				const result = await response.json();
				setCurrentPasswordHasError(true);
				result.current_password && setPasswordErrorText(result.current_password);
				result.similar_password && setPasswordErrorText(result.similar_password);
				result.password && setPasswordErrorText(result.password);
				setLoading(false);
			}
		};
		return (
			<>
				<Snackbar
					dir={i18next.language === "ar" ? "rtl": "ltr"}
					open={showSnackbar}
					autoHideDuration={2000}
					anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
					sx={{
						"& .MuiSnackbarContent-root": { backgroundColor: "#03A678" },
						"& .MuiSnackbarContent-message": {
							fontSize: "16px",
							fontFamily: "'Open Sans', sans-serif",
							fontWeight: "100",
						},
					}}
					onClose={() => {
						setShowSnackbar(false);
					}}>
					<SnackbarContent message={<p>{t("password_changed_successfully")} </p>} />
				</Snackbar>
				<Component {...props} saveChangePassword={saveChangePassword} cancelStatusChanged={cancelStatusChanged} />
			</>
		);
	};
};
