import React from "react";
import Button from "../UI/Button";
import { ButtonTypes, AddOrEditModal } from "../../utils/Constants";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";

const CancelAndSaveButton: React.FC<{
  cancelBtn: () => void;
  saveBtn: (type: AddOrEditModal) => void;
  disableSaveBtn?: boolean;
  type: AddOrEditModal;
  loading: boolean;
}> = ({ cancelBtn, saveBtn, disableSaveBtn, type, loading }) => {
  const { t } = useTranslation();
  
  return (
    <div className="mt-5 flex justify-end mb-2">
      <div className="mx-2">
        <Button type={ButtonTypes.CANCEL} onClick={cancelBtn}>
          {t("cancel")}
        </Button>
      </div>

      <div>
        <Button
          disabled={disableSaveBtn}
          type={ButtonTypes.PRIMARY}
          onClick={() => saveBtn(type)}
        >
          {loading ? (
            <CircularProgress size={18} style={{ color: "white" }} />
          ) : (
            t("save")
          )}
        </Button>
      </div>
    </div>
  );
};

export default CancelAndSaveButton;
