export const language = (lang: string) => {
  switch (lang.toLowerCase()) {
    case "en":
      return "English";
    case "fr":
      return "French";
    case "ar":
      return "Arabic";
    case "rw":
      return "Kinyarwanda";
    default:
      return "English";
  }
};
