import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClinicMedical,
  faUserMd,
  faUserFriends,
  faSyringe,
  faHospitalSymbol,
} from "@fortawesome/free-solid-svg-icons";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { useLoadScript, GoogleMap, Marker, InfoWindow, Polygon } from "@react-google-maps/api";

import Clinic from "../../../types/Clinic";
import { AdminRole, MAP_CONTAINER_STYLE } from "../../../utils/Constants";
import DashboardAPI from "../../../store/api/dashboardAPI";
import MainContainer from "../../UI/MainContainer";
import DashboardCard from "../../UI/DashboardCard";
import MainFooter from "../../UI/MainFooter";
import i18next from "i18next";
import { parseCoordinates } from "../../../utils/parseCoordinates";
import { defaultGoogleMapCenter } from "../../../utils/utils";
import { calculatePolygonCenter } from "../../../utils/calculatePolygonCenter";
import useRole from "../../../hooks/useRole";

export interface LngLat {
  lng: number;
  lat: number;
}

export type Area = {
  id: string;
  name: string;
  description: string;
  longitude: number;
  latitude: number;
  coordinates: LngLat[];
  clinic: number;
  children: number;
  defaulters: number;
};

const Dashboard = () => {
  const { t } = useTranslation();
  const { isClinicAdmin } = useRole();
  const [clinics, setClinics] = useState<Clinic[]>([]);
  const [selectedClinic, setSelectedClinic] = useState<Clinic | null>(null);
  const [selectedMarker, setSelectedMarker] = useState<Area | null>(null);
  const [markers, setMarkers] = useState<Area[]>([]);
  const [totalStaffNum, setTotalStaffNum] = useState(0);
  const [clinicNames, setClinicNames] = useState<string[]>([]);
  const [totalDefaultersNum, setTotalDefaultersNum] = useState<number>(0);
  const [totalSavedVaccinationsNum, setTotalSavedVaccinationsNum] = useState<number>(0);
  const [selectedClinicForTotalDefaulters, setselectedClinicForTotalDefaulters] = useState("all");
  const [selectedClinicForTotalSavedVaccinations, setselectedClinicForTotalSavedVaccinations] = useState("all");
  const [currentLanguage, setCurrentLanguage] = useState(i18next.language);
  const isInitialRender = useRef<Boolean>(true);
  const [showTooltip, setShowTooltip] = useState(false);
  const [hoveredPolygon, setHoveredPolygon] = useState<Area | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const [defaultMapCenter, setDefaultMapCenter] = useState<LngLat>(defaultGoogleMapCenter);
  const [hoverTimeout, setHoverTimeout] = useState<NodeJS.Timeout | null>(null);
  const { getDashboard, getClinicAdminDashboard } = DashboardAPI();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY!,
    mapIds: JSON.parse(process.env.REACT_APP_MAP_IDS!),
    language: JSON.parse(localStorage.getItem("auth_data")!).lang,
  });

  useEffect(() => {
    if (isClinicAdmin())
      getClinicAdminDashboard(processGetDashboardResponse);
    else
      getDashboard(processGetDashboardResponse);
  }, [getDashboard, getClinicAdminDashboard]);

  useEffect(() => {
    if (selectedMarker) {
      setDefaultMapCenter({
        lat: selectedMarker.latitude,
        lng: selectedMarker.longitude,
      });
    }
  }, [selectedMarker]);

  useEffect(() => {
    if (selectedClinic) {
      setDefaultMapCenter({
        lat: selectedClinic.latitude,
        lng: selectedClinic.longitude,
      });
    }
  }, [selectedClinic]);

  useEffect(() => {
    if (i18next.language !== currentLanguage) {
      setCurrentLanguage(i18next.language);
      if (!isInitialRender.current) {
        window.location.reload();
      }
      isInitialRender.current = false;
    }
  }, [i18next.language]);

  useEffect(() => {
    setTotalStaffNum(
      clinics.reduce((preValue, clinic) => {
        return preValue + clinic.staffCount;
      }, 0)
    );
    setClinicNames(clinics.map((clinic) => clinic.title));

    let totalDefaultersNum;
    if (selectedClinicForTotalDefaulters === "all") {
      totalDefaultersNum = clinics.reduce((preValue, clinic) => {
        return preValue + clinic.defaulterCount!;
      }, 0);
    } else {
      totalDefaultersNum = clinics.find((clinic) => clinic.title === selectedClinicForTotalDefaulters)?.defaulterCount;
    }
    setTotalDefaultersNum(totalDefaultersNum ?? 0);

    let totalSavedVaccinationsNum;
    if (selectedClinicForTotalSavedVaccinations === "all") {
      totalSavedVaccinationsNum = clinics.reduce((preValue, clinic) => {
        return preValue + clinic.savedCount!;
      }, 0);
    } else {
      totalSavedVaccinationsNum = clinics.find(
        (clinic) => clinic.title === selectedClinicForTotalSavedVaccinations
      )?.savedCount;
    }
    setTotalSavedVaccinationsNum(totalSavedVaccinationsNum ?? 0);
  }, [clinics, selectedClinicForTotalDefaulters, selectedClinicForTotalSavedVaccinations]);

  const processGetDashboardResponse = (_clinics: Clinic | Clinic[], _areas: Area[]) => {
    const clinicsArray = Array.isArray(_clinics) ? _clinics : [_clinics];
    setClinics(clinicsArray);
    setMarkers(_areas);
  };

  // TODO convert two markers functions to one function
  const createMarkers = () => {
    return markers?.map((area) => (
      <Marker
        key={area.id}
        position={{ lat: area.latitude, lng: area.longitude }}
        icon={{
          url: "/map-marker.svg",
        }}
        onClick={() => {
          if (selectedMarker?.id === area.id) {
            setSelectedMarker(null);
            return;
          }
          setSelectedMarker(area);
        }}
      />
    ));
  };

  const createClinicMarkers = () => {
    return clinics?.map((area) => (
      <Marker
        key={area.slug}
        position={{ lat: area.latitude, lng: area.longitude }}
        icon={{
          url: "/map-marker.svg",
        }}
        onClick={() => {
          if (selectedClinic?.slug === area.slug) {
            setSelectedClinic(null);
            return;
          }
          setSelectedClinic(area);
        }}
      />
    ));
  };

  const defaultersPerChildrenPercentage = (defaulters: number, children: number) => {
    if (children === 0) {
      return "#00000000"; // "No children to calculate percentage"
    } else {
      const percentage = (defaulters / children) * 100;
      if (percentage <= 20) {
        return "#E5EDF4";
      } else if (percentage <= 40) {
        return "#C0D3E4";
      } else if (percentage <= 60) {
        return "#9AB8D4";
      } else if (percentage <= 80) {
        return "#4F83B4";
      } else if (percentage > 80) {
        return "#11589A";
      }
    }
  };

  const createPolygons = () => {
    return markers?.map((polygon, index) => (
      <Polygon
        key={index}
        paths={parseCoordinates(polygon).coordinates}
        options={{
          strokeColor:
            defaultersPerChildrenPercentage(polygon.defaulters, polygon.children) === "#00000000"
              ? "#000000"
              : defaultersPerChildrenPercentage(polygon.defaulters, polygon.children),
          strokeOpacity: 1,
          strokeWeight: 1.5,
          fillColor: defaultersPerChildrenPercentage(polygon.defaulters, polygon.children),
          fillOpacity: defaultersPerChildrenPercentage(polygon.defaulters, polygon.children) === "#00000000" ? 0 : 0.9,
        }}
        onMouseOver={(e) => {
          const timeoutId: NodeJS.Timeout = setTimeout(() => {
            if (e.latLng) {
              const center = calculatePolygonCenter(parseCoordinates(polygon).coordinates);
              setHoveredPolygon(polygon);
              setTooltipPosition({ lat: center.lat, lng: center.lng });
            }
          }, 500);
          setHoverTimeout(timeoutId);
        }}
        onMouseOut={() => {
          if (hoverTimeout) {
            clearTimeout(hoverTimeout);
            setHoverTimeout(null);
          }
          setHoveredPolygon(null);
          setTooltipPosition(null);
        }}
      />
    ));
  };

  return (
    <MainContainer>
      <main className="alignment mb-8">
        <h3 data-testid="dashboard-title" className="text-lg text-black27 font-semibold">
          {t("dashboard")}
        </h3>
        <section className="flex flex-col-reverse justify-center p-6 rounded shadow-md bg-white mt-6 960:flex-row">
          <div className="grid grid-cols-1 gap-6 bg-gray-f5 p-6 pt-20 relative rounded mt-6 480:grid-cols-2 960:mr-6 960:mt-0 960:flex-1">
            <div className="w-full h-1/3 bg-blue-primary rounded-t absolute top-0 left-0" />
            <DashboardCard title={isClinicAdmin() ? t("number_of_areas") : t("number_of_clinics")} value={isClinicAdmin() ? markers.length : clinics.length
            } valueTestId="clinic__num">
              <div className="relative">
                <FontAwesomeIcon icon={faClinicMedical} className="text-3xl text-blue-primary" />
                <div className="w-[15px] h-[12px] bg-white absolute left-[9px] top-[14px]">
                  <FontAwesomeIcon
                    icon={faHospitalSymbol}
                    className="text-xl text-blue-primary absolute -top-[4px] -left-[2px]"
                  />
                </div>
              </div>
            </DashboardCard>

            <DashboardCard title={t("number_of_staff_members")} value={totalStaffNum} valueTestId="staff__num">
              <FontAwesomeIcon icon={faUserMd} className="text-3xl text-blue-primary" />
            </DashboardCard>
            <DashboardCard
              title={t("number_of_defaulters")}
              value={totalDefaultersNum}
              valueTestId="defaulter__num"
              clinicNames={!isClinicAdmin() ? clinicNames : undefined}
              onClinicChanged={!isClinicAdmin() ? (clinicName) => setselectedClinicForTotalDefaulters(clinicName) : undefined}
            >
              <FontAwesomeIcon icon={faUserFriends} className="text-3xl text-blue-primary" />
            </DashboardCard>
            <DashboardCard
              title={t("number_of_recovered_vaccines")}
              value={totalSavedVaccinationsNum}
              valueTestId="saved-vaccination__num"
              clinicNames={!isClinicAdmin() ? clinicNames : undefined}
              onClinicChanged={!isClinicAdmin() ? (clinicName) => setselectedClinicForTotalSavedVaccinations(clinicName) : undefined}
            >
              <FontAwesomeIcon icon={faSyringe} className="text-3xl text-blue-primary" />
            </DashboardCard>
          </div>
          <div className="h-96 960:flex-1 960:h-auto mr-6" id="map">
            <div className="mb-2 text-sm">
              {t("percentage_of_defaulters")}
              <div
                className="inline relative"
                onMouseOver={() => setShowTooltip(true)}
                onMouseOut={() => setShowTooltip(false)}
              >
                <FontAwesomeIcon icon={faInfoCircle} className="mx-[10px]" />
                {showTooltip && (
                  <div className="absolute top-[-90px] left-[-130px] bg-white rounded shadow px-2 py-1 z-30 min-w-[300px] w-auto text-xs">
                    {t("percentage_of_defaulters_hover")}
                  </div>
                )}
              </div>
            </div>

            {isLoaded && (
              <div className="w-full h-[85%]">
                <GoogleMap
                  key={currentLanguage}
                  mapContainerStyle={MAP_CONTAINER_STYLE}
                  center={defaultMapCenter}
                  zoom={8}
                  options={{ mapId: "cb06d0000f670630" }}
                >
                  {createClinicMarkers()}
                  {createPolygons()}
                  {tooltipPosition && hoveredPolygon && (
                    <InfoWindow
                      position={tooltipPosition}
                      onCloseClick={() => {
                        setHoveredPolygon(null);
                        setTooltipPosition(null);
                      }}
                    >
                      <article className="pb-6">
                        <h3 className="text-xs text-gray-70 font-bold capitalize mb-2">{hoveredPolygon.name}</h3>
                        <ul>
                          <li className="map__info-window__list-item">
                            {t("children")}: {hoveredPolygon.children}
                          </li>
                          <li className="map__info-window__list-item">
                            {t("defaulters")}:{" "}
                            {hoveredPolygon.defaulters && hoveredPolygon.children
                              ? `${((hoveredPolygon.defaulters / hoveredPolygon.children) * 100).toFixed(0)}%`
                              : "0%"}
                          </li>
                        </ul>
                      </article>
                    </InfoWindow>
                  )}

                  {selectedClinic && (
                    <InfoWindow
                      options={{
                        pixelOffset: new window.google.maps.Size(0, -24),
                      }}
                      position={{
                        lat: selectedClinic.latitude,
                        lng: selectedClinic.longitude,
                      }}
                      onCloseClick={() => setSelectedClinic(null)}
                    >
                      <article className="pr-3 pb-3">
                        <h3 className="text-xs text-gray-70 font-bold capitalize mb-2">{selectedClinic.title}</h3>
                        <ul>
                          <li className="text-11px text-gray-70">{selectedClinic.address}</li>
                          <li className="map__info-window__list-item">
                            {t("staff")}: {selectedClinic.staffCount}
                          </li>
                          <li className="map__info-window__list-item">
                            {t("patients")}: {selectedClinic.patientCount}
                          </li>
                          <li className="map__info-window__list-item">
                            {t("defaulters")}: {selectedClinic.defaulterCount}
                          </li>
                          <li className="map__info-window__list-item">
                            {t("saved_vaccinations")}: {selectedClinic.savedCount}
                          </li>
                        </ul>
                      </article>
                    </InfoWindow>
                  )}

                  {/* {selectedMarker && (
                    <InfoWindow
                      options={{
                        pixelOffset: new window.google.maps.Size(0, -24),
                      }}
                      position={{
                        lat: selectedMarker.latitude,
                        lng: selectedMarker.longitude,
                      }}
                      onCloseClick={() => setSelectedMarker(null)}
                    >
                      <article className="pr-3 pb-3">
                        <h3 className="text-xs text-gray-70 font-bold capitalize mb-2">{selectedMarker.name}</h3>
                        <ul>
                          <li className="map__info-window__list-item">
                            {t("defaulters")}: {selectedMarker.defaulters}
                          </li>
                          <li className="map__info-window__list-item">
                            {t("children")}: {selectedMarker.children}
                          </li>
                        </ul>
                      </article>
                    </InfoWindow>
                  )} */}
                </GoogleMap>
              </div>
            )}

            <div className="mt-4 text-xs flex flex-row">
              <div className="flex flex-col items-center mr-2">
                <span className="bg-[#E5EDF4] w-10 h-4 rounded-full mb-1" />
                <span>{(i18next.language === "ar" ? Intl.NumberFormat("ar-EG").format(20) : 20) + "%"}</span>
              </div>
              <div className="flex flex-col items-center mr-2">
                <span className="bg-[#C0D3E4] w-10 h-4 rounded-full mb-1" />
                <span>{(i18next.language === "ar" ? Intl.NumberFormat("ar-EG").format(40) : 40) + "%"}</span>
              </div>
              <div className="flex flex-col items-center mr-2">
                <span className="bg-[#9AB8D4] w-10 h-4 rounded-full mb-1" />
                <span>{(i18next.language === "ar" ? Intl.NumberFormat("ar-EG").format(60) : 60) + "%"}</span>
              </div>
              <div className="flex flex-col items-center mr-2">
                <span className="bg-[#4F83B4] w-10 h-4 rounded-full mb-1" />
                <span>{(i18next.language === "ar" ? Intl.NumberFormat("ar-EG").format(80) : 80) + "%"}</span>
              </div>
              <div className="flex flex-col items-center mr-2">
                <span className="bg-[#11589A] w-10 h-4 rounded-full mb-1" />
                <span>{(i18next.language === "ar" ? Intl.NumberFormat("ar-EG").format(100) : 100) + "%"}</span>
              </div>
            </div>
          </div>
        </section>
        <MainFooter />
      </main>
    </MainContainer>
  );
};

export default Dashboard;
