import { CircularProgress } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const LoadingSpinner: React.FC<{
  onBackdrop: () => void;
  titleKey: string;
}> = ({ onBackdrop, titleKey }) => {
  const { t } = useTranslation();

  return (
    <div
      className="backdrop"
      onClick={() => onBackdrop()}
    >
      <section
        className="bg-white shadow-md p-6 cursor-auto w-outo 480:rounded items-center"
        onClick={(e) => e.stopPropagation()}
      >
        <h3 className={`capitalize text-black27 text-lg font-semibold ${i18next.language === "ar" && "text-right"}`}>
          {t(titleKey)}
        </h3>
        <div className="flex justify-center center items-center" style={{ height: '100%' }}>
          <CircularProgress className="my-5" />
        </div>
      </section>
    </div>
  );
};

export default LoadingSpinner;
