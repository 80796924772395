import useAPI from "../../hooks/useAPI";

const AuthAPI = () => {
  const { sendRequest } = useAPI();

  const loginRequest = (
    email: string,
    password: string,
    token: string,
    processLoginResponse: (response: Response) => void
  ) => {
    sendRequest(
      {
        url: "token/",
        method: "POST",
        body: JSON.stringify({ email, password, token }),
        noAuthorization: true,
      },
      processLoginResponse
    );
  };

  const getPasswordRequest = (
    email: string,
    token: string,
    processSendEmailResponse: (response: Response) => void
  ) => {
    sendRequest(
      {
        url: "ca-getpassword/",
        method: "POST",
        body: JSON.stringify({ email, token }),
        noAuthorization: true,
      },
      processSendEmailResponse
    );
  };

  const forgotPasswordRequest = (email: string, token: string, processForgotResponse: (response: Response) => void) => {
    sendRequest(
      {
        url: "send-mail/",
        method: "POST",
        body: JSON.stringify({ email, token }),
        noAuthorization: true,
      },
      processForgotResponse
    );
  };

  const linkValidationRequest = (
    uid: string,
    hashToken: string,
    processLinkValidationResponse: (response: Response) => void
  ) => {
    sendRequest(
      {
        url: `reset-password/${uid}/${hashToken}/`,
        noAuthorization: true,
      },
      processLinkValidationResponse
    );
  };

  const resetPassword = (
    uid: string,
    hashToken: string,
    firstPassword: string,
    token: string,
    processResetPasswordResponse: (response: Response) => void
  ) => {
    sendRequest(
      {
        url: `reset-password/${uid}/${hashToken}/`,
        method: "PUT",
        body: JSON.stringify({ new_password: firstPassword, token }),
        noAuthorization: true,
      },
      processResetPasswordResponse
    );
  };

  return { loginRequest, getPasswordRequest, forgotPasswordRequest, linkValidationRequest, resetPassword };
};

export default AuthAPI;
