import React, { useEffect, useState } from "react";
import EditProfile from "./EditProfile";
import Profile from "./Profile";

const ProfilePage: React.FC<{
  saveHandler: (
    photoDeleted: boolean,
    photo: string,
    firstname: string,
    lastname: string
  ) => void;
  photoChangeHandler: (isChanged: boolean) => void;
  loading: boolean;
  cancelCalled: boolean;
}> = ({ saveHandler, loading, cancelCalled, photoChangeHandler }) => {
  const [isEditPage, setIsEditPage] = useState(false);

  useEffect(() => {
    setIsEditPage(false);
  }, [cancelCalled]);

  return (
    <>
      {!isEditPage ? (
        <Profile editProfileHandler={() => setIsEditPage(true)} />
      ) : (
        <EditProfile
          photoChangeHandler={photoChangeHandler}
          saveHandler={saveHandler}
          cancel={() => setIsEditPage(false)}
          loading={loading}
        />
      )}
    </>
  );
};

export default ProfilePage;
