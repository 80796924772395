import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Snackbar, SnackbarContent } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/pro-light-svg-icons";

import { ButtonTypes } from "../../../../utils/Constants";
import DefaulterDetailsType from "../../../../types/DefaulterDetails";
import DefaultersAPI from "../../../../store/api/defaultersAPI";
import Button from "../../../UI/Button";
import MainContainer from "../../../UI/MainContainer";
import AuthenticatedNotFound404 from "../../../pages/not-found-404/AuthenticatedNotFound404";
import DefaulterInformationSection from "./DefaulterInformationSection";
import DefaulterMissedVaccinesTable from "./DefaulterMissedVaccinesTable";
import BreadCrumb from "../../../UI/BreadCrumb";
import ActivityLogs from "./ActivityLogs";
import ResendReminderModal from "../../../UI/ResendReminderModal";
import i18next from "i18next";
import MainFooter from "../../../UI/MainFooter";

const capitalize = (word: string) => word[0].toUpperCase() + word.slice(1).toLowerCase();

const DefaulterDetails = () => {
    const { t } = useTranslation();
    const { slug } = useParams<{ slug: string }>();

    const parentContainer = useRef<HTMLDivElement>(null);
    const activityLogsContainer = useRef<HTMLElement>(null);
    const informationContiner = useRef<HTMLElement>(null);

    const { getDefaulterDetails, resendReminder } = DefaultersAPI();

    const [defaulter, setDefaulter] = useState<DefaulterDetailsType>();
    const [isUrlInvalid, setUrlInvalid] = useState(false);
    const [showResendReminderModal, setShowResendReminderModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState<{
        show: boolean;
        message: string;
    }>({
        show: false,
        message: "",
    });

    useEffect(() => {
        onGetDefaulterDetails();
    }, [JSON.parse(localStorage.getItem("auth_data")!).lang]);

    useEffect(() => {
        const onWindowResize = () => {
            if (defaulter) {
                activityLogsContainer.current!.style.right = `${
                    document.body.clientWidth - parentContainer.current!.getBoundingClientRect().right
                }px`;

                activityLogsContainer.current!.style.left = `${informationContiner.current!.getBoundingClientRect().right + 16}px`;
            }
        };

        window.addEventListener("resize", onWindowResize);
        onWindowResize();

        return () => window.removeEventListener("resize", onWindowResize);
    }, [defaulter]);

    const onGetDefaulterDetails = () => {
        getDefaulterDetails(slug, processGetDefaulterDetails);
    };

    const processGetDefaulterDetails = async (response: Response, _defaulter: DefaulterDetailsType) => {
        if (response.status === 404) {
            setUrlInvalid(true);
            return;
        }

        setDefaulter(_defaulter);
    };

    const onResendButtonClicked = () => {
        setLoading(true);

        resendReminder([{ slug }], async (response) => {
            if (response.ok) {
                setLoading(false);
                setShowResendReminderModal(false);
                onGetDefaulterDetails();
                setSnackbar({
                    show: true,
                    message: t("reminder_sent_successfully"),
                });
            }
        });
    };

    let guardianInformation;
    switch (defaulter?.caregiverTypeKey) {
        case "P":
        case "N":
            guardianInformation = [
                {
                    label: t("first_name"),
                    value: defaulter.caregiverFirstName,
                },
                {
                    label: t("last_name"),
                    value: defaulter.caregiverLastName,
                },
                {
                    label: t("guardian_type"),
                    value: defaulter.caregiverType,
                },
                {
                    label: "Relation",
                    value: defaulter.relation,
                },
                {
                    label: t("national_id"),
                    value: defaulter.caregiverNationalID,
                },
            ];
            break;
        case "L":
            guardianInformation = [
                {
                    label: t("guardian_type"),
                    value: defaulter.caregiverType,
                },
                {
                    label: t("institution_name"),
                    value: defaulter.caregiverFirstName,
                },
                {
                    label: t("contact_person"),
                    value: defaulter.caregiverLastName,
                },
            ];
            break;
    }

    if (isUrlInvalid) {
        return (
            <MainContainer>
                <AuthenticatedNotFound404 message={t("not_found_url__message")} />
            </MainContainer>
        );
    }

    return (
        <MainContainer>
            <Snackbar
                dir={i18next.language === "ar" ? "rtl": "ltr"}
                open={snackbar.show}
                autoHideDuration={5000}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                sx={{
                    "& .MuiSnackbarContent-root": { backgroundColor: "#03A678" },
                    "& .MuiSnackbarContent-message": {
                        fontSize: "16px",
                        fontFamily: "'Open Sans', sans-serif",
                        fontWeight: "100",
                    },
                }}
                onClose={() => {
                    setSnackbar({
                        show: false,
                        message: "",
                    });
                }}
            >
                <SnackbarContent message={<p>{snackbar.message}</p>} />
            </Snackbar>
            <main ref={parentContainer} className="alignment mb-8">
                {defaulter && (
                    <>
                        <header className="flex flex-col mt-4 md:flex-row md:justify-between md:items-center md:mt-0">
                            <div className="flex items-center">
                                <h3 className="text-lg font-semibold text-gray-27">
                                  {`${defaulter.firstName} ${defaulter.lastName}`}</h3>
                                <BreadCrumb
                                    className="mt-[2px] mx-2"
                                    availableLinks={[
                                        { title: t("defaulters"), path: "/defaulters" },
                                        { title: `${defaulter.firstName && capitalize(defaulter.firstName)} ${defaulter.lastName && capitalize(defaulter.lastName)}` },
                                    ]}
                                />
                            </div>
                            <Button
                                className="self-end mt-4 md:mt-0"
                                type={ButtonTypes.PRIMARY}
                                onClick={() => setShowResendReminderModal(true)}
                            >
                                <div className="flex">
                                    <FontAwesomeIcon icon={faPaperPlane} className={` mt-1 ${i18next.language === "ar" ? "ml-[10px]" : "mr-[10px]"} `} />
                                    <p className="normal-case">{t("resend_reminder")}</p>
                                </div>
                            </Button>
                        </header>
                        <div className={` flex flex-col ${i18next.language === "ar" ? "888:flex-row-reverse" : "888:flex-row"} `}>
                            <section ref={informationContiner} className="888:w-3/5">
                                <DefaulterInformationSection
                                    className="mt-4"
                                    title={t("child_personal_information")}
                                    information={[
                                        {
                                            label: t("first_name"),
                                            value: defaulter.firstName,
                                        },
                                        {
                                            label: t("last_name"),
                                            value: defaulter.lastName,
                                        },
                                        {
                                            label: t("birth_date"),
                                            value: defaulter.birthDate,
                                        },
                                        {
                                            label: t("national_id"),
                                            value: defaulter.nationalID,
                                        },
                                        {
                                            label: t("gender"),
                                            value: defaulter.gender,
                                        },
                                        {
                                            label: t("clinic"),
                                            value: defaulter.clinicTitle,
                                        },
                                    ]}
                                />
                                <DefaulterInformationSection
                                    className="mt-4"
                                    title={t("contact_information")}
                                    information={[
                                        {
                                            label: t("phone_number"),
                                            value: defaulter.phoneNumber,
                                        },
                                        {
                                            label: t("city_town_village"),
                                            value: defaulter.area,
                                        },
                                        {
                                            label: t("street"),
                                            value: defaulter.street,
                                        },
                                        {
                                            label: "Email",
                                            value: defaulter.email,
                                        },
                                    ]}
                                />
                                <DefaulterInformationSection
                                    className="mt-4"
                                    title={t("caregiver_information")}
                                    information={guardianInformation || []}
                                />
                                <DefaulterMissedVaccinesTable missedVaccines={defaulter.missedVaccines} />
                            </section>
                            <aside ref={activityLogsContainer} className="888:fixed 888:bottom-4 888:top-[260px]">
                                <ActivityLogs
                                    activityLogs={defaulter.activityLogs}
                                    slug={slug}
                                    onAddActivityLog={(activityLog) =>
                                        setDefaulter((preDefaulter) => {
                                            if (!preDefaulter) return;
                                            return {
                                                ...preDefaulter,
                                                activityLogs: [activityLog, ...preDefaulter.activityLogs],
                                            };
                                        })
                                    }
                                />
                                <MainFooter />
                            </aside>
                        </div>
                    </>
                )}
            </main>
            {showResendReminderModal && (
                <ResendReminderModal
                    onCancel={() => setShowResendReminderModal(false)}
                    onBackdrop={() => setShowResendReminderModal(false)}
                    onResendReminderButtonClicked={onResendButtonClicked}
                    loading={loading}
                    titleKey="resend_reminder"
                    message={t("resend_reminder__message", {
                        selectedDefaulterNum: 1,
                    })}
                    resendButtonLabelKey="yes_resend"
                />
            )}
        </MainContainer>
    );
};

export default DefaulterDetails;
