import React, { useContext, useEffect } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { AreaContext } from "../../../../store/AreaContext";
import Pagination from "../../../UI/Pagination";
import AreaCard from "./AreaCard";
import Area from "../../../../types/Area";

interface Column {
  header: string;
  accessor: any;
}

const AreaTable: React.FC<{
  columns: Column[];
  data: Area[];
  deleteHandler?: (id?: number) => void;
  editHandler?: (id: number) => void;
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  pageCount: number;
  totalDataCount: number;
  paginationDropdownKeys: string[];
}> = ({
  columns,
  data,
  deleteHandler,
  editHandler,
  pageNumber,
  setPageNumber,
  pageSize,
  setPageSize,
  pageCount,
  totalDataCount,
  paginationDropdownKeys,
}) => {
  const { skipPageReset } = useContext(AreaContext);
  const {
    getTableProps,
    getTableBodyProps,
    page,
    gotoPage,
    nextPage,
    previousPage,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: pageSize,
      },
      autoResetPage: skipPageReset,
      manualPagination: true,
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    page.length === 0 && previousPage();
  }, [page]);

  return (
    <>
      <div
        {...getTableProps()}
        className="grid grid-cols-1 gap-6 480:grid-cols-2"
      >
        {page.map((row, i) => (
          <div
            {...getTableBodyProps()}
            key={i}
            className="col-span-1 flex flex-col bg-white rounded"
          >
            <AreaCard
              index={row.original.id}
              name={row.original.name}
              description={row.original.description}
              coordinates={row.original.coordinates}
              editHandler={editHandler}
              deleteHandler={(id) => deleteHandler!(id)}
            />
          </div>
        ))}
      </div>

      <Pagination
        dropdownKeys={paginationDropdownKeys}
        previousPage={previousPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
        setPageNumber={setPageNumber}
        gotoPage={gotoPage}
        pageCount={pageCount}
        pageIndex={pageNumber}
        pageSize={pageSize}
        pageLength={page.length}
        totalLength={totalDataCount}
      />
    </>
  );
};

export default AreaTable;
