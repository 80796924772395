import { useState, useContext, useEffect, useRef } from "react";
import { CircularProgress, Snackbar, SnackbarContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SearchFilterDefaultersContext } from "../../../store/SearchFilterDefaultersContext";
import { SelectedDefaultersContext } from "../../../store/SelectedDefaultersContext";
import Defaulter from "../../../types/Defaulter";
import DefaultersAPI from "../../../store/api/defaultersAPI";
import MainContainer from "../../UI/MainContainer";
import SearchSection from "../../UI/SearchSection";
import DefaulterFilterSection from "./DefaulterFilterSection";
import SelectedDefaulters from "./SelectedDefaulters";
import DefaulterTable from "./DefaulterTable";
import MainFooter from "../../UI/MainFooter";
import NoResultMessage from "../../UI/NoResultMessage";
import ResendReminderModal from "../../UI/ResendReminderModal";
import { saveAs } from "file-saver";
import LoadingSpinner from "../../UI/LoadingSpinner";
import PrimaryButton from "../../UI/PrimaryButton";

const Defaulters = () => {
  const { t } = useTranslation();
  const isInitialRender = useRef(true);
  const [loading, setLoading] = useState(false);
  const [showListLoading, setShowListLoading] = useState(true);
  const [showFilterSection, setShowFilterSection] = useState(false);
  const [showResendReminderModal, setShowResendReminderModal] = useState(false);
  const [defaulters, setDefaulters] = useState<Defaulter[]>([]);
  const [snackbar, setSnackbar] = useState<{
    show: boolean;
    message: string;
  }>({
    show: false,
    message: "",
  });

  const {
    searchTerm,
    setSearchTerm,
    defaulterFilter,
    setDefaulterFilter,
    defaulterAppliedFilter,
    setDefaulterAppliedFilter,
  } = useContext(SearchFilterDefaultersContext);

  const { selectedDefaulters, clearSelectedDefaulters } = useContext(
    SelectedDefaultersContext
  );
  const { getSearchedAndFilteredDefaulters, resendReminder, exportDefaulter } =
    DefaultersAPI();
  const [exporting, setExporting] = useState(false);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    setDefaulterFilter({ ...defaulterAppliedFilter });
    onGetSearchedAndFilteredDefaulters();
  }, [
    defaulterAppliedFilter,
    JSON.parse(localStorage.getItem("auth_data")!).lang,
  ]);

  useEffect(() => {
    if (isInitialRender.current) return;
    if (!showFilterSection) {
      onGetSearchedAndFilteredDefaulters();
    }
  }, [searchTerm]);

  const onGetSearchedAndFilteredDefaulters = () => {
    setShowListLoading(true);

    getSearchedAndFilteredDefaulters(
      searchTerm,
      defaulterAppliedFilter.clinicTitleSlug,
      defaulterAppliedFilter.lastReminderSentFrom,
      defaulterAppliedFilter.lastReminderSentTo,
      defaulterAppliedFilter.missedVaccinesScheduledDateFrom,
      defaulterAppliedFilter.missedVaccinesScheduledDateTo,
      defaulterAppliedFilter.isMandatory,
      processGetSearchedAndFilteredDefaulters
    );
  };

  const onApply = () => {
    setDefaulterAppliedFilter({ ...defaulterFilter });
  };

  const processGetSearchedAndFilteredDefaulters = (
    _defaulters: Defaulter[]
  ) => {
    setDefaulters(_defaulters);
    setShowListLoading(false);
  };

  const onResendButtonClicked = () => {
    setLoading(true);

    resendReminder(
      selectedDefaulters.map((selectedDefaulter) => ({
        slug: selectedDefaulter,
      })),
      async (response) => {
        const result = await response.json();
        if (response.ok) {
          onResend(result.slugs, result.message);
          setLoading(false);
          setShowResendReminderModal(false);
          clearSelectedDefaulters();
        }
      }
    );
  };

  const exportDefaulterHandler = () => {
    setExporting(true);
    exportDefaulter(
      searchTerm,
      defaulterAppliedFilter.clinicTitleSlug,
      defaulterAppliedFilter.lastReminderSentFrom,
      defaulterAppliedFilter.lastReminderSentTo,
      defaulterAppliedFilter.missedVaccinesScheduledDateFrom,
      defaulterAppliedFilter.missedVaccinesScheduledDateTo,
      async (response) => {
        if (response.ok) {
          setTimeout(async () => {
            try {
              const blob = await response.blob();
              saveAs(blob, ` "defaulters".csv`);
              setExporting(false);
              setSnackbar({
                show: true,
                message: t("data_exported_successfully"),
              });
            } catch (e) {
              console.log("error:file not found");
            } finally {
              setExporting(false);
            }
          }, 1000);
        }
      }
    );
  };

  const onResend = (defaulterSlugs: string[], message: string) => {
    setDefaulters((preDefaulters) => {
      return preDefaulters.map((preDefaulter) => {
        if (defaulterSlugs.indexOf(preDefaulter.slug) !== -1) {
          return {
            ...preDefaulter,
            lastReminderSent: new Date(),
          };
        }

        return preDefaulter;
      });
    });

    setSnackbar({
      show: true,
      message,
    });
  };

  return (
    <MainContainer>
      <Snackbar
        open={snackbar.show}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{
          "& .MuiSnackbarContent-root": { backgroundColor: "#03A678" },
          "& .MuiSnackbarContent-message": {
            fontSize: "16px",
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: "100",
          },
        }}
        onClose={() => {
          setSnackbar({
            show: false,
            message: "",
          });
        }}
      >
        <SnackbarContent message={<p>{snackbar.message}</p>} />
      </Snackbar>
      {exporting && (
        <LoadingSpinner
          onBackdrop={() => setExporting(false)}
          titleKey={t("exporting_to_csv")}
        />
      )}
      <main className="alignment mb-8">
        <header className="flex flex-col mt-4 md:flex-row md:justify-between md:items-center md:mt-0">
          <h3 className="text-lg font-semibold text-gray-27">
            {t("defaulters")}
          </h3>
          {defaulters.length > 0 && (
            <div className="mt-2 md:mt-0">
              <PrimaryButton
                onClick={exportDefaulterHandler}
                primaryOrNot
                title={t("export_to_csv")}
              />
            </div>
          )}
        </header>

        <section className="w-full rounded mt-6 bg-white pb-6 min-h-[621px]">
          <SearchSection
            onSearch={(_searchTerm) => setSearchTerm(_searchTerm)}
            onSliderClicked={() =>
              setShowFilterSection(
                (preShowFilterSection) => !preShowFilterSection
              )
            }
            isFilterMode={showFilterSection}
          />
          {showFilterSection && <DefaulterFilterSection onApply={onApply} />}
          <hr className="h-[6px] bg-gray-f5 border-none" />
          <SelectedDefaulters
            setShowResendReminderModal={setShowResendReminderModal}
          />
          <main className="w-95/100 mx-auto">
            {showListLoading ? (
              <div className="flex w-full h-[500px] items-center justify-center">
                <CircularProgress size={64} style={{ color: "#11589a" }} />
              </div>
            ) : defaulters.length > 0 ? (
              <DefaulterTable defaulters={defaulters} />
            ) : (
              <NoResultMessage noResultOrNoData={true} />
            )}
          </main>
        </section>

        <MainFooter />
      </main>
      {showResendReminderModal && (
        <ResendReminderModal
          onCancel={() => setShowResendReminderModal(false)}
          onBackdrop={() => setShowResendReminderModal(false)}
          onResendReminderButtonClicked={onResendButtonClicked}
          loading={loading}
          titleKey="resend_reminder"
          message={t("resend_reminder__message", {
            selectedDefaulterNum: selectedDefaulters.length,
          })}
          resendButtonLabelKey="yes_resend"
        />
      )}
    </MainContainer>
  );
};

export default Defaulters;
