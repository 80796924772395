import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { VaccineStatus } from "../../store/SearchFilterChildrenContext";
import { IconDefinition, faQuestion } from "@fortawesome/pro-regular-svg-icons";
import {
  faCheckCircle,
  faTimesCircle,
  faClock,
  faBan,
  faSolarSystem,
} from "@fortawesome/pro-light-svg-icons";
import { faScrubber } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const FilterTag: React.FC<{
  label: string;
  value: VaccineStatus[];
  onClose: () => void;
}> = ({ label, value, onClose }) => {
  const { t } = useTranslation();
  const showValueHandler = () => {
    return (
      <>
        {value.length !== 0 &&
          value.map((v, index) => (
            <React.Fragment key={v.key}>
              <FontAwesomeIcon
                className="mx-1 text-[16px]"
                style={{ color: v.color }}
                icon={iconHandler(v.icon)}
              />
              <p className="whitespace-pre-wrap font-bold">{v.name}</p>
              {index < value.length - 1 && (
                <span className="font-bold">,</span>
              )}
            </React.Fragment>
          ))}
      </>
    );
  };

  const iconHandler = (iconName: string): IconDefinition => {
    switch (iconName) {
      case "faCheckCircle":
        return faCheckCircle;
      case "faTimesCircle":
        return faTimesCircle;
      case "faClock":
        return faClock;
      case "faBan":
        return faBan;
      case "faScrubber":
        return faScrubber;
      case "faSolarSystem":
        return faSolarSystem;
  
      default:
        return faQuestion;
    }
  };

  return (
    <div className="flex items-center py-[10px] px-3 mx-2 mt-2.5 h-10 rounded-[3px] text-blue-primary bg-blue-e2 text-sm">
      <label>{label}</label>
      <span>:</span>
      {showValueHandler()}
      <FontAwesomeIcon
        onClick={() => onClose()}
        className={`text-base mt-[2px] cursor-pointer ${i18next.language === "ar" ? "mr-2" : "ml-2"}`}
        icon={faTimes}
      />
    </div>
  );
};

export default FilterTag;
