import React, { useMemo, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useTable, useSortBy, Column, TableOptions, usePagination } from "react-table";

import Defaulter from "../../../types/Defaulter";
import { SelectedDefaultersContext } from "../../../store/SelectedDefaultersContext";
import TableHeader from "../../UI/TableHeader";
import DefaulterRow from "./DefaulterRow";
import Pagination from "../../UI/Pagination";
import { tableStringSort } from "../../../utils/tableStringSort";

const DefaulterTable: React.FC<{
  defaulters: Defaulter[];
}> = ({ defaulters }) => {
  const { t } = useTranslation();
  const columns: Column<Defaulter>[] = useMemo(
    () => [
      {
        header: t("name"),
        accessor: "name",
        sortType: tableStringSort,
      },
      {
        header: t("phone_number"),
        accessor: "phoneNumber",
      },
      {
        header: t("national_id"),
        accessor: "nationalID",
      },
      {
        header: t("clinic"),
        accessor: "clinicTitle",
        sortType: tableStringSort,
      },
      {
        header: t("area"),
        accessor: "area",
        sortType: tableStringSort,
      },
      {
        header: t("missed_vaccines"),
        accessor: "missedVaccines",
        disableSortBy: true,
      },
      {
        header: t("last_reminder_sent"),
        accessor: "lastReminderSent",
        sortType: "datetime",
      },
    ],
    [t]
  );
  const data = useMemo(() => defaulters, [defaulters]);

  const options: TableOptions<Defaulter> = {
    columns,
    data,
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    pageCount,
    nextPage,
    previousPage,
    gotoPage,
    setPageSize,
    state,
  } = useTable(options, useSortBy, usePagination);

  const { pageIndex, pageSize } = state;

  const { selectedDefaulters, selectAll, deselectAll } = useContext(SelectedDefaultersContext);

  const visibleDefaulters = page.map((row) => row.original.slug);
  let allChecked = true;
  for (let visibleDefaulter of visibleDefaulters) {
    if (selectedDefaulters.indexOf(visibleDefaulter) === -1) {
      allChecked = false;
      break;
    }
  }
  if (selectedDefaulters.length === 0) {
    allChecked = false;
  }

  return (
    <div className="relative">
      <div className="overflow-x-auto overflow-y-hidden scroll-firefox">
        <table {...getTableProps()} className="w-full border-collapse">
          <TableHeader
            headerGroups={headerGroups}
            allChecked={allChecked}
            selectAll={() => selectAll(page.map((row) => row.original.slug))}
            deselectAll={() => deselectAll(page.map((row) => row.original.slug))}
            className="pl-0"
          />
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return <DefaulterRow key={row.id} row={row} isFirstRow={row.index % pageSize === 0} />;
            })}
          </tbody>
        </table>
      </div>
      <Pagination
        dropdownKeys={["5", "10", "20"]}
        previousPage={previousPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
        gotoPage={gotoPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageLength={page.length}
        totalLength={defaulters.length}
      />
    </div>
  );
};

export default DefaulterTable;
