import React, { PropsWithChildren, useState } from "react";
import Area from "../types/Area";

type AreaContextType = {
  responseData: Area[];
  setResponseData: (areas: Area[]) => void;
  loading: boolean;
  setLoading: (status: boolean) => void;
  setSkipPageReset: (status: boolean) => void;
  skipPageReset: boolean;
};

export const AreaContext = React.createContext<AreaContextType>({
  responseData: [],
  setResponseData: () => {
    //
  },
  loading: false,
  setLoading: () => {
    //
  },
  skipPageReset: false,
  setSkipPageReset: () => {
    //
  },
});

const AreaContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [responseData, setResponseData] = useState<Array<Area>>([]);
  const [loading, setLoading] = useState(false);
  const [skipPageReset, setSkipPageReset] = useState(false);

  return (
    <AreaContext.Provider
      value={{
        responseData,
        setResponseData,
        loading,
        setLoading,
        setSkipPageReset,
        skipPageReset,
      }}
    >
      {children}
    </AreaContext.Provider>
  );
};

export default AreaContextProvider;
