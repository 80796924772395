import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/pro-light-svg-icons";
import MenuItem from "../../../UI/MenuItem";
import LastConfirmWarningModal from "../../../UI/LastConfirmWarningModal";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useLoadScript, GoogleMap, Polygon } from "@react-google-maps/api";
import { AdminRole, MAP_CONTAINER_STYLE } from "../../../../utils/Constants";
import { LngLat } from "../../../../types/Area";
import { defaultGoogleMapCenter } from "../../../../utils/utils";
import useRole from "../../../../hooks/useRole";

const AreaCard: React.FC<{
  index: number;
  name: string;
  description: string;
  coordinates: LngLat[];
  deleteHandler?: (id?: number) => void;
  editHandler?: (id: number) => void;
}> = ({
  index,
  name,
  description,
  coordinates,
  deleteHandler,
  editHandler,
}) => {
  const { t } = useTranslation();
  const { isClinicAdmin } = useRole();
  const [showEditMenu, setShowEditMenu] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(i18next.language);
  const isInitialRender = useRef<Boolean>(true);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY!,
    mapIds: JSON.parse(process.env.REACT_APP_MAP_IDS!),
    language: JSON.parse(localStorage.getItem("auth_data")!).lang,
  });

  useEffect(() => {
    if (i18next.language !== currentLanguage) {
      setCurrentLanguage(i18next.language);
      if (!isInitialRender.current) {
        window.location.reload();
      }
      isInitialRender.current = false;
    }
  }, [i18next.language]);

  const createPolygon = () => {
    return (
      <Polygon
        key={index}
        paths={coordinates}
        options={{
          strokeColor: "#1C68AF",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#1C68AF",
          fillOpacity: 0.25,
        }}
      />
    );
  };

  return (
    <div>
      <div className="p-6" dir={i18next.language === "ar" ? "rtl" : "ltr"}>
        <div className="relative">
          <div className="text-[#1C68AF] text-[15px] font-bold">{name}</div>
          {!isClinicAdmin() && (
            <div
              tabIndex={0}
              onClick={() => setShowEditMenu(!showEditMenu)}
              onBlur={() => setShowEditMenu(false)}
              className={`rounded cursor-pointer hover:bg-gray-f5 absolute ${
                i18next.language === "ar" ? "left-0" : "right-0"
              } top-0 py-1 px-3`}
            >
              <FontAwesomeIcon className="text-base" icon={faEllipsisV} />
              <div
                className={`${
                  i18next.language === "ar" ? "left-0" : "right-0"
                } absolute z-10 ${showEditMenu ? "block" : "hidden"}`}
              >
                <ul
                  dir={i18next.language === "ar" ? "rtl" : "ltr"}
                  className="rounded shadow bg-white mt-2 py-1 w-44"
                >
                  <MenuItem
                    titleKey={t("edit")}
                    onClick={() => {
                      editHandler!(index);
                    }}
                  >
                    <FontAwesomeIcon icon={faPen} className="mx-2" />
                  </MenuItem>

                  <MenuItem
                    titleKey={t("delete")}
                    onClick={() => {
                      setShowDeleteModal(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} className="mx-2" />
                  </MenuItem>
                </ul>
              </div>
            </div>
          )}
        </div>
        <p className="text-sm text-black41 overflow-auto relative mt-4">
          {description}
        </p>
        {isLoaded && (
          <div className="w-full h-[123px] mt-4">
            <GoogleMap
              key={currentLanguage}
              mapContainerStyle={MAP_CONTAINER_STYLE}
              center={{
                lat:
                  coordinates.length > 0
                    ? coordinates[0].lat
                    : defaultGoogleMapCenter.lat,
                lng:
                  coordinates.length > 0
                    ? coordinates[0].lng
                    : defaultGoogleMapCenter.lng,
              }}
              zoom={7}
              options={{ mapId: "cb06d0000f670630" }}
            >
              {createPolygon()}
            </GoogleMap>
          </div>
        )}
      </div>

      {showDeleteModal && (
        <LastConfirmWarningModal
          onBackdrop={() => setShowDeleteModal(false)}
          onCancel={() => setShowDeleteModal(false)}
          onConfirm={() => {
            deleteHandler!(index);
            setShowDeleteModal(false);
          }}
          titleKey={"delete"}
          messageKey={"delete_area_message"}
          confirmButtonLabelKey={"yes_delete"}
        />
      )}
    </div>
  );
};

export default AreaCard;
