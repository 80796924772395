import React from "react";
import ReactDatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/pro-light-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import i18next from "i18next";

const DatePicker: React.FC<{
  inputClassName?: string;
  containerClassName?: string;
  selected: Date | null;
  placeholderText: string;
  onChange: (date: Date | null) => void;
  minDate?: Date | null;
  maxDate?: Date | null;
}> = ({
  inputClassName,
  containerClassName,
  selected,
  placeholderText,
  onChange,
  minDate,
  maxDate,
}) => {
  return (
    <div className={`relative ${containerClassName}`}>
      <FontAwesomeIcon
        icon={faCalendarAlt}
        className={`text-sm text-gray-d7 absolute ${i18next.language === "ar" ? "left-3": "right-3"}  top-4 z-10`}
      />
      <ReactDatePicker
        className={`search-filter__input ${inputClassName}`}
        selected={selected}
        placeholderText={placeholderText}
        onChange={(date) => onChange(date)}
        maxDate={maxDate}
        minDate={minDate}
        dateFormat="dd/MM/yyyy"
      />
    </div>
  );
};

export default DatePicker;
