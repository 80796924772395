import React, { PropsWithChildren, useState } from "react";

type SelectedClinicsContextType = {
  selectedClinics: string[];
  selectClinic: (slug: string) => void;
  deselectClinic: (slug: string) => void;
  selectAll: (slug: string[]) => void;
  deselectAll: (slug: string[]) => void;
  clearSelectedClinics: () => void;
};

export const SelectedClinicsContext =
  React.createContext<SelectedClinicsContextType>({
    selectedClinics: [],
    selectClinic: () => {
      //
    },
    deselectClinic: () => {
      //
    },
    selectAll: () => {
      //
    },
    deselectAll: () => {
      //
    },
    clearSelectedClinics: () => {
      //
    },
  });

const SelectedClinicsContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [selectedClinics, setSelectedClinics] = useState<string[]>([]);

  const selectClinic = (slug: string) => {
    setSelectedClinics((preSelectedClinics) => {
      if (preSelectedClinics.indexOf(slug) === -1) {
        return [...preSelectedClinics, slug];
      } else {
        return preSelectedClinics;
      }
    });
  };

  const deselectClinic = (slug: string) => {
    setSelectedClinics((preSelectedClinics) =>
      preSelectedClinics.filter(
        (preSelectedClinic) => preSelectedClinic !== slug
      )
    );
  };

  const selectAll = (slugs: string[]) => {
    setSelectedClinics((preSelectedClinics) => [
      ...preSelectedClinics,
      ...slugs.filter((slug) => preSelectedClinics.indexOf(slug) === -1),
    ]);
  };

  const deselectAll = (slugs: string[]) => {
    setSelectedClinics((preSelectedClinics) =>
      preSelectedClinics.filter(
        (preSelectedClinic) => slugs.indexOf(preSelectedClinic) === -1
      )
    );
  };

  const clearSelectedClinics = () => {
    setSelectedClinics([]);
  };

  return (
    <SelectedClinicsContext.Provider
      value={{
        selectedClinics,
        selectClinic,
        deselectClinic,
        selectAll,
        deselectAll,
        clearSelectedClinics,
      }}
    >
      {children}
    </SelectedClinicsContext.Provider>
  );
};

export default SelectedClinicsContextProvider;
