import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTachometer } from "@fortawesome/pro-solid-svg-icons";

const BreadCrumb: React.FC<{
  className?: string;
  availableLinks: {
    title: string;
    path?: string;
  }[];
}> = ({ className, availableLinks }) => {
  return (
    <header className={`flex items-center ${className}`}>
      <Link className="text-gray-8d" to="/dashboard">
        <FontAwesomeIcon icon={faTachometer} />
      </Link>
      <ul className="flex">
        {availableLinks.map((availableLink, index) => (
          <li
            key={index}
            className="flex items-center text-[13px] text-black41"
          >
            <div className="w-1 h-1 mx-2 rounded-full bg-gray-27" />
            {availableLink.path ? (
              <Link to={availableLink.path}>{availableLink.title}</Link>
            ) : (
              <p>{availableLink.title}</p>
            )}
          </li>
        ))}
      </ul>
    </header>
  );
};

export default BreadCrumb;
