import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/pro-light-svg-icons";
import MenuItem from "../../../UI/MenuItem";
import LastConfirmWarningModal from "../../../UI/LastConfirmWarningModal";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
export type Message = {
    id: number;
    text: { en: string; fr: string; ar: string; rw: string; };
};
const HealthCard: React.FC<{
    englishMessage: string;
    frenchMessage: string;
    arabicMessage: string;
    kinyarwandaMessage: string;
    index: number;
    deleteHandler: (id?: number) => void;
    editHealthMessageHandler: (id: number) => void;
}> = ({
    englishMessage,
    frenchMessage,
    arabicMessage,
    kinyarwandaMessage,
    index,
    deleteHandler,
    editHealthMessageHandler,
}) => {
    const [showEditMenu, setShowEditMenu] = useState(false);

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const { t } = useTranslation();

    return (
        <div>
            <div className=" px-5 py-4">
                <div className="relative">
                    <div className="text-[#1C68AF] text-[15px]  font-bold ">
                        <div>EN</div>
                        <div
                            tabIndex={0}
                            onClick={() => setShowEditMenu(!showEditMenu)}
                            onBlur={() => setShowEditMenu(false)}
                            className="rounded cursor-pointer hover:bg-gray-f5 absolute right-0 top-0 py-1 px-3"
                        >
                            <FontAwesomeIcon
                                className="text-base "
                                icon={faEllipsisV}
                            />
                            <div
                                className={`right-0 absolute z-10 ${
                                    showEditMenu ? "block" : "hidden"
                                }`}
                            >
                                <ul dir={i18next.language === "ar" ? "rtl": "ltr"} className="rounded shadow bg-white py-1 w-44">
                                    <MenuItem
                                        titleKey={t("edit")}
                                        onClick={() => {
                                            editHealthMessageHandler(index);
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={faPen}
                                            className="mx-2"
                                        />
                                    </MenuItem>

                                    <MenuItem
                                        titleKey={t("delete")}
                                        onClick={() => {
                                            setShowDeleteModal(true);
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={faTrash}
                                            className="mx-2"
                                        />
                                    </MenuItem>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overflow-auto relative">
                    <div className="mt-2.5 ">{englishMessage}</div>

                    <div className="mt-[30px] text-[#1C68AF] text-[15px] font-bold ">
                        {process.env.REACT_APP_Country === "Rwanda" ? "RW" : "FR"}
                    </div>
                    <div className="mt-2.5">{process.env.REACT_APP_Country === "Rwanda" ? kinyarwandaMessage : frenchMessage}</div>
                    {process.env.REACT_APP_Country === "Lebanon" && (
                      <>
                        <div className="mt-[30px] text-[#1C68AF] text-[15px] font-bold ">
                          AR
                        </div>
                        <div className="mt-2.5">{arabicMessage}</div>
                      </>
                    )}
                </div>
            </div>

            {showDeleteModal && (
                <LastConfirmWarningModal
                    onBackdrop={() => setShowDeleteModal(false)}
                    onCancel={() => setShowDeleteModal(false)}
                    onConfirm={() => {
                        deleteHandler(index);
                        setShowDeleteModal(false);
                    }}
                    titleKey={"delete_message"}
                    messageKey={"delete_health__message"}
                    confirmButtonLabelKey={"yes_delete"}
                />
            )}
        </div>
    );
};

export default HealthCard;
