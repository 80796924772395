import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/pro-regular-svg-icons";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const DefaulterInformationSection: React.FC<{
    className?: string;
    title: string;
    information: {
        label: string;
        value: string;
    }[];
}> = ({ className, title, information }) => {
    const { t } = useTranslation();
    const [showInfo, setShowInfo] = useState(true);

    const parentContainer = useRef<HTMLDivElement>(null);
    const childContainer = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (showInfo) {
            parentContainer.current!.style.height = `${childContainer.current!.clientHeight}px`;
        } else {
            parentContainer.current!.style.height = "0px";
        }
    }, [showInfo]);

    return (
        <section className={`bg-white rounded ${className}`}>
            <div className={`py-4 px-6 flex justify-between items-center rounded-t bg-gray-612d duration-700 ${!showInfo && "rounded-b"}`}>
                <p className={`font-semibold ${showInfo ? "text-black41" : "text-blue-secondary"}`}>{title}</p>
                <FontAwesomeIcon
                    icon={faAngleUp}
                    className={`text-sm text-gray-8d cursor-pointer duration-300 ${showInfo ? "rotate-0" : "-rotate-180"}`}
                    onClick={() => setShowInfo((preShowInfo) => !preShowInfo)}
                />
            </div>
            <div ref={parentContainer} className="duration-300 overflow-hidden">
                <div className="grid grid-cols-2 gap-10 px-6 py-10" ref={childContainer}>
                    {information.map((info, index) => (
                        <article key={index} className="text-sm text-black41">
                            <p className="font-semibold">{info.label}</p>
                            <p dir={i18next.language === "ar" && info.label === t("phone_number") ? "ltr" : ""} className={`${i18next.language === "ar" && info.label === t("phone_number") && "text-right"}`}>{info.value}</p>
                        </article>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default DefaulterInformationSection;
