import React, { useState, useContext, useEffect } from "react";
import { Row } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { saveAs } from "file-saver";
import Clinic from "../../../types/Clinic";
import { StatusBadgeTypes } from "../../../utils/Constants";
import { SelectedClinicsContext } from "../../../store/SelectedClinicsContext";
import { SearchFilterClinicsContext } from "../../../store/SearchFilterClinicsContext";
import ClinicsAPI from "../../../store/api/clinicsAPI";
import ChildrenAPI from "../../../store/api/childrenAPI";
import StatusBadge from "../../UI/StatusBadge";
import ClinicModal from "./ClinicModal";
import AddressBox from "./AddressBox";
import { SnackBarType } from "../../../utils/Constants";
import LoadingSpinner from "../../UI/LoadingSpinner";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import ClinicEllipsisVMenu from "./ClinicEllipsisVMenu";

const ClinicRow: React.FC<{
  row: Row<Clinic>;
  onExportCompleted: () => void;
  onClinicEdited: () => void;
}> = ({ row, onExportCompleted, onClinicEdited }) => {
  const {
    slug,
    title,
    status,
    phoneNumber,
    address,
    areaNames,
    staffCount,
    patientCount,
  } = row.original;
  const { selectedClinics, selectClinic, deselectClinic } = useContext(
    SelectedClinicsContext
  );
  const {
    setShowClinicEditedSnackbar,
    setTableAutoReset,
    setShowClinicImportedFollowUpsSnackbar,
    setShowClinicImportedNewbornSnackbar,
    setSnackBarType,
    setImportErrorMessage,
    searchTerm,
    clinicAppliedFilter,
    setClinics,
  } = useContext(SearchFilterClinicsContext);

  const { exportClinics, getSearchedAndFilteredClinics } = ClinicsAPI();
  const { importNewBornsAPI, importFollowUpsAPI } = ChildrenAPI();
  const [createEditClinicModal, setCreateEditClinicModal] = useState(false);
  const [displayEditClinicModal, setDisplayEditClinicModal] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  const [showAddressTooltip, setShowAddressTooltip] = useState(false);
  const updateDimention = () => {
    setWidth(window.innerWidth);
  };
  const [numberOfCharachters, setNumberOfCharachters] = useState(0);
  const [exporting, setExporting] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (width > 1300) {
      setNumberOfCharachters(86);
    } else if (width > 1270 && width < 1301) {
      setNumberOfCharachters(37);
    } else if (width > 1075 && width < 1271) {
      setNumberOfCharachters(15);
    } else if (width < 1076) {
      setNumberOfCharachters(10);
    }
  }, [width]);

  useEffect(() => {
    window.addEventListener("resize", updateDimention);
    return () => window.removeEventListener("resize", updateDimention);
  }, []);

  const onExportClicked = (isPatient: boolean) => {
    exportClinics(isPatient, [{ slug }], async (response) => {
      if (response.ok) {
        setExporting(true);
        setTimeout(async () => {
          try {
            const blob = await response.blob();
            saveAs(
              blob,
              `${isPatient ? title + "_patients" : title + "_staff"}.csv`
            );
            setExporting(false);
            onExportCompleted();
          } catch (e) {
            console.log(e);
          } finally {
            setExporting(false);
          }
        }, 1000);
      }
    });
  };

  const onImportNewBornChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const fileUpload = Object(event.target.files)[0];
    const formData = new FormData();
    formData.append("csv", fileUpload);
    importNewBornsAPI(slug, formData, async (response: Response) => {
      const result = await response.json();
      if (response.ok) {
        setShowClinicImportedNewbornSnackbar(true);
        getSearchedAndFilteredClinics(
          searchTerm,
          clinicAppliedFilter.status,
          clinicAppliedFilter.minStaffCount,
          clinicAppliedFilter.maxStaffCount,
          clinicAppliedFilter.minPatientCount,
          clinicAppliedFilter.maxPatientCount,
          processGetSeaerchedAndFilterResponse
        );
      } else {
        setSnackBarType(SnackBarType.IMPORTFAILED);
        setImportErrorMessage(result);
      }
    });
  };

  const processGetSeaerchedAndFilterResponse = (_clinics: Clinic[]) => {
    setClinics(_clinics);
  };

  const onImportFollwsUpChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const fileUpload = Object(event.target.files)[0];
    const formData = new FormData();
    formData.append("csv", fileUpload);
    importFollowUpsAPI(slug, formData, async (response: Response) => {
      const result = await response.json();
      if (response.ok) {
        setShowClinicImportedFollowUpsSnackbar(true);
        getSearchedAndFilteredClinics(
          searchTerm,
          clinicAppliedFilter.status,
          clinicAppliedFilter.minStaffCount,
          clinicAppliedFilter.maxStaffCount,
          clinicAppliedFilter.minPatientCount,
          clinicAppliedFilter.maxPatientCount,
          processGetSeaerchedAndFilterResponse
        );
      } else {
        setSnackBarType(SnackBarType.IMPORTFAILED);
        setImportErrorMessage(result);
      }
    });
  };

  const checked = selectedClinics.indexOf(slug) !== -1;

  const numberOfCharachter = address.length;

  return (
    <tr
      {...row.getRowProps()}
      className="border-t border-gray-df align-baseline"
    >
      <td
        {...row.cells[0].getCellProps()}
        className={`table__cell text-sm text-black41 whitespace-normal sticky ${
          i18next.language === "ar" ? "right-0" : "left-0"
        } px-3 border-t border-gray-df td-firefox`}
      >
        <div className="flex items-center">
          <div
            className={`table__checkbox grid place-items-center ${
              i18next.language === "ar" ? "ml-4" : "mr-4"
            } ${checked && "border-blue-primary bg-blue-primary"}`}
            onClick={() => {
              if (checked) {
                deselectClinic(slug);
              } else {
                selectClinic(slug);
              }
            }}
          >
            {checked && (
              <FontAwesomeIcon icon={faCheck} className="text-sm text-white" />
            )}
          </div>
          <p className="whitespace-nowrap">{title}</p>
        </div>
      </td>
      <td {...row.cells[1].getCellProps()} className="table__cell text-sm">
        {status === "active" ? (
          <StatusBadge type={StatusBadgeTypes.ACTIVE} />
        ) : (
          <StatusBadge type={StatusBadgeTypes.INACTIVE} />
        )}
      </td>
      <td
        {...row.cells[2].getCellProps()}
        className={`table__cell text-sm text-black41 ${
          i18next.language === "ar" && "text-right"
        }`}
        dir="ltr"
      >
        {phoneNumber}
      </td>
      <td
        {...row.cells[3].getCellProps()}
        className="table__cell text-sm text-black41 whitespace-normal"
      >
        <div>
          {address.substring(0, numberOfCharachters)}
          {numberOfCharachter > numberOfCharachters && (
            <AddressBox
              address={address} //if there is any hangover on td
              onMouseOut={() => setShowAddressTooltip(false)}
              onMouseOver={() => setShowAddressTooltip(true)}
              showAddressTooltip={showAddressTooltip}
            />
          )}
        </div>
      </td>
      <td
        {...row.cells[4].getCellProps()}
        className="table__cell text-sm text-black41"
      >
        {areaNames ? areaNames.map((area) => area.name).join(", ") : ""}
      </td>
      <td
        {...row.cells[5].getCellProps()}
        className="table__cell text-sm text-black41"
      >
        {i18next.language === "ar"
          ? Intl.NumberFormat("ar-EG").format(staffCount)
          : staffCount}
      </td>
      <td
        {...row.cells[6].getCellProps()}
        className={`table__cell ${
          i18next.language === "ar" ? "pl-0" : "pr-0"
        } text-sm text-black41 flex justify-between items-center`}
      >
        {i18next.language === "ar"
          ? Intl.NumberFormat("ar-EG").format(patientCount)
          : patientCount}

        <ClinicEllipsisVMenu
          slug={slug}
          onExportClicked={onExportClicked}
          onEditClinic={() => {
            setCreateEditClinicModal(true);
            setDisplayEditClinicModal(true);
          }}
          onImportNewBornChange={onImportNewBornChange}
          onImportFollwsUpChange={onImportFollwsUpChange}
          staffCount={staffCount}
          patientCount={patientCount}
        />

        {createEditClinicModal && (
          <ClinicModal
            isAdd={false}
            className={displayEditClinicModal ? "grid" : "hidden"}
            onBackdrop={() => setDisplayEditClinicModal(false)}
            onCancel={() => setCreateEditClinicModal(false)}
            clinic={row.original}
            onClinicEdited={() => {
              setTableAutoReset(false);
              setCreateEditClinicModal(false);
              setShowClinicEditedSnackbar(true);
              onClinicEdited();
            }}
          />
        )}
      </td>
      <td>
        {exporting && (
          <LoadingSpinner
            onBackdrop={() => setExporting(false)}
            titleKey={t("exporting_to_csv")}
          />
        )}
      </td>
    </tr>
  );
};

export default ClinicRow;
