import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const DashboardCard: React.FC<{
  title: string;
  value: number;
  valueTestId: string;
  children: React.ReactNode;
  clinicNames?: string[];
  onClinicChanged?: (clinicName: string) => void;
}> = (props) => {
  const { title, value, valueTestId, children, clinicNames, onClinicChanged } = props;

  const { t } = useTranslation();
  const [showMenu, setShowMenu] = useState(false);
  const [selectedClinicName, setSelectedClinicName] = useState("");

  if (selectedClinicName === "all" && i18next.language === "fr") {
    setSelectedClinicName("tous");
  } else if (selectedClinicName === "tous" && i18next.language === "en") {
    setSelectedClinicName("all");
  }

  return (
    <article
      data-testid="dashboard-card"
      style={{ zIndex: 10 }}
      className="bg-white rounded shadow-md p-6 z-10 flex flex-col justify-between"
    >
      <h4 className="text-base text-black41 font-semibold">{title}</h4>
      <div className="mt-8">
        {clinicNames && (
          <div className="flex items-center">
            <label className="capitalize text-sm text-black41">
              {t("clinic")}:
            </label>
            <div
              tabIndex={0}
              onBlur={() => setShowMenu(false)}
              className="relative"
            >
              <p
                data-testid="selected__clinic-name"
                onClick={() => setShowMenu((preShowMenu) => !preShowMenu)}
                className="capitalize text-sm text-blue-primary cursor-pointer hover:text-blue-71 mx-5"
              >
                {selectedClinicName !== ""
                  ? selectedClinicName
                  : t("all")}
              </p>
              {showMenu && (
                <ul className="bg-white py-1 shadow rounded absolute top-5 max-h-32 overflow-y-auto scroll-firefox z-40">
                  <li
                    className="dashboard-card__list-item"
                    onClick={() => {
                      setSelectedClinicName(t("all"));
                      setShowMenu(false);
                      onClinicChanged!("all");
                    }}
                  >
                    {t("all")}
                  </li>
                  {clinicNames.map((clinicName, index) => (
                    <li
                      data-testid="clinic-name"
                      className="dashboard-card__list-item"
                      key={index}
                      onClick={() => {
                        setSelectedClinicName(clinicName);
                        setShowMenu(false);
                        onClinicChanged!(clinicName);
                      }}
                    >
                      {clinicName}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        )}
        <div className="flex items-center mt-12" dir="ltr">
          {children}
          <p
            data-testid={valueTestId}
            className="text-base text-black41 font-semibold ml-6"
          >
            {i18next.language === "ar" ? Intl.NumberFormat('ar-EG').format(value) : value}
          </p>
        </div>
      </div>
    </article>
  );
};

export default DashboardCard;
