import { CircularProgress, Snackbar } from "@mui/material";
import MainContainer from "../../../UI/MainContainer";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useRef, useState } from "react";
import SearchSection from "../../../UI/SearchSection";
import MainFooter from "../../../UI/MainFooter";
import VaccineUtilizationTable from "./VaccineUtilizationTable";
import VaccineUtilizationFilterBar from "./VaccineUtilizationFilterBar";
import { VaccineUtilizationContext } from "../../../../store/VaccineUtilizationContext";
import { getDate } from "../../../../utils/getDate";
import NoResultMessage from "../../../UI/NoResultMessage";
import VaccineUtilizationApi from "../../../../store/api/vaccineUtilizationApi";
import i18next from "i18next";
import { SnackBarType } from "../../../../utils/Constants";
import saveAs from "file-saver";
import { SelectedVaccineUtilizationContext } from "../../../../store/SelectedVaccineUtilizationContext";
import PrimaryButton from "../../../UI/PrimaryButton";
import LoadingSpinner from "../../../UI/LoadingSpinner";
import BreadCrumb from "../../../UI/BreadCrumb";
import FilterTag from "../../../UI/FilterTag";
import { getFormattedDate } from "../../../../utils/DateUtils";

export type SnackbarType = {
  message: string;
  type: number;
  show: boolean;
};

export type SortConfig = {
  column: string;
  sortType: "Ascending" | "Descending";
};

const VaccineUtilization: React.FC = () => {
  const { t } = useTranslation();
  const isInitialRender = useRef(true);
  const [showLoading, setShowLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState<number>(0);
  const [dataCount, setDataCount] = useState<number>(0);
  const [showDateFrom, setShowDateFrom] = useState(false);
  const [showDateTo, setShowDateTo] = useState(false);
  const [showFilterSection, setShowFilterSection] = useState(false);
  const [showStatusBar, setShowStatusBar] = useState(false);
  const [currLanguage, setCurrLanguage] = useState<string | null>(null);
  const [sortConfig, setSortConfig] = useState<SortConfig | undefined>(undefined);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [snackbar, setSnackbar] = useState<SnackbarType>({
    message: "",
    type: 0,
    show: false,
  });
  const {
    responseData,
    setResponseData,
    loading,
    setLoading,
    searchInput,
    setSearchInput,
    filterState,
    setFilterState,
    setLanguage,
    clinics,
  } = useContext(VaccineUtilizationContext);
  const { getVaccineUtilization, exportVaccineUtilization } = VaccineUtilizationApi();
  const { selectedVaccineUtilization, clearSelectedVaccineUtilization } = useContext(SelectedVaccineUtilizationContext);

  const columns = [
    {
      header: t("vaccine"),
      accessor: "name",
      disableSortBy: true,
    },
    {
      header: t("no_of_administered"),
      accessor: "no_of_administered",
      disableSortBy: true,
    },
    {
      header: t("no_of_utilized"),
      accessor: "no_of_utilized",
      disableSortBy: true,
    },
    {
      header: t("no_of_defaulters"),
      accessor: "no_of_defaulters",
      disableSortBy: true,
    },
    {
      header: t("administration_rate"),
      accessor: "administration_rate",
      disableSortBy: true,
    },
    {
      header: t("defaulter_rate"),
      accessor: "defaulter_rate",
      disableSortBy: true,
    },
  ];

  let intervalDateFilterTagValue =
    filterState.dateFrom && filterState.dateTo
      ? `${getFormattedDate(filterState.dateFrom)} - ${getFormattedDate(filterState.dateTo)}`
      : filterState.dateFrom
        ? `${t("from")} ${getFormattedDate(filterState.dateFrom)}`
        : filterState.dateTo
          ? `${t("to")} ${getFormattedDate(filterState.dateTo)}`
          : "";

  useEffect(() => {
    if (searchInput && !searchPerformed) {
      setPageNumber(0);
      setSearchPerformed(true);
    }
  }, [searchInput]);

  useEffect(() => {
    getDataFromServer();
  }, [pageNumber, pageSize, searchInput, sortConfig]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    setPageNumber(0);
    getDataFromServer();
    setShowDateFrom(Boolean(filterState.dateTo && filterState.dateFrom));
    setShowDateTo(Boolean(filterState.dateTo && filterState.dateFrom));
  }, [filterState]);

  useEffect(() => {
    setCurrLanguage(localStorage.getItem("auth_data") ? JSON.parse(localStorage.getItem("auth_data")!).lang : "en");
  }, []);

  useEffect(() => {
    setLanguage(i18next.language);
    if (currLanguage && currLanguage !== i18next.language) {
      getDataFromServer();
      setCurrLanguage(i18next.language);
    }
  }, [i18next.language]);

  function constructQueryParams(includePagination: boolean = true) {
    let queryParams = [];
    if (includePagination) queryParams.push(`page_number=${pageNumber + 1}&page_size=${pageSize}`);
    if (searchInput) {
      queryParams.push(`search=${(searchInput)}`);
    }
    if (filterState.vaccine.length > 0 && filterState.vaccine.some((item) => item.id !== "")) {
      let vaccineIds = filterState.vaccine.map((vaccine) => vaccine.id).join(",");
      if (vaccineIds !== "") {
        queryParams.push(`vaccines=${vaccineIds}`);
      }
    }
    if (filterState.dateFrom !== null) {
      queryParams.push(`date_from=${getDate(filterState.dateFrom.toString(), false)}`);
    }
    if (filterState.dateTo !== null) {
      queryParams.push(`date_to=${getDate(filterState.dateTo.toString(), false)}`);
    }
    if (filterState.clinic !== "") {
      queryParams.push(`clinic_slug=${filterState.clinic}`);
    }
    if (sortConfig) {
      queryParams.push(`sort_by=${sortConfig.column}&sort_type=${sortConfig.sortType}`);
    }
    return `?${queryParams.join("&")}`;
  }

  const getDataFromServer = () => {
    setShowLoading(true);
    getVaccineUtilization(async (response: Response) => {
      const result = await response.json();
      if (response.ok) {
        setResponseData(result.vaccines);
        setPageCount(result.page_count);
        setDataCount(result.total_count);
        setShowLoading(false);
      } else {
        console.log("Failed to fetch data!");
        setShowLoading(false);
      }
    }, constructQueryParams());
  };

  const exportHandler = () => {
    setLoading(true);
    exportVaccineUtilization(
      constructQueryParams(false) +
      (selectedVaccineUtilization.length > 0
        ? `&selected_vaccines=${selectedVaccineUtilization.map((selectedItem) => selectedItem).join(",")}`
        : ""),
      async (response) => {
        if (response.ok) {
          setTimeout(async () => {
            try {
              const blob = await response.blob();
              saveAs(blob, ` "vaccine_utilization_report".csv`);
              setLoading(false);
              clearSelectedVaccineUtilization();
              setSnackbar({
                message: t("export_completed__message"),
                type: SnackBarType.IMPORT,
                show: true,
              });
            } catch (e) {
              console.log("error:file not found");
            } finally {
              setLoading(false);
            }
          }, 1000);
        }
      }
    );
  };

  return (
    <MainContainer>
      <Snackbar
        dir={i18next.language === "ar" ? "rtl" : "ltr"}
        open={snackbar.show}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: `${snackbar.type === SnackBarType.IMPORTFAILED ? "#D11C1C" : "#03A678"}`,
          },
          "& .MuiSnackbarContent-message": {
            fontSize: "16px",
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: "100",
          },
        }}
        onClose={() => {
          setSnackbar({
            message: "",
            type: 0,
            show: false,
          });
        }}
        message={snackbar.message}
      />

      {loading && <LoadingSpinner onBackdrop={() => setLoading(false)} titleKey={t("exporting_to_csv")} />}
      <main className="alignment mb-8">
        <header className="flex flex-col mt-4 md:flex-row md:justify-between md:items-center md:mt-0">
          <div className="flex items-center">
            <h3 className="text-lg font-semibold text-gray-27">{t("vaccine_utilization")}</h3>
            <BreadCrumb
              className="mt-[2px] mx-2"
              availableLinks={[{ title: t("reports") }, { title: t("vaccine_utilization") }]}
            />
          </div>
          {responseData.length > 0 && !loading && (
            <div className="mt-2 md:mt-0">
              <PrimaryButton
                onClick={() => {
                  exportHandler();
                }}
                primaryOrNot
                title={t("export_to_csv")}
              />
            </div>
          )}
        </header>

        <section className="rounded shadow bg-white overflow-hidden mt-6 pb-6">
          <SearchSection
            onSearch={(_searchVal) => setSearchInput(_searchVal)}
            onSliderClicked={() => setShowFilterSection((preState) => !preState)}
          />
          <div
            className={` ${showFilterSection
                ? ` h-auto bg-white`
                : "h-0"
              }   duration-500 `}
          >
            <VaccineUtilizationFilterBar
              applyHandler={(value) => {
                setFilterState(value);
                setShowStatusBar(true);
              }}
              resetHandler={() => {
                setFilterState({
                  vaccine: [{ id: "", name: "", type: [] }],
                  dateFrom: null,
                  dateTo: null,
                  clinic: "",
                });
                setShowStatusBar(false);
                setShowDateTo(false);
                setShowDateFrom(false);
              }}
              vaccineValue={filterState.vaccine}
              dateValue={{
                dateFrom: filterState.dateFrom,
                dateTo: filterState.dateTo,
              }}
              clinicValue={filterState.clinic}
            />
            {
              <div
                className={`flex bg-white ${(showStatusBar || showDateTo || showDateFrom) && showFilterSection && JSON.stringify(filterState) !== JSON.stringify({
                  vaccine: [{ id: "", name: "", type: [] }],
                  dateFrom: null,
                  dateTo: null,
                  clinic: "",
                }) ? "min-h-[69px] py-4" : "h-0 py-4"
                  } duration-500 overflow-hidden`}
              >
                <div className="mx-6 text-black41 text-sm flex flex-wrap">
                  <div className="mt-5">
                    {t("applied_filter")}:
                  </div>
                  {filterState.vaccine.length !== 0 && filterState.vaccine.some((item) => item.name !== "") && (
                    <FilterTag
                      label={t("vaccine")}
                      value={filterState.vaccine.map((key) => key.name).join(", ")}
                      onClose={() => {
                        setFilterState({
                          ...filterState,
                          vaccine: [{ id: "", name: "", type: [] }],
                        });
                      }}
                    />
                  )}
                  {intervalDateFilterTagValue && (
                    <FilterTag
                      label={t("interval_date")}
                      value={intervalDateFilterTagValue}
                      onClose={() => {
                        setFilterState({
                          ...filterState,
                          dateFrom: null,
                          dateTo: null,
                        });
                      }}
                    />
                  )}
                  {filterState.clinic && filterState.clinic !== "" && (
                    <FilterTag
                      label={t("clinic")}
                      value={clinics.find((clinic) =>filterState.clinic === clinic.slug)?.name!}
                      onClose={() => {
                        setFilterState({
                          ...filterState,
                          clinic: "",
                        });
                      }}
                    />
                  )}
                </div>
              </div>
            }
          </div>
          <div className="line relative"></div>
          <div className="px-4 mb-[100px] relative z-10 bg-white">
            {showLoading && responseData.length === 0 ? (
              <div className="flex w-full h-[500px] items-center justify-center">
                <CircularProgress size={64} style={{ color: "#11589a" }} />
              </div>
            ) : responseData.length > 0 ? (
              <div className="relative">
                {showLoading && (
                  <div className="absolute inset-0 bg-white bg-opacity-50 flex items-center justify-center z-10">
                    <CircularProgress size={64} style={{ color: "#11589a" }} />
                  </div>
                )}
                <div className={showLoading ? "opacity-50" : "opacity-100"}>
                  <VaccineUtilizationTable
                    columns={columns}
                    data={responseData}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    pageCount={pageCount}
                    totalDataCount={dataCount}
                    currentSortConfig={sortConfig}
                    sortChanged={setSortConfig}
                  />
                </div>
              </div>
            ) : (
              <div className="text-center py-2.5 rounded bg-white">
                <NoResultMessage noResultOrNoData={true} />
              </div>
            )}
          </div>
        </section>
        <MainFooter />
      </main>
    </MainContainer>
  );
};

export default VaccineUtilization;
