import React, { PropsWithChildren, useState } from "react";

type SelectedChildrenContextType = {
  selectedChildren: string[];
  selectChildren: (slug: string) => void;
  deselectChildren: (slug: string) => void;
  selectAll: (slug: string[]) => void;
  deselectAll: (slug: string[]) => void;
  clearSelectedChildren: () => void;
};

export const SelectedChildrenContext =
  React.createContext<SelectedChildrenContextType>({
    selectedChildren: [],
    selectChildren: () => {
      //
    },
    deselectChildren: () => {
      //
    },
    selectAll: () => {
      //
    },
    deselectAll: () => {
      //
    },
    clearSelectedChildren: () => {
      //
    },
  });

const SelectedChildrenContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [selectedChildren, setSelectedChildren] = useState<string[]>([]);

  const selectChildren = (slug: string) => {
    setSelectedChildren((preSelectedChildren) => {
      if (preSelectedChildren.indexOf(slug) === -1) {
        return [...preSelectedChildren, slug];
      } else {
        return preSelectedChildren;
      }
    });
  };

  const deselectChildren = (slug: string) => {
    setSelectedChildren((preSelectedChildren) =>
      preSelectedChildren.filter(
        (preSelectedChildren) => preSelectedChildren !== slug
      )
    );
  };

  const selectAll = (slugs: string[]) => {
    setSelectedChildren((preSelectedChildren) => [
      ...preSelectedChildren,
      ...slugs.filter((slug) => preSelectedChildren.indexOf(slug) === -1),
    ]);
  };

  const deselectAll = (slugs: string[]) => {
    setSelectedChildren((preSelectedChildren) =>
      preSelectedChildren.filter(
        (preSelectedChildren) => slugs.indexOf(preSelectedChildren) === -1
      )
    );
  };

  const clearSelectedChildren = () => {
    setSelectedChildren([]);
  };

  return (
    <SelectedChildrenContext.Provider
      value={{
        selectedChildren,
        selectChildren,
        deselectChildren,
        selectAll,
        deselectAll,
        clearSelectedChildren,
      }}
    >
      {children}
    </SelectedChildrenContext.Provider>
  );
};

export default SelectedChildrenContextProvider;
