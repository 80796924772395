import useAPI from "../../hooks/useAPI";
import useRole from "../../hooks/useRole";
import Clinic from "../../types/Clinic";
import IdValueType from "../../types/IdValueType";
import { ClinicTitle } from "../SearchFilterDefaultersContext";

const ClinicsAPI = () => {
  const { sendRequest } = useAPI();
  const { isClinicAdmin } = useRole();

  const getSearchedAndFilteredClinics = (
    searchTerm: string,
    status: string,
    minStaffCount: string,
    maxStaffCount: string,
    minPatientCount: string,
    maxPatientCount: string,
    processGetSeaerchedAndFilterResponse: (clinics: Clinic[]) => void
  ) => {
    sendRequest(
      {
        url: `clinics/?${searchTerm && "search=" + searchTerm}${status && "&status=" + status}${maxStaffCount && "&max_staff=" + maxStaffCount
          }${minStaffCount && "&min_staff=" + minStaffCount}${maxPatientCount && "&max_patient=" + maxPatientCount}${minPatientCount && "&min_patient=" + minPatientCount
          }`,
      },
      async (response) => {
        const result = await response.json();

        processGetSeaerchedAndFilterResponse(
          result.map(
            (clinic: {
              slug: string;
              name: string;
              phone_number: string;
              address: string;
              areas: number[];
              area_names: IdValueType[];
              latitude: number;
              longitude: number;
              active: boolean;
              staff_count: number;
              patient_count: number;
            }) => {
              return {
                slug: clinic.slug,
                title: clinic.name,
                phoneNumber: clinic.phone_number,
                address: clinic.address,
                areas: clinic.areas,
                areaNames: clinic.area_names,
                latitude: clinic.latitude,
                longitude: clinic.longitude,
                status: clinic.active ? "active" : "inactive",
                staffCount: clinic.staff_count,
                patientCount: clinic.patient_count,
              };
            }
          )
        );
      }
    );
  };

  const addClinic = (
    title: string,
    active: boolean,
    phoneNumber: string,
    address: string,
    areas: number[],
    latitude: number,
    longitude: number,
    processAddClinicResponse: (response: Response) => void
  ) => {
    sendRequest(
      {
        url: "clinics/",
        method: "POST",
        body: JSON.stringify({
          name: title,
          active,
          phone_number: phoneNumber,
          address,
          areas,
          latitude,
          longitude,
        }),
      },
      processAddClinicResponse
    );
  };

  const editClinic = (
    slug: string,
    title: string,
    phoneNumber: string,
    address: string,
    latitude: number,
    longitude: number,
    areas: number[],
    processEditClinicResponse: (response: Response) => void,
    active?: boolean,
  ) => {
    const body: any = {
      name: title,
      phone_number: phoneNumber,
      address,
      latitude,
      longitude,
      areas,
    };

    if (!isClinicAdmin()) {
      body.active = active;
    }

    sendRequest(
      {
        url: isClinicAdmin() ? `ca-clinics/${slug}/` : `clinics/${slug}/`,
        method: "PUT",
        body: JSON.stringify(body),
      },
      processEditClinicResponse
    );
  };

  const exportClinics = (
    isPatient: boolean,
    slugs: { slug: string }[],
    processExportClinics: (response: Response) => void
  ) => {
    sendRequest(
      {
        url: `${isPatient ? "clinics/patients/export" : "clinics/staffs/export"}/`,
        method: "POST",
        body: JSON.stringify(slugs),
      },
      processExportClinics
    );
  };

  const getClinicTitles = (processGetClinicTitles: (clinicTitles: ClinicTitle[]) => void) => {
    sendRequest(
      {
        url: "clinic-list/",
      },
      async (response: Response) => {
        try {
          const result = await response.json();
          if (response.ok) {
            processGetClinicTitles(result);
          }
        } catch (error) {
          console.log(error);
        }
      }
    );
  };

  const getClinicDetails = (
    getClinicDetailsProcessResponse: (response: Response) => void,
    pageNumber?: number, pageSize?: number, searchInput?: string
  ) => {
    sendRequest(
      {
        url: `ca-clinics/?page=${pageNumber}&page_size=${pageSize}${searchInput ? `&search=${searchInput}` : ""}`,
      },
      getClinicDetailsProcessResponse
    );
  };

  const getAreasList = (getNamesProcessResponse: (response: Response) => void) => {
    sendRequest(
      {
        url: "areas/names/",
      },
      getNamesProcessResponse
    );
  };

  return { getSearchedAndFilteredClinics, addClinic, editClinic, exportClinics, getClinicTitles, getClinicDetails, getAreasList };
};

export default ClinicsAPI;
