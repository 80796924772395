import React, { useEffect, useState, useContext } from "react";
import TitleBreadCrumb, {
  BreadCrumbLinks,
  BreadCrumbButton,
} from "../../UI/TitleBreadCrumb";
import StaffTable from "./StaffTable";
import StaffFilterBar from "./StaffFilterBar";
import StatusBar from "../../UI/StatusBar";
import MainContainer from "../../UI/MainContainer";
import AuthenticatedNotFound404 from "../../pages/not-found-404/AuthenticatedNotFound404";
import AddEditStaffModal from "./AddEditStaffModal";
import { AddOrEditModal } from "../../../utils/Constants";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import MainFooter from "../../UI/MainFooter";
import { StaffContext } from "../../../store/StaffContext";
import SearchSection from "../../UI/SearchSection";
import { faShare, faUser } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Staff from "../../../types/Staff";
import NoResultMessage from "../../UI/NoResultMessage";
import { CircularProgress } from "@mui/material";

const StaffManagment: React.FC<{
  getStaffs: (searchInput: string, filterInput: string) => void;
  exportHandler: () => void;
  clinicName: string;
  notFoundMessage: string;
  saveAddOrEditHandler: (
    _type: AddOrEditModal,
    name: string,
    lastName: string,
    phone: string,
    email: string,
    language: string,
    isClinicAdmin: boolean,
    editStaffSlug?: string
  ) => void;
  updateStatusHandler: (_status: string, _slugStaff: string) => void;
}> = ({
  getStaffs,
  exportHandler,
  clinicName,
  notFoundMessage,
  saveAddOrEditHandler,
  updateStatusHandler,
}) => {
  const { t } = useTranslation();
  const [statuses, setStatuses] = useState("AL");
  const [showFilterSection, setShowFilterSection] = useState(false);
  const [showStatusBar, setShowStatusBar] = useState(false);
  const filterArray = ["AL", "AC", "IC", "PD"];
  const {
    setStatusState,
    responseData,
    responseStatus,
    filterInput,
    setFilterInput,
    searchInput,
    setSearchInput,
  } = useContext(StaffContext);
  const [showListLoading, setShowListLoading] = useState(true);
  const { slug } = useParams<{ slug: string }>();
  const [staff, setStaff] = useState<Staff | undefined>(undefined);
  const [showModal, setShowModal] = useState(false);
  const [statusEdit, setStatusEdit] = useState("");

  const columns = [
    {
      header: t("first_name"),
      accessor: "first_name",
    },
    {
      header: t("last_name"),
      accessor: "last_name",
    },
    {
      header: t("phone_number"),
      accessor: "phone_number",
    },
    {
      header: t("email"),
      accessor: "email",
    },
    {
      header: t("language"),
      accessor: "preferred_language",
    },
    {
      header: t("last_Sync"),
      accessor: "last_sync",
      sortType: "datetime",
    },
    {
      header: t("status"),
      accessor: "status",
    },
  ];

  useEffect(() => {
    setShowListLoading(false);
  }, [responseData]);

  useEffect(() => {
    if (!showModal) setStaff(undefined);
  }, [saveAddOrEditHandler, showModal]);

  useEffect(() => {
    setShowListLoading(true);
    getStaffs(searchInput, filterInput);
  }, [searchInput, filterInput, slug]);

  useEffect(() => {
    if(responseStatus.responseIsOk)
      setShowModal(false);
  }, [responseStatus.responseStatusChanged]);

  const applyClick = (_apply: string) => {
    _apply !== "" && setStatusState(_apply);
    _apply !== "" && setShowStatusBar(true);
    setStatuses(_apply);
    setFilterInput(`status=${_apply}`);
  };

  const resetClick = (_val: string) => {
    setStatuses("AL");
    setStatusState("AL");
    setFilterInput(`status=${_val}`);
    setShowStatusBar(false);
  };

  const editHandler = (_slugStaff: string, _data: Array<Staff>) => {
    const staff: Staff = _data.find(
      (staffs: Staff) => staffs.slug === _slugStaff
    )!;
    setShowModal(true);
    setStaff(staff);
    setStatusEdit(staff.status_key);
  };

  const links: BreadCrumbLinks = {
    firstAddress: "clinics_and_staff",
    secondAddress: clinicName,
    title: "manage_staff",
    thirdAddress: "manage_staff",
    firstAddressLink: "/clinics",
  };

  const secondaryButton: BreadCrumbButton = {
    buttonTitle: "export_staff",
    onClick: () => exportHandler(),
  };

  const primaryButton: BreadCrumbButton = {
    buttonTitle: "add_staff",
    onClick: () => setShowModal(true),
  };

  if (notFoundMessage) {
    return (
      <MainContainer>
        <AuthenticatedNotFound404 message={notFoundMessage} />
      </MainContainer>
    );
  }

  return (
    <div>
      <MainContainer>
        <div className="alignment mb-8">
          <div>
            <TitleBreadCrumb
              secondaryButton={secondaryButton}
              links={links}
              primaryButton={primaryButton}
              hasSecondButton={responseData.length > 0}
            >
              <FontAwesomeIcon
                className="text-sm absolute top-3 left-2"
                icon={faUser}
              />
              <FontAwesomeIcon
                className="text-xs absolute top-5 left-[13px]"
                icon={faShare}
              />
            </TitleBreadCrumb>
          </div>
          <div className="rounded shadow bg-white overflow-hidden mt-[34px]">
            <SearchSection
              onSearch={(_searchTerm) =>
                setSearchInput(`search=${_searchTerm}`)
              }
              onSliderClicked={() =>
                setShowFilterSection((preState) => !preState)
              }
              isFilterMode={showFilterSection}
            />

            <div
              data-testid="filter_bar"
              className={` ${
                showFilterSection ? "md:h-[109px] h-auto bg-white" : "h-0"
              } duration-500 `}
            >
              <StaffFilterBar
                filterArray={filterArray}
                applyClick={applyClick}
                resetClick={resetClick}
              />
            </div>

            {statuses !== "AL" && (
              <div
                className={` bg-white ${
                  showStatusBar && showFilterSection ? "min-h-[69px]" : "h-0"
                } duration-500 overflow-hidden `}
              >
                <StatusBar
                  deleteStatus={() => resetClick("AL")}
                  status={statuses.toLowerCase()}
                  setStatus={setStatuses}
                  statusTitle="staff_status"
                />
              </div>
            )}

            {showListLoading ? (
              <div className="flex w-full h-[500px] items-center justify-center">
                <CircularProgress size={64} style={{ color: "#11589a" }} />
              </div>
            ) : responseData.length > 0 ? (
              <div>
                <div className="line relative"></div>
                <div className="p-4 mb-[100px] relative z-10 bg-white">
                  <StaffTable
                    columns={columns}
                    data={responseData}
                    editHandler={(slug_Staff) =>
                      editHandler(slug_Staff, responseData)
                    }
                    updateStatusHandler={updateStatusHandler}
                  />
                </div>
              </div>
            ) : (
              <div>
                <div className="line relative"></div>
                <div className="p-4 relative z-10 bg-white min-h-[100px]">
                  <NoResultMessage
                    noResultOrNoData={
                      statuses !== "AL" || searchInput !== "search="
                    }
                    button={primaryButton}
                  />
                </div>
              </div>
            )}
          </div>
          <MainFooter />
        </div>
      </MainContainer>

      {showModal && (
        <AddEditStaffModal
          staff={staff}
          onCancel={() => setShowModal(false)}
          status={statusEdit}
          className={`${showModal ? "scale-100" : "scale-0"}`}
          saveAddOrEditHandler={saveAddOrEditHandler}
        />
      )}
    </div>
  );
};

export default StaffManagment;
